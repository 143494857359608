@charset "UTF-8";
.picture-xml-edit {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .picture-xml-edit .tooltip {
    z-index: 999; }
  .picture-xml-edit .header {
    width: 100%;
    height: 72px;
    background: #ffffff;
    border-bottom: 1px solid #E2E2E2;
    display: flex;
    align-items: center; }
    .picture-xml-edit .header .left {
      width: 391px;
      padding: 0 32px; }
      .picture-xml-edit .header .left input {
        width: 100%;
        border: none !important;
        box-shadow: none !important; }
    .picture-xml-edit .header .center {
      flex: 1; }
      .picture-xml-edit .header .center span {
        font-size: 24px;
        margin-right: 24px;
        cursor: pointer; }
      .picture-xml-edit .header .center .icon-fanhuibanben {
        border-right: 1px solid #ccc;
        padding-right: 24px; }
    .picture-xml-edit .header .right {
      width: 199px;
      display: flex;
      justify-content: center; }
      .picture-xml-edit .header .right .btn1 {
        width: 64px;
        height: 40px;
        background: #264DF0;
        border-radius: 6px;
        color: #fff; }
      .picture-xml-edit .header .right .btn2 {
        width: 64px;
        height: 40px;
        background: #222529;
        border-radius: 6px;
        color: #fff;
        margin-left: 10px; }
  .picture-xml-edit .body {
    height: calc(100% - 72px);
    width: 100%;
    background: #F5F7FA;
    display: flex; }
    .picture-xml-edit .body .nav {
      height: 100%;
      width: 72px;
      background: #FFFFFF;
      border-right: 1px solid #E2E2E2; }
      .picture-xml-edit .body .nav ul {
        width: 100%;
        padding: 0;
        margin: 0; }
        .picture-xml-edit .body .nav ul li {
          margin: 6px;
          height: 66px;
          background: #FFFFFF;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          color: #505760; }
          .picture-xml-edit .body .nav ul li .iconfont {
            font-size: 22px; }
        .picture-xml-edit .body .nav ul .navOn {
          background: rgba(38, 77, 240, 0.09);
          color: #264DF0; }
    .picture-xml-edit .body .operationLeft {
      height: 100%;
      width: 320px;
      background: #FFFFFF;
      border-right: 1px solid #E2E2E2;
      padding: 24px 16px; }
      .picture-xml-edit .body .operationLeft .operationMain {
        width: 288px; }
        .picture-xml-edit .body .operationLeft .operationMain .upload-btn {
          width: 100%;
          height: 40px;
          background: #F5F7FA;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #264DF0;
          position: relative;
          margin-bottom: 24px; }
          .picture-xml-edit .body .operationLeft .operationMain .upload-btn span {
            margin-right: 10px; }
          .picture-xml-edit .body .operationLeft .operationMain .upload-btn input {
            position: absolute;
            opacity: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%; }
        .picture-xml-edit .body .operationLeft .operationMain .line {
          width: 100%;
          height: 1px;
          background: #E2E2E2; }
        .picture-xml-edit .body .operationLeft .operationMain .classify {
          display: flex;
          flex-wrap: wrap;
          margin-top: 24px; }
          .picture-xml-edit .body .operationLeft .operationMain .classify .classify-btn {
            padding: 8px 12px;
            background: #F5F7FA;
            border-radius: 2px;
            margin: 5px 5px 16px 0; }
          .picture-xml-edit .body .operationLeft .operationMain .classify .classify-btn-active {
            color: #FFFFFF;
            background: #264DF0; }
        .picture-xml-edit .body .operationLeft .operationMain .classify-unfold {
          width: 64px;
          height: 24px;
          background: #F5F7FA;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #89919D;
          margin: 0 auto; }
          .picture-xml-edit .body .operationLeft .operationMain .classify-unfold .iconfont {
            font-size: 14px;
            color: #89919D; }
        .picture-xml-edit .body .operationLeft .operationMain .mask-body ul {
          display: flex;
          flex-wrap: wrap; }
          .picture-xml-edit .body .operationLeft .operationMain .mask-body ul li {
            width: 136px;
            height: 136px;
            background: #F7F8FA;
            margin-top: 15px; }
            .picture-xml-edit .body .operationLeft .operationMain .mask-body ul li .mask-item {
              padding: 10px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative; }
              .picture-xml-edit .body .operationLeft .operationMain .mask-body ul li .mask-item .img {
                max-width: 100%;
                max-height: 100%;
                filter: brightness(85%); }
              .picture-xml-edit .body .operationLeft .operationMain .mask-body ul li .mask-item .delete {
                color: #fff;
                width: 32px;
                height: 32px;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 0px 0px 0px 6px;
                position: absolute;
                right: 0;
                top: 0;
                display: none;
                align-items: center;
                justify-content: center; }
            .picture-xml-edit .body .operationLeft .operationMain .mask-body ul li .mask-item:hover .delete {
              display: flex; }
            .picture-xml-edit .body .operationLeft .operationMain .mask-body ul li .mask-item:hover {
              border: 1px solid #264df0; }
          .picture-xml-edit .body .operationLeft .operationMain .mask-body ul li:nth-child(2n-1) {
            margin-right: 15px; }
        .picture-xml-edit .body .operationLeft .operationMain .noData {
          margin-top: 12px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #89919D; }
      .picture-xml-edit .body .operationLeft .operationFont ul li {
        width: 288px;
        height: 76px;
        background: #F5F7FA;
        border-radius: 2px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px; }
        .picture-xml-edit .body .operationLeft .operationFont ul li img {
          max-width: 100%;
          max-height: 100%; }
    .picture-xml-edit .body .main {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative; }
      .picture-xml-edit .body .main .top-btn {
        width: 100%;
        height: 40px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 99; }
      .picture-xml-edit .body .main .canvas {
        flex: 1;
        overflow: hidden;
        position: relative; }
      .picture-xml-edit .body .main .unfoldLeft {
        position: absolute;
        width: 20px;
        height: 112px;
        background: #FFFFFF;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 20px 20px 0;
        z-index: 999; }
      .picture-xml-edit .body .main .unfoldRight {
        position: absolute;
        width: 20px;
        height: 112px;
        background: #FFFFFF;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px 0 0 20px;
        z-index: 999; }
    .picture-xml-edit .body .right {
      width: 200px;
      background: #FFFFFF;
      border-left: 1px solid #E2E2E2; }
      .picture-xml-edit .body .right .attribute-head {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border-bottom: 1px solid #E2E2E2;
        font-size: 14px;
        font-weight: bold;
        color: #222529;
        display: flex;
        align-items: center;
        justify-content: center; }
      .picture-xml-edit .body .right .attribute-body {
        padding: 0 16px; }
        .picture-xml-edit .body .right .attribute-body .attribute-body-module {
          border-bottom: 1px solid #E2E2E2;
          padding: 24px 0 16px; }
          .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-title {
            font-size: 14px;
            font-weight: bold;
            color: #222529;
            line-height: 20px; }
          .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form {
            border: 1px solid #E2E2E2;
            padding: 8px;
            display: flex;
            margin-top: 16px; }
            .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-input {
              flex: 1;
              position: relative; }
              .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-input input {
                width: 100%;
                padding: 0 10px 0 20px;
                border: none !important;
                box-shadow: none !important; }
              .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-input span {
                position: absolute;
                left: 0px;
                top: 0;
                color: #89919D; }
            .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-select {
              width: 65px;
              border-left: 1px solid #E2E2E2;
              padding: 0 0 0 10px; }
              .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-select select {
                border: none !important;
                width: 100%;
                box-shadow: none !important;
                padding: 0; }
            .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-input2 {
              position: relative; }
              .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-input2 input {
                width: 100%;
                padding: 0 65px 0 10px;
                border: none !important;
                box-shadow: none !important; }
              .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form .attribute-body-module-form-input2 span {
                position: absolute;
                right: 0px;
                top: 0;
                color: #89919D;
                border-left: 1px solid #E2E2E2;
                width: 65px;
                display: flex;
                align-items: center;
                justify-content: center; }
            .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form textarea {
              width: 100%;
              height: 80px;
              border: none; }
            .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form textarea:hover, .picture-xml-edit .body .right .attribute-body .attribute-body-module .attribute-body-module-form textarea:focus {
              border: none !important;
              box-shadow: none !important; }
        .picture-xml-edit .body .right .attribute-body .thumbnail-title {
          margin-top: 24px;
          font-size: 14px;
          font-weight: bold;
          color: #222529;
          line-height: 20px; }
        .picture-xml-edit .body .right .attribute-body .thumbnail {
          margin-top: 16px; }
  .picture-xml-edit .top-btn-left {
    display: flex;
    align-items: center; }
    .picture-xml-edit .top-btn-left > .iconfont {
      width: 32px;
      height: 32px;
      background: #F5F7FA;
      border-radius: 4px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      cursor: pointer;
      position: relative;
      z-index: 1000; }
    .picture-xml-edit .top-btn-left .btn-avtive {
      border: 2px dashed #264df0; }
  .picture-xml-edit .top-btn-right {
    display: flex;
    align-items: center; }
    .picture-xml-edit .top-btn-right > .iconfont {
      width: 32px;
      height: 32px;
      background: #F5F7FA;
      border-radius: 4px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      cursor: pointer;
      position: relative;
      z-index: 1000; }
    .picture-xml-edit .top-btn-right .btn-avtive {
      border: 2px dashed #264df0; }
  .picture-xml-edit .roundedSet {
    position: absolute;
    width: 272px;
    background: #FFFFFF;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.14));
    left: 50%;
    top: 125%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #222529;
    padding: 8px;
    border-radius: 8px;
    z-index: 1000; }
    .picture-xml-edit .roundedSet .roundedSet-title {
      margin-top: 20px;
      padding: 0px 16px;
      display: flex !important;
      justify-content: space-between;
      margin-bottom: 10px; }
      .picture-xml-edit .roundedSet .roundedSet-title .rightPanel-title {
        font-size: 16px;
        color: #222529;
        text-align: left;
        margin-top: 0px;
        font-weight: bold;
        margin-bottom: 0; }
      .picture-xml-edit .roundedSet .roundedSet-title .filletType {
        display: inline-block;
        padding: 0 10px;
        background: #F5F7FA;
        color: #505760; }
      .picture-xml-edit .roundedSet .roundedSet-title .filletTypeActive {
        color: #264DF0;
        background: rgba(38, 77, 240, 0.09); }
    .picture-xml-edit .roundedSet .align-view {
      width: 100%;
      display: flex !important;
      flex-wrap: wrap; }
    .picture-xml-edit .roundedSet .align-view > div {
      width: 50%;
      display: flex !important;
      margin-top: 0px;
      padding: 9px 16px; }
    .picture-xml-edit .roundedSet .align-view > div:hover {
      background: #F5F7FA; }
    .picture-xml-edit .roundedSet .align-view > div > div {
      margin-left: 10px; }
    .picture-xml-edit .roundedSet .border-radio-btn {
      display: flex !important;
      width: 50px;
      align-items: center;
      justify-content: space-evenly; }
    .picture-xml-edit .roundedSet .c-schedule-view {
      display: flex !important;
      align-items: center;
      padding: 0 16px;
      width: 100%; }
      .picture-xml-edit .roundedSet .c-schedule-view > span {
        margin-left: 5px; }
      .picture-xml-edit .roundedSet .c-schedule-view > .iconfont {
        font-size: 20px;
        margin-right: 10px; }
    .picture-xml-edit .roundedSet .c-schedule-view2 {
      display: flex !important;
      align-items: center;
      padding: 0 0 0 16px;
      width: calc(50% - 10px); }
      .picture-xml-edit .roundedSet .c-schedule-view2 > span {
        margin-left: 5px; }
      .picture-xml-edit .roundedSet .c-schedule-view2 .c-schedule-title {
        width: 70% !important; }
    .picture-xml-edit .roundedSet .coverageItem {
      height: 40px; }
    .picture-xml-edit .roundedSet .coverageItem:hover {
      background: #F5F7FA; }
    .picture-xml-edit .roundedSet .c-schedule-title {
      display: block;
      width: 40px !important;
      font-size: 12px; }
    .picture-xml-edit .roundedSet .bezel-set-color {
      width: 30px;
      height: 30px;
      border-radius: 3px;
      overflow: hidden;
      cursor: pointer;
      border: 1px solid #ddd; }
    .picture-xml-edit .roundedSet .bezel-set-color input {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer; }
  .picture-xml-edit .roundedSet::after {
    content: "";
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px;
    border-color: transparent transparent #fff;
    position: absolute;
    z-index: 9999; }
  .picture-xml-edit .disabled-btn {
    opacity: 0.6;
    cursor: no-drop !important; }
  .picture-xml-edit .sel-top {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    left: -7px;
    top: -7px;
    cursor: se-resize; }
  .picture-xml-edit .sel-top1 {
    width: 16px;
    height: 6px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    left: 50%;
    top: -4px;
    margin-left: -7px;
    cursor: n-resize; }
  .picture-xml-edit .sel-right {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    right: -7px;
    top: -7px;
    cursor: sw-resize; }
  .picture-xml-edit .sel-right-lock {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    right: -10px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .picture-xml-edit .sel-right1 {
    width: 6px;
    height: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    right: -4px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize; }
  .picture-xml-edit .sel-bottom {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    right: -4px;
    bottom: -7px;
    cursor: se-resize; }
  .picture-xml-edit .sel-bottom1 {
    width: 16px;
    height: 6px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    left: 50%;
    margin-left: -7px;
    z-index: 1;
    cursor: n-resize; }
  .picture-xml-edit .sel-left {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    left: -7px;
    bottom: -7px;
    cursor: sw-resize; }
  .picture-xml-edit .sel-left1 {
    width: 6px;
    height: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    left: -4px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize; }
  .picture-xml-edit .rotate-icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    outline: none;
    margin: -4px 0 0 14px;
    border-radius: 12px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    opacity: 0.6; }
  .picture-xml-edit .rotate-icon:hover,
  .picture-xml-edit .rotate-icon-active {
    opacity: 1; }
  .picture-xml-edit .selected {
    outline: 1px solid #264DF0 !important; }
  .picture-xml-edit .rotate-box {
    position: absolute;
    text-align: center;
    outline: none; }
  .picture-xml-edit .image-placeholder-picture-xml {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .picture-xml-edit .image-placeholder-picture-xml img {
    width: 28px; }
  .picture-xml-edit .image-placeholder-picture-xml span {
    margin-top: 7px;
    color: #333333; }
  .picture-xml-edit .image-placeholder-picture-xml-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .picture-xml-edit .image-placeholder-picture-xml-2 img {
    width: 20px; }
  .picture-xml-edit .image-placeholder-picture-xml-2 span {
    margin-top: 7px;
    color: #333333; }
  .picture-xml-edit .c-xml-page-material {
    position: absolute; }
  .picture-xml-edit .text-range {
    display: inline-block !important;
    -webkit-appearance: none;
    width: 140px !important;
    padding: 2px !important;
    height: 2px;
    border-radius: 5px;
    margin: 18px auto;
    outline: 0;
    flex: 1;
    padding: 0px !important;
    background: -webkit-linear-gradient(#264DF0, #264DF0) no-repeat, #D1D5DC;
    /*设置左边颜色为#61bd12，右边颜色为#ddd*/
    background-color: #D1D5DC; }
  .picture-xml-edit input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /*清除系统默认样式*/
    width: 14px;
    height: 14px;
    background: #FFFFFF;
    border: 2px solid #D1D5DC; }
  .picture-xml-edit .c-font-view {
    position: relative;
    width: 200px; }
  .picture-xml-edit .c-font-view .c-font-view-head2 {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-size: auto 11px;
    border-radius: 3px;
    border: 1px solid #ddd;
    position: relative;
    padding-left: 20px; }
  .picture-xml-edit .c-font-view .c-font-view-head2 > div {
    width: 75%;
    text-align: center; }
  .picture-xml-edit .c-font-view .c-font-view-head2 > div img {
    width: 100%; }
  .picture-xml-edit .c-font-view .c-font-view-body {
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    background: #fff;
    border-radius: 0 0 6px 6px;
    border: 1px solid #ddd;
    border-top: 0;
    padding: 10px;
    display: none;
    z-index: 999;
    opacity: 0; }
  .picture-xml-edit .c-font-view-body-ul {
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto; }
  .picture-xml-edit .c-font-view-body-ul .c-font-view-body-li {
    width: 100%;
    height: 36px;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
    font-size: 12px;
    transition: all 0.3s; }
  .picture-xml-edit .c-font-view-body-ul .c-font-view-body-li img {
    max-height: 100%;
    max-width: 100%; }
  .picture-xml-edit .c-font-view-body-ul .c-font-view-body-li:hover {
    background: #f5f7fa;
    border-radius: 2px; }
  .picture-xml-edit .c-font-view-body-li-on {
    background: #f5f7fa;
    border-radius: 2px; }
  .picture-xml-edit .c-xml-page-material-pitch, .picture-xml-edit .c-xml-page-textboxe-pitch {
    border: 1px solid #264df0; }
  .picture-xml-edit .c-xml-page-material-zoom {
    display: none;
    position: absolute;
    width: 14px;
    height: 14px; }
  .picture-xml-edit .c-xml-page-material-zoom-left {
    width: 6px;
    height: 16px;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    left: -4px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize; }
  .picture-xml-edit .c-xml-page-material-zoom-left1 {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    left: -7px;
    top: -7px;
    cursor: se-resize; }
  .picture-xml-edit .c-xml-page-material-zoom-top {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    right: -7px;
    top: -7px;
    cursor: sw-resize; }
  .picture-xml-edit .c-xml-page-material-zoom-top1 {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    right: -7px;
    top: -7px;
    cursor: sw-resize; }
  .picture-xml-edit .c-xml-page-material-zoom-right {
    width: 6px;
    height: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    right: -4px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize; }
  .picture-xml-edit .c-xml-page-material-zoom-right1 {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    right: -7px;
    bottom: -7px;
    cursor: se-resize; }
  .picture-xml-edit .c-xml-page-material-zoom-bottom {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    left: -7px;
    bottom: -7px;
    cursor: sw-resize; }
  .picture-xml-edit .c-xml-page-material-zoom-bottom1 {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    left: -7px;
    bottom: -7px;
    cursor: sw-resize; }
  .picture-xml-edit .c-xml-page-material-pitch, .picture-xml-edit .c-xml-page-textboxe-pitch {
    border: 1px solid #264DF0; }
  .picture-xml-edit .c-xml-page-material-rotate {
    position: absolute;
    left: 50%;
    bottom: -20px;
    height: 20px;
    display: none;
    background: none; }
  .picture-xml-edit .c-xml-page-material-rotate-icon {
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 20px;
    background: url("../images/icons/rotate_gray.svg") no-repeat;
    margin: -10px 0 0 -10px; }
  .picture-xml-edit .c-xml-page-material-lock {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    width: 16px;
    height: 16px;
    right: -7px;
    top: -7px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .picture-xml-edit .c-xml-page-material-lock .iconfont {
      font-size: 12px; }
  .picture-xml-edit .c-xml-action-icon-font {
    float: left; }
    .picture-xml-edit .c-xml-action-icon-font li {
      position: relative;
      padding-top: 0;
      margin: 0 5px;
      float: left; }
    .picture-xml-edit .c-xml-action-icon-font > .iconfont {
      width: 32px;
      height: 32px;
      background: #f5f7fa;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px; }
    .picture-xml-edit .c-xml-action-icon-font .toolsSelected {
      color: #264DF0;
      font-weight: bold; }
  .picture-xml-edit .c-xml-action-div-transparency {
    position: absolute;
    top: 40px;
    left: 50%;
    border-radius: 3px;
    padding: 10px;
    margin-left: -135px;
    z-index: 999;
    width: 320px;
    height: 225px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
    border: none; }
  .picture-xml-edit .c-xml-action-div-transparency-title {
    display: block;
    width: 50px;
    font-size: 14px;
    color: #000;
    float: left;
    height: 30px;
    line-height: 30px; }
  .picture-xml-edit .c-xml-action-div-transparency-bar {
    position: relative;
    height: 30px;
    width: 224px;
    margin: 0 15px;
    overflow: hidden; }
  .picture-xml-edit .c-xml-action-div-transparency-bar1 {
    position: absolute;
    top: 13.5px;
    width: 100%;
    height: 3px;
    background: #ddd;
    border-radius: 1px; }
  .picture-xml-edit .c-xml-action-div-transparency-bar2 {
    position: absolute;
    left: 0;
    top: 10px;
    display: block;
    width: 10px;
    height: 10px;
    background: #4d4d4d;
    box-shadow: 0 0 3px #fff;
    cursor: pointer;
    border-radius: 10px; }
  .picture-xml-edit .c-xml-action-div-transparency-input {
    float: left;
    width: 50px;
    height: 30px;
    border: 1px solid #ddd;
    background: #f3f3f3;
    color: #000;
    text-align: center; }

.hover-scrollbar {
  overflow-y: overlay;
  overflow-x: hidden; }

/*  */
.hover-scrollbar:hover.hover-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 4px solid #ffffff; }

.hover-scrollbar:hover.hover-scrollbar::-webkit-scrollbar-track {
  background-color: #2b2c2f00;
  display: block; }

.hover-scrollbar::-webkit-scrollbar {
  display: block;
  width: 16px !important; }

/* 这是针对缺省样式 (必须的) */
.hover-scrollbar::-webkit-scrollbar-track {
  background-color: #2b2c2f00; }

/* 滚动条的滑轨背景颜色 */
.hover-scrollbar::-webkit-scrollbar-thumb {
  background-color: #2b2c2f00;
  border-radius: 20px;
  border: 4px solid #2b2c2f00;
  transition-duration: 5s;
  cursor: pointer !important;
  display: block; }

/* 滑块颜色 */
.hover-scrollbar::-webkit-scrollbar-button {
  display: none;
  background-color: #fff; }

/* 滑轨两头的监听按钮颜色 */
.hover-scrollbar::-webkit-scrollbar-corner {
  background-color: #fff; }

.shake3 {
  animation: shake 0.5s;
  animation-iteration-count: 3;
  color: #ffba2b !important; }

/* 定义动画（左右摇晃） */
@keyframes shake {
  0% {
    transform: rotateZ(-10deg); }
  50% {
    transform: rotateZ(10deg); }
  100% {
    transform: rotateZ(-10deg); } }

.graduatedScale-unit {
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  padding: 5px 0 0 10px;
  font-size: 12px;
  color: #666;
  background: #F5F7FA;
  z-index: 10; }

.rulerWrapper .rulerPointer {
  position: absolute;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 15px solid #fff;
  left: 50%;
  margin-left: -10px;
  display: none; }

.rulerWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 35px;
  overflow: hidden;
  z-index: 1; }

.rulerWrapper .rulerScroller {
  position: absolute;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  left: -10px; }

.rulerWrapper .rulerScroller ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100px;
  height: 8px;
  float: left;
  border-right: 1px solid #666;
  position: relative; }

.rulerWrapper .rulerScroller li {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  width: 10px;
  float: left;
  border-right: 1px solid #999;
  height: 6px; }

.rulerWrapper .doTime span {
  margin-left: -15px; }

.rulerWrapper .sizeNo {
  position: absolute;
  color: #666;
  top: 7px;
  display: block;
  text-align: center;
  font-size: 12px; }

/*竖版*/
.rulerWrapper1 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 35px;
  overflow: hidden; }

.rulerWrapper1 .rulerPointer {
  position: absolute;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 15px solid #fff;
  top: 50%;
  margin-top: -10px;
  display: none; }

.rulerWrapper1 .rulerScroller {
  position: absolute;
  top: 0; }

.rulerWrapper1 .rulerScroller ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100px;
  width: 8px;
  border-bottom: 1px solid #666;
  position: relative; }

.rulerWrapper1 .rulerScroller li {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  height: 10px;
  border-bottom: 1px solid #999;
  width: 6px; }

.rulerWrapper1 .doTime span {
  margin-top: -15px;
  writing-mode: vertical-lr; }

.rulerWrapper1 .sizeNo {
  position: absolute;
  color: #666;
  left: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  writing-mode: vertical-lr; }

.c-ruler-left {
  position: absolute;
  top: 0;
  background: #2dceed;
  color: #b4f1ff;
  height: 20px;
  display: flex;
  align-items: center;
  pointer-events: none;
  padding: 0 1px; }

.c-ruler-y {
  position: absolute;
  left: 0;
  background: #2dceed;
  color: #b4f1ff;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 0 1px; }

.ant-input-number {
  margin-right: 10px; }
  .ant-input-number input[type="number"] {
    padding: 0 8px;
    border: none;
    font-size: 14px;
    border-radius: 0;
    transition: all .2s; }
  .ant-input-number input[type="number"]:focus {
    outline: none;
    box-shadow: none;
    border: none !important; }
  .ant-input-number input[type="number"]:hover {
    box-shadow: none;
    border: none !important; }
  .ant-input-number input[type="number"]::-webkit-inner-spin-button,
  .ant-input-number input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.ant-input-number:hover {
  border-color: #264DF0;
  border-right-width: 1px !important; }

.ant-input-number {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  -webkit-transition: all .3s;
  transition: all .3s;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px; }

.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1; }

.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis; }

.ant-input-number:placeholder-shown {
  text-overflow: ellipsis; }

.ant-input-number:hover {
  border-color: #264DF0;
  border-right-width: 1px !important; }

.ant-input-number:focus {
  border-color: #264DF0;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  -webkit-transition: opacity .24s linear .1s;
  transition: opacity .24s linear .1s; }

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1; }

.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  -webkit-transition: opacity .24s linear .1s;
  transition: opacity .24s linear .1s;
  display: flex;
  flex-direction: column; }

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  -webkit-transform: scale(0.58333) rotate(0deg);
  -ms-transform: scale(0.58333) rotate(0deg);
  transform: scale(0.58333) rotate(0deg);
  min-width: auto;
  margin-right: 0; }

.ant-input-number-handler {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 0;
  text-align: center;
  -webkit-transition: all .1s linear;
  transition: all .1s linear; }

.ant-input-number-handler-up {
  border-top-right-radius: 4px;
  cursor: pointer; }

.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 4px;
  cursor: pointer; }

.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  -moz-appearance: textfield !important; }

.ant-input-number-handler-up:hover {
  height: 70% !important; }

.ant-input-number-handler-down:hover {
  height: 70% !important; }

.ant-input-number-handler-up-inner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }

.ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #264DF0; }

.ant-input-number-handler-down-inner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  -webkit-transform: scale(0.58333) rotate(0deg);
  -ms-transform: scale(0.58333) rotate(0deg);
  transform: scale(0.58333) rotate(0deg);
  min-width: auto;
  margin-right: 0; }

.ant-input-number2 {
  display: flex;
  align-items: center;
  justify-content: center; }
  .ant-input-number2 input[type="number"] {
    padding: 0 8px;
    border: none;
    font-size: 14px;
    border-radius: 0;
    transition: all .2s; }
  .ant-input-number2 input[type="number"]:focus {
    outline: none;
    box-shadow: none;
    border: none !important; }
  .ant-input-number2 input[type="number"]:hover {
    box-shadow: none;
    border: none !important; }
  .ant-input-number2 input[type="number"]::-webkit-inner-spin-button,
  .ant-input-number2 input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .ant-input-number2 > input {
    width: 100%;
    height: 100%; }
  .ant-input-number2 .ant-input-number2-handler-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .2s;
    width: 20px;
    height: 20px; }
    .ant-input-number2 .ant-input-number2-handler-wrap i {
      font-size: 12px;
      transform: scale(0.8);
      cursor: pointer;
      width: 10px;
      height: 10px;
      position: relative;
      bottom: 2px; }
    .ant-input-number2 .ant-input-number2-handler-wrap i:hover {
      color: #000; }

.ant-input-number2:hover .ant-input-number2-handler-wrap {
  opacity: 1; }

* {
  margin: 0;
  padding: 0; }

@font-face {
  font-family: TitleFont;
  src: url("../fonts/YouSheBiaoTiHei-2.ttf"); }

em, i {
  font-style: normal; }

li {
  list-style: none; }

img {
  border: 0;
  /*取消图片底侧有空白缝隙的问题*/
  vertical-align: middle; }

button {
  cursor: pointer; }

a {
  color: #666;
  text-decoration: none; }

a:hover {
  color: #c81623; }

button, input {
  font-family: Microsoft YaHei, Heiti SC, tahoma, arial, Hiragino Sans GB, "\5B8B\4F53", sans-serif; }

body {
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  font: 12px/1.5 Microsoft YaHei, Heiti SC, tahoma, arial, Hiragino Sans GB, "\5B8B\4F53", sans-serif;
  color: #222529; }

.hide, .none {
  display: none; }

.clearfix:after {
  visibility: hidden;
  clear: both;
  display: block;
  content: ".";
  height: 0; }

.clearfix {
  *zoom: 1; }

input[type="text"], input[type="password"], input[type="number"], select, textarea {
  padding: 0 8px;
  border: 1px solid #E1E5EB;
  font-size: 14px;
  border-radius: 4px;
  transition: all .2s; }

input[type="text"]:hover, input[type="password"]:hover, input[type="number"]:hover, select:hover, textarea:hover {
  box-shadow: 0px 0px 4px 0px rgba(58, 124, 255, 0.45);
  border: 1px solid #264DF0 !important; }

input[type="text"]:focus, input[type="password"]:focus, input[type="number"]:focus, select:focus, textarea:focus {
  outline: none;
  box-shadow: 0px 0px 4px 0px rgba(58, 124, 255, 0.45);
  border: 1px solid #264DF0 !important; }

input[type="radio"], input[type="checkbox"] {
  margin: 0;
  line-height: normal;
  width: auto;
  height: auto; }

.c-link {
  display: inline-block;
  cursor: pointer;
  color: #264DF0;
  padding-left: 5px; }

.c-link:first-child {
  padding-left: 0; }

.c-link:last-child {
  padding-right: 0; }

.cursor-pointer {
  cursor: pointer; }

.c-color {
  color: #264DF0; }

.c-color1 {
  color: #6D7278; }

.c-color2 {
  color: #0AB967; }

.c-color3 {
  color: #32C5FF; }

.c-color4 {
  color: #FA6400; }

.c-color5 {
  color: #F2242D !important; }

.slh {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis; }

.slh2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/*隐藏滚动条*/
.scrollbar-none::-webkit-scrollbar {
  display: none; }

/*滚动条整体样式*/
.scrollbar-main::-webkit-scrollbar, .c-table::-webkit-scrollbar, .c-table1::-webkit-scrollbar {
  width: 8px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px; }

/*滚动条里面小方块*/
.scrollbar-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D0D0D0; }

.scrollbar-main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff; }

.iconfont {
  font-size: 14px; }

.btn {
  min-width: 60px;
  height: 30px;
  border-radius: 4px;
  transition: all .1s;
  padding: 0 16px;
  line-height: 30px;
  font-size: 14px; }

.btn:hover, .btn:focus, .btn:active, .btn:visited {
  outline: none !important;
  box-shadow: none !important; }

.btn-max {
  height: 40px;
  line-height: 40px;
  padding: 0 40px; }

.btn-info {
  color: #fff;
  background: #264DF0;
  border: 1px solid #264DF0; }

.btn-info[disabled]:hover {
  background: #264DF0;
  border: 1px solid #264DF0; }

input:disabled {
  background: #F5F7FA; }

.btn-info:hover {
  background: rgba(58, 124, 255, 0.85);
  border: 1px solid rgba(58, 124, 255, 0.85); }

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background: rgba(58, 124, 255, 0.85);
  border: 1px solid rgba(58, 124, 255, 0.85); }

.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  color: #fff;
  background: rgba(58, 124, 255, 0.85);
  border: 1px solid rgba(58, 124, 255, 0.85); }

.btn-info:active:hover, .btn-info.active:hover, .open > .dropdown-toggle.btn-info:hover, .btn-info:active:focus, .btn-info.active:focus, .open > .dropdown-toggle.btn-info:focus, .btn-info:active.focus, .btn-info.active.focus, .open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background: rgba(58, 124, 255, 0.85);
  border: 1px solid rgba(58, 124, 255, 0.85); }

.btn-cancel {
  color: #505760;
  background: #fff;
  border: 1px solid #fff; }

.align-items-center {
  display: flex;
  align-items: center; }

.mt10 {
  margin-top: 10px; }

.mt20 {
  margin-top: 20px; }

.c-home-head {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 72px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2E2E2;
  z-index: 1; }
  .c-home-head .c-home-head-fl {
    height: 72px;
    display: flex;
    align-items: center; }
    .c-home-head .c-home-head-fl .logo {
      height: 40px;
      cursor: pointer; }
    .c-home-head .c-home-head-fl .c-home-head-nav {
      margin-left: 200px;
      height: 100%;
      display: flex; }
      .c-home-head .c-home-head-fl .c-home-head-nav .c-home-head-nav-li {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 48px;
        transition: all .1s ease;
        font-size: 16px; }
        .c-home-head .c-home-head-fl .c-home-head-nav .c-home-head-nav-li .c-home-head-nav-li-hr {
          display: none;
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 40px;
          height: 4px;
          margin-left: -20px;
          background: #264DF0; }
      .c-home-head .c-home-head-fl .c-home-head-nav .c-home-head-nav-li:hover {
        color: #264DF0; }
      .c-home-head .c-home-head-fl .c-home-head-nav .c-home-head-nav-li-selected {
        color: #264DF0; }
        .c-home-head .c-home-head-fl .c-home-head-nav .c-home-head-nav-li-selected .c-home-head-nav-li-hr {
          display: block; }
  .c-home-head .c-home-head-fr {
    height: 100%;
    display: flex; }
    .c-home-head .c-home-head-fr .c-home-message {
      position: relative;
      height: 100%; }
      .c-home-head .c-home-head-fr .c-home-message .c-home-message-1 {
        height: 100%;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; }
        .c-home-head .c-home-head-fr .c-home-message .c-home-message-1 i {
          font-size: 30px;
          color: #0a0a0a; }
        .c-home-head .c-home-head-fr .c-home-message .c-home-message-1 .c-home-message-quantity {
          position: absolute;
          top: 16px;
          right: 3px;
          background: #FB0000;
          font-size: 12px;
          padding: 0px 6px;
          border-radius: 50%;
          color: #fff; }
      .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 {
        position: absolute;
        right: 0;
        top: 60px;
        width: 448px;
        background: #FFFFFF;
        box-shadow: 0px 1px 8px 0px rgba(34, 37, 41, 0.12);
        border-radius: 8px;
        display: none; }
        .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-1 {
          height: 76px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 24px; }
          .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-1 .c-home-message-2-1-title {
            font-size: 20px; }
        .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 {
          max-height: 300px;
          overflow: auto;
          padding: 0 24px; }
          .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li {
            display: flex;
            margin-bottom: 10px; }
            .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-icon {
              width: 60px;
              min-width: 60px;
              height: 94px;
              display: flex;
              align-items: center; }
              .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-icon img {
                max-width: 40px;
                max-height: 40px; }
            .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-fr {
              width: calc(100% - 60px);
              height: 94px;
              display: flex;
              flex-direction: column;
              justify-content: center; }
              .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-fr .c-home-message-2-2-li-fr1 {
                width: 100%;
                font-size: 16px;
                line-height: 22px;
                color: #222529;
                font-weight: bold; }
                .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-fr .c-home-message-2-2-li-fr1 .title {
                  max-width: 96%;
                  display: inline-block; }
                .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-fr .c-home-message-2-2-li-fr1 .unread-icon {
                  display: inline-block;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background: #FF4444;
                  margin-left: 4px;
                  vertical-align: top; }
              .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-fr .c-home-message-2-2-li-fr2 {
                width: 100%;
                font-size: 14px;
                color: #505760;
                line-height: 20px;
                margin: 8px 0; }
              .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-2 .c-home-message-2-2-li .c-home-message-2-2-li-fr .c-home-message-2-2-li-fr3 {
                width: 100%;
                font-size: 12px;
                color: #89919D;
                line-height: 20px; }
        .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-3 {
          width: 100%;
          height: 200px;
          font-size: 20px;
          color: #999;
          display: flex;
          justify-content: center;
          align-items: center; }
        .c-home-head .c-home-head-fr .c-home-message .c-home-message-2 .c-home-message-2-4 {
          width: 100%;
          height: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #264DF0;
          font-size: 16px;
          cursor: pointer; }
    .c-home-head .c-home-head-fr .c-home-message:hover .c-home-message-2 {
      display: block; }
    .c-home-head .c-home-head-fr .c-home-user {
      position: relative;
      height: 100%;
      margin-left: 20px; }
      .c-home-head .c-home-head-fr .c-home-user .c-home-user-1 {
        height: 100%;
        display: flex;
        align-items: center; }
        .c-home-head .c-home-head-fr .c-home-user .c-home-user-1 img {
          max-width: 40px;
          max-height: 40px; }
        .c-home-head .c-home-head-fr .c-home-user .c-home-user-1 .c-home-user-1-text {
          margin-left: 8px;
          color: #222529;
          font-size: 16px; }
      .c-home-head .c-home-head-fr .c-home-user .c-home-user-2 {
        position: absolute;
        right: 0;
        top: 70px;
        width: 220px;
        background: #FFFFFF;
        box-shadow: 0px 1px 8px 0px rgba(34, 37, 41, 0.12);
        border-radius: 8px;
        display: none;
        padding: 30px 16px 20px; }
        .c-home-head .c-home-head-fr .c-home-user .c-home-user-2 .c-home-user-2-hr {
          width: 100%;
          height: 1px;
          background: #ddd;
          margin: 24px 0; }
        .c-home-head .c-home-head-fr .c-home-user .c-home-user-2 .c-home-user-2-li {
          width: 100%;
          height: 44px;
          display: flex;
          align-items: center;
          transition: all .1s;
          cursor: pointer;
          color: #505760;
          padding: 0 16px;
          border-radius: 4px;
          font-size: 14px;
          margin-bottom: 16px; }
          .c-home-head .c-home-head-fr .c-home-user .c-home-user-2 .c-home-user-2-li:last-child {
            margin-bottom: 0; }
          .c-home-head .c-home-head-fr .c-home-user .c-home-user-2 .c-home-user-2-li .iconfont {
            margin-right: 8px;
            font-size: 16px; }
        .c-home-head .c-home-head-fr .c-home-user .c-home-user-2 .c-home-user-2-li:hover {
          background: #F5F7FA; }
    .c-home-head .c-home-head-fr .c-home-user:hover .c-home-user-2 {
      display: block; }

.c-home-nav {
  position: fixed;
  left: 0;
  top: 72px;
  bottom: 0;
  width: 264px;
  background: #fff;
  overflow: auto;
  padding: 32px;
  border-right: 1px solid #E2E2E2;
  z-index: 1; }
  .c-home-nav .c-home-nav-bg {
    width: 100%;
    margin-bottom: 20px; }
  .c-home-nav .c-home-nav-li {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-radius: 2px;
    transition: all .1s ease;
    margin-bottom: 10px;
    cursor: pointer; }
    .c-home-nav .c-home-nav-li i {
      margin-right: 8px;
      height: 18px; }
    .c-home-nav .c-home-nav-li span {
      font-size: 14px; }
  .c-home-nav .c-home-nav-li:hover, .c-home-nav .c-home-nav-li-selected {
    background: rgba(38, 77, 240, 0.09);
    color: #264DF0; }
  .c-home-nav .c-home-nav-title {
    line-height: 35px;
    color: #89919D; }

.c-home-body {
  position: fixed;
  top: 72px;
  left: 264px;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 32px; }

.c-home-body1 {
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0; }

.modal .modal-dialog {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%) !important; }

.modal-content {
  margin: 50px auto;
  background-clip: padding-box;
  background-color: unset;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  box-shadow: unset;
  outline: 0 none;
  position: relative;
  overflow: hidden; }

.c-model {
  background: #F7F8FA;
  border-radius: 8px; }
  .c-model .c-model-head {
    width: 100%;
    padding: 20px 24px 0;
    display: flex;
    justify-content: space-between; }
    .c-model .c-model-head .c-model-head-title {
      font-size: 18px;
      font-weight: bold; }
    .c-model .c-model-head .iconfont {
      font-size: 16px;
      cursor: pointer; }
  .c-model .c-model-body {
    margin-top: 16px;
    padding: 0 24px; }
    .c-model .c-model-body .c-model-body-view {
      background: #fff;
      padding: 20px;
      max-height: 500px;
      overflow: auto; }
  .c-model .c-model-foot {
    padding: 20px;
    text-align: center; }

.pw-view {
  margin-bottom: 24px; }
  .pw-view .pw-title {
    display: flex;
    justify-content: space-between;
    color: #505760;
    font-size: 14px; }
  .pw-view .pw-input {
    position: relative;
    margin-top: 16px;
    width: 100%;
    height: 40px; }
    .pw-view .pw-input input {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #E1E5EB;
      color: #222529;
      padding: 0 16px; }
    .pw-view .pw-input .iconfont {
      position: absolute;
      right: 16px;
      top: 12px;
      cursor: pointer; }

.errorView .pw-title {
  color: #FF4444; }

.errorView input {
  border: 1px solid #FF4444 !important; }

ul#strengthBar {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }
  ul#strengthBar .point {
    background: #ddd;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px; }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("../images/icons/icon-nextPage.svg"); }

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("../images/icons/icon-previousPage.svg"); }

.swiper-button-prev, .swiper-button-next {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  background-size: 7px;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 8px 0px rgba(34, 37, 41, 0.12);
  border-radius: 50%; }

.c-notData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #999; }

.mb16 {
  margin-bottom: 16px; }

.mb32 {
  margin-bottom: 32px; }

.pointer {
  cursor: pointer; }

.login_wrap,
.register_wrap {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden; }

.login_content,
.register_content {
  width: 100%;
  height: 100%;
  position: relative; }
  .login_content .login_bg,
  .login_content .register_bg,
  .register_content .login_bg,
  .register_content .register_bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: url("../images/login_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: 0; }
  .login_content .company_info,
  .register_content .company_info {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 32px;
    font-size: 14px;
    text-align: center;
    color: #505760; }

.login_box,
.register_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 920px;
  padding: 56px 0 80px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.6) 100%);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.7); }
  .login_box .login_logo,
  .register_box .login_logo {
    margin-bottom: 42px; }
    .login_box .login_logo img,
    .register_box .login_logo img {
      display: block;
      width: 180px;
      margin: 0 auto; }
  .login_box .login_title,
  .login_box .register_title,
  .register_box .login_title,
  .register_box .register_title {
    font-size: 28px;
    color: #222529;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    margin-bottom: 40px; }
  .login_box .login_form,
  .login_box .register_form,
  .register_box .login_form,
  .register_box .register_form {
    width: 400px;
    margin: 0 auto; }
    .login_box .login_form .form_item,
    .login_box .register_form .form_item,
    .register_box .login_form .form_item,
    .register_box .register_form .form_item {
      position: relative; }
      .login_box .login_form .form_item .input_icon,
      .login_box .register_form .form_item .input_icon,
      .register_box .login_form .form_item .input_icon,
      .register_box .register_form .form_item .input_icon {
        position: absolute;
        top: 0;
        line-height: 40px;
        font-size: 20px;
        color: #89919d; }
        .login_box .login_form .form_item .input_icon.left,
        .login_box .register_form .form_item .input_icon.left,
        .register_box .login_form .form_item .input_icon.left,
        .register_box .register_form .form_item .input_icon.left {
          left: 20px; }
        .login_box .login_form .form_item .input_icon.right,
        .login_box .register_form .form_item .input_icon.right,
        .register_box .login_form .form_item .input_icon.right,
        .register_box .register_form .form_item .input_icon.right {
          right: 20px; }
      .login_box .login_form .form_item input,
      .login_box .register_form .form_item input,
      .register_box .login_form .form_item input,
      .register_box .register_form .form_item input {
        width: 400px;
        height: 40px;
        border: 1px solid transparent;
        font-size: 14px;
        background: #fff;
        border-radius: 8px;
        padding: 0 20px;
        color: #222529; }
        .login_box .login_form .form_item input:-webkit-autofill,
        .login_box .register_form .form_item input:-webkit-autofill,
        .register_box .login_form .form_item input:-webkit-autofill,
        .register_box .register_form .form_item input:-webkit-autofill {
          font-size: 14px; }
        .login_box .login_form .form_item input::-webkit-input-placeholder,
        .login_box .register_form .form_item input::-webkit-input-placeholder,
        .register_box .login_form .form_item input::-webkit-input-placeholder,
        .register_box .register_form .form_item input::-webkit-input-placeholder {
          color: #89919d; }
        .login_box .login_form .form_item input.icon_left,
        .login_box .register_form .form_item input.icon_left,
        .register_box .login_form .form_item input.icon_left,
        .register_box .register_form .form_item input.icon_left {
          padding-left: 44px; }
        .login_box .login_form .form_item input.icon_right,
        .login_box .register_form .form_item input.icon_right,
        .register_box .login_form .form_item input.icon_right,
        .register_box .register_form .form_item input.icon_right {
          padding-right: 48px; }
        .login_box .login_form .form_item input.checkErr,
        .login_box .register_form .form_item input.checkErr,
        .register_box .login_form .form_item input.checkErr,
        .register_box .register_form .form_item input.checkErr {
          color: #ff4444;
          border-color: #ff4444; }
      .login_box .login_form .form_item button,
      .login_box .register_form .form_item button,
      .register_box .login_form .form_item button,
      .register_box .register_form .form_item button {
        width: 400px;
        height: 48px;
        border: 0;
        padding: 0;
        background: #264DF0;
        border-radius: 26px;
        font-size: 16px;
        color: #fff; }
    .login_box .login_form .footer_link,
    .login_box .register_form .footer_link,
    .register_box .login_form .footer_link,
    .register_box .register_form .footer_link {
      display: flex;
      justify-content: space-between; }
      .login_box .login_form .footer_link a,
      .login_box .register_form .footer_link a,
      .register_box .login_form .footer_link a,
      .register_box .register_form .footer_link a {
        text-decoration: none;
        font-size: 16px;
        color: #264DF0; }

.register_box,
.check_box {
  padding: 48px 0 48px 0; }
  .register_box .register_title,
  .check_box .register_title {
    margin-bottom: 48px; }
  .register_box .register_form .footer_link,
  .register_box .check_form .footer_link,
  .check_box .register_form .footer_link,
  .check_box .check_form .footer_link {
    justify-content: center; }
  .register_box .register_form .send_code input,
  .register_box .check_form .send_code input,
  .check_box .register_form .send_code input,
  .check_box .check_form .send_code input {
    padding-right: 100px; }
  .register_box .register_form .send_code .btn_code,
  .register_box .check_form .send_code .btn_code,
  .check_box .register_form .send_code .btn_code,
  .check_box .check_form .send_code .btn_code {
    position: absolute;
    display: block;
    text-decoration: none;
    top: 50%;
    right: 16px;
    color: #264DF0;
    font-size: 14px;
    transform: translate(0, -50%); }
    .register_box .register_form .send_code .btn_code.disabled,
    .register_box .check_form .send_code .btn_code.disabled,
    .check_box .register_form .send_code .btn_code.disabled,
    .check_box .check_form .send_code .btn_code.disabled {
      color: #89919d; }

.check_box {
  padding-top: 80px; }
  .check_box .check_form {
    width: 400px;
    margin: 0 auto; }
  .check_box .check_prompt {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #222529;
    margin-bottom: 25px; }
  .check_box .radio_group {
    display: flex;
    justify-content: center;
    margin-bottom: 64px; }
    .check_box .radio_group.isAccount {
      margin-bottom: 24px; }
    .check_box .radio_group .radio_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 16px; }
      .check_box .radio_group .radio_item:last-child {
        margin-left: 16px;
        margin-right: 0; }
    .check_box .radio_group .iconfont {
      color: #88919d;
      font-size: 16px;
      margin-right: 4px; }
      .check_box .radio_group .iconfont.active {
        color: #264DF0; }

.c-title {
  font-size: 14px;
  color: #505760; }

/*首页*/
.c-page-1 {
  width: 100%;
  height: 240px;
  background: url("../images/home-bg.png") no-repeat center;
  background-size: 100% 100%;
  padding: 40px 56px;
  display: flex; }
  .c-page-1 .c-page-1-1 {
    width: 592px;
    height: 160px;
    background: #F6F8FE;
    box-shadow: 0px 4px 16px 0px rgba(42, 81, 225, 0.4), inset 0px -8px 8px 0px #C6D5FD;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 36px 48px; }
    .c-page-1 .c-page-1-1 .c-page-1-1-view {
      display: flex; }
      .c-page-1 .c-page-1-1 .c-page-1-1-view .c-page-1-1-img {
        width: 88px;
        height: 88px;
        margin-right: 24px; }
      .c-page-1 .c-page-1-1 .c-page-1-1-view .c-page-1-1-1 {
        height: 88px;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
        .c-page-1 .c-page-1-1 .c-page-1-1-view .c-page-1-1-1 .c-page-1-1-1-img {
          height: 26px; }
        .c-page-1 .c-page-1-1 .c-page-1-1-view .c-page-1-1-1 .c-page-1-1-1-title {
          font-family: TitleFont;
          font-size: 35px;
          line-height: 35px; }
        .c-page-1 .c-page-1-1 .c-page-1-1-view .c-page-1-1-1 .c-page-1-1-1-text {
          font-size: 16px;
          color: #89919D;
          line-height: 22px; }
    .c-page-1 .c-page-1-1 .c-page-1-1-2 {
      width: 120px;
      height: 48px;
      background: #FFFFFF;
      box-shadow: 0px 3px 8px 0px rgba(42, 81, 225, 0.2), inset 0px -3px 4px 0px rgba(196, 212, 255, 0.49);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      transition: all .2s; }
      .c-page-1 .c-page-1-1 .c-page-1-1-2 span {
        color: #264DF0; }
      .c-page-1 .c-page-1-1 .c-page-1-1-2 .iconfont {
        color: #0a0a0a; }
  .c-page-1 .c-page-1-1:hover .c-page-1-1-2 {
    background: #264DF0;
    box-shadow: 0px 3px 8px 0px rgba(42, 81, 225, 0.2); }
    .c-page-1 .c-page-1-1:hover .c-page-1-1-2 span {
      color: #fff; }
    .c-page-1 .c-page-1-1:hover .c-page-1-1-2 .iconfont {
      color: #fff; }

.c-page-2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 32px; }
  .c-page-2 .c-page-2-li {
    width: calc(25% - 25px);
    height: 128px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    cursor: pointer;
    transition: all .1s; }
    .c-page-2 .c-page-2-li .c-page-2-li-name {
      font-size: 26px;
      font-weight: bold; }
    .c-page-2 .c-page-2-li .c-page-2-li-btn {
      width: 112px;
      height: 34px;
      background: #FFFFFF;
      box-shadow: 0px 3px 8px 0px rgba(42, 81, 225, 0.2), inset 0px -3px 4px 0px rgba(196, 212, 255, 0.49);
      border-radius: 17px;
      color: #505760;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .c-page-2 .c-page-2-li .c-page-2-li-btn .iconfont {
        margin-left: 5px; }
  .c-page-2 .c-page-2-li:hover .c-page-2-li-btn {
    color: #264DF0; }
  .c-page-2 .c-page-2-1 {
    background: url("../images/home-img1.png");
    background-size: 100% 100%; }
  .c-page-2 .c-page-2-2 {
    background: url("../images/home-img2.png");
    background-size: 100% 100%; }
  .c-page-2 .c-page-2-3 {
    background: url("../images/home-img3.png");
    background-size: 100% 100%; }
  .c-page-2 .c-page-2-4 {
    background: url("../images/home-img4.png");
    background-size: 100% 100%; }

.c-page-title {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  align-items: center; }
  .c-page-title .c-page-title-name {
    font-size: 28px;
    font-weight: 600; }
  .c-page-title .c-page-title-icon {
    font-size: 16px;
    color: #505760;
    cursor: pointer; }

.c-page-3 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px; }
  .c-page-3 .c-page-3-li {
    height: 40px;
    padding: 0 30px;
    line-height: 40px;
    background: #fff;
    border-radius: 22px;
    border: 1px solid #E1E5EB;
    font-size: 18px;
    transition: all .1s;
    cursor: pointer;
    margin: 0 16px 16px 0; }
  .c-page-3 .c-page-3-li:hover {
    color: #264DF0; }
  .c-page-3 .c-page-3-li-selected {
    color: #264DF0;
    background: rgba(38, 77, 240, 0.09);
    border: 1px solid rgba(38, 77, 240, 0.09); }

.c-page-4 {
  width: 100%;
  height: 280px;
  margin-top: 8px; }
  .c-page-4 .c-page-4-view {
    width: 100%;
    height: 100%; }
    .c-page-4 .c-page-4-view .c-page-4-img {
      position: relative;
      width: 100%;
      height: 232px;
      background: #F5F7FA;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }
      .c-page-4 .c-page-4-view .c-page-4-img img {
        max-width: 90%;
        max-height: 90%; }
      .c-page-4 .c-page-4-view .c-page-4-img .c-page-4-img-view {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        align-items: flex-end;
        padding: 16px;
        transition: all .1s ease; }
        .c-page-4 .c-page-4-view .c-page-4-img .c-page-4-img-view .c-page-4-img-view-btn {
          width: 100%;
          height: 40px;
          background: rgba(255, 255, 255, 0.95);
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all .2s; }
        .c-page-4 .c-page-4-view .c-page-4-img .c-page-4-img-view .c-page-4-img-view-btn:hover {
          color: #264DF0; }
    .c-page-4 .c-page-4-view .c-page-4-img:hover .c-page-4-img-view {
      display: flex; }
    .c-page-4 .c-page-4-view .c-page-4-name {
      font-size: 16px;
      margin-top: 16px;
      height: 22px;
      line-height: 22px;
      width: 100%; }
    .c-page-4 .c-page-4-view .c-page-4-size {
      height: 17px;
      line-height: 17px;
      color: #89919D;
      font-size: 12px;
      margin-top: 8px; }

.my-design .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  .my-design .header .header-left {
    flex: 1;
    background: linear-gradient(90deg, #F2F5FA 0%, #E0E8F6 100%);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 48px; }
  .my-design .header .header-right {
    flex: 1;
    height: 144px;
    background: linear-gradient(90deg, #F2F5FA 0%, #E0E8F6 100%);
    border-radius: 16px;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 48px; }
  .my-design .header .header-title {
    display: flex;
    align-items: center; }
    .my-design .header .header-title img {
      width: 88px; }
    .my-design .header .header-title .content {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .my-design .header .header-title .content .content-title1 {
        font-family: TitleFont;
        font-size: 35px;
        line-height: 35px; }
      .my-design .header .header-title .content .content-title2 {
        font-size: 16px;
        font-weight: 400;
        color: #89919D;
        margin-top: 17px; }
  .my-design .header .header-btn {
    width: 120px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222529;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer; }
    .my-design .header .header-btn .iconfont {
      margin-left: 5px; }
  .my-design .header .header-btn:hover {
    color: #264DF0; }

.my-design .body {
  margin-top: 28px; }
  .my-design .body .top1 {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .my-design .body .top1 .left {
      font-size: 20px;
      font-weight: 500;
      color: #222529; }
    .my-design .body .top1 .right {
      display: flex;
      align-items: center; }
      .my-design .body .top1 .right .input {
        width: 240px;
        height: 36px;
        position: relative; }
        .my-design .body .top1 .right .input input {
          width: 100%;
          height: 100%;
          border: 1px solid #E1E5EB;
          border-radius: 2px;
          padding-left: 40px; }
        .my-design .body .top1 .right .input .iconfont {
          position: absolute;
          left: 15px;
          top: 9px; }
      .my-design .body .top1 .right .btn1 {
        margin-left: 8px;
        width: 112px;
        height: 36px;
        border-radius: 2px;
        border: 1px solid #E1E5EB;
        display: flex;
        align-items: center;
        justify-content: center; }
        .my-design .body .top1 .right .btn1 .iconfont {
          margin-right: 10px; }
      .my-design .body .top1 .right .btn1:hover {
        color: #264DF0; }
      .my-design .body .top1 .right .btn2 {
        margin-left: 8px;
        width: 112px;
        height: 36px;
        background: #264DF0;
        border-radius: 2px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
  .my-design .body .top2 {
    margin-top: 24px; }
    .my-design .body .top2 .classify {
      padding: 20px 0;
      border-bottom: 1px solid #E2E2E2;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;
      font-size: 14px; }
      .my-design .body .top2 .classify .name {
        font-size: 14px;
        font-weight: 400;
        color: #222529;
        margin-right: 8px;
        line-height: 30px; }
      .my-design .body .top2 .classify .tag {
        color: #505760;
        padding: 6px 16px;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s; }
      .my-design .body .top2 .classify .tag:hover {
        background: #F5F7FA; }
      .my-design .body .top2 .classify .active-tag {
        background: rgba(38, 77, 240, 0.09) !important;
        color: #264DF0; }
      .my-design .body .top2 .classify .classify2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
        height: 30px;
        overflow: hidden;
        gap: 16px;
        font-size: 14px; }
      .my-design .body .top2 .classify .more {
        line-height: 30px;
        color: #264DF0; }
      .my-design .body .top2 .classify .editor {
        width: 24px;
        height: 24px;
        background: rgba(38, 77, 240, 0.09);
        color: #264DF0;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
  .my-design .body .top3 {
    margin-top: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 56px; }
    .my-design .body .top3 .line {
      width: 1px;
      height: 20px;
      background: #E2E2E2; }
    .my-design .body .top3 .shareStatus {
      color: #505760;
      display: flex;
      align-items: center;
      gap: 32px;
      font-size: 14px; }
      .my-design .body .top3 .shareStatus span {
        transition: all 0.1s; }
      .my-design .body .top3 .shareStatus .active-tag {
        font-size: 20px;
        font-weight: 500;
        color: #264DF0 !important; }
      .my-design .body .top3 .shareStatus span:hover {
        color: #222529;
        font-weight: bold; }
    .my-design .body .top3 .upgradeStatus {
      font-size: 14px; }
      .my-design .body .top3 .upgradeStatus span {
        color: #505760;
        padding: 6px 16px;
        border-radius: 2px;
        cursor: pointer;
        margin-left: 16px;
        transition: all 0.3s; }
      .my-design .body .top3 .upgradeStatus span:hover {
        background: #F5F7FA; }
      .my-design .body .top3 .upgradeStatus .active-tag {
        background: rgba(38, 77, 240, 0.09) !important;
        color: #264DF0; }
  .my-design .body .content {
    margin-top: 34px; }
    .my-design .body .content .template-list {
      margin-top: 34px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 20px; }
      .my-design .body .content .template-list .item {
        width: calc((100% - 80px) / 5);
        position: relative; }
        .my-design .body .content .template-list .item:nth-child(5n) .item-top .mask-operate .template-menus {
          left: auto;
          right: 0; }
        .my-design .body .content .template-list .item .flag {
          position: absolute;
          left: 0;
          top: 0;
          width: 74px;
          height: 32px;
          background: #FFFFFF;
          box-shadow: 0px 1px 8px 0px rgba(34, 37, 41, 0.12);
          border-radius: 8px 0px 16px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1; }
          .my-design .body .content .template-list .item .flag .iconfont {
            margin-left: 2px; }
        .my-design .body .content .template-list .item .selected0 {
          width: 32px;
          height: 32px;
          background: #FFFFFF;
          box-shadow: 0px 2px 4px 0px rgba(34, 37, 41, 0.13);
          border-radius: 4px;
          border: 1px solid #E1E5EB;
          left: 16px;
          top: 16px;
          position: absolute;
          z-index: 1; }
        .my-design .body .content .template-list .item .selected1 {
          width: 32px;
          height: 32px;
          background: #264DF0;
          box-shadow: 0px 2px 4px 0px rgba(34, 37, 41, 0.13);
          border-radius: 4px;
          left: 16px;
          top: 16px;
          position: absolute;
          z-index: 1;
          color: #FFF;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .my-design .body .content .template-list .item .item-top {
          width: 100%;
          height: 300px;
          background: #F5F7FA;
          border-radius: 8px;
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative; }
          .my-design .body .content .template-list .item .item-top img {
            max-width: 100%;
            max-height: 100%; }
          .my-design .body .content .template-list .item .item-top .upgrade {
            width: 66px;
            height: 26px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 13px;
            position: absolute;
            right: 16px;
            bottom: 16px;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center; }
          .my-design .body .content .template-list .item .item-top .maskbg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 8px;
            display: none;
            transition: all 1s;
            z-index: 2; }
          .my-design .body .content .template-list .item .item-top .mask-operate {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            display: none;
            transition: all 1s;
            z-index: 2; }
            .my-design .body .content .template-list .item .item-top .mask-operate .iconfont {
              width: 32px;
              height: 32px;
              background: rgba(255, 255, 255, 0.95);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              color: #222529;
              cursor: pointer; }
            .my-design .body .content .template-list .item .item-top .mask-operate .icon-gongkai {
              position: absolute;
              left: 16px;
              top: 16px; }
            .my-design .body .content .template-list .item .item-top .mask-operate .icon-biaoqian {
              position: absolute;
              right: 56px;
              top: 16px; }
            .my-design .body .content .template-list .item .item-top .mask-operate .icon-gengduo1 {
              position: absolute;
              right: 16px;
              top: 16px;
              z-index: 99; }
            .my-design .body .content .template-list .item .item-top .mask-operate .btn1 {
              height: 40px;
              background: white;
              border-radius: 4px;
              color: #264DF0;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 16px;
              bottom: 16px;
              right: 16px; }
            .my-design .body .content .template-list .item .item-top .mask-operate .template-more:hover > .template-menus {
              display: block; }
            .my-design .body .content .template-list .item .item-top .mask-operate .template-menus {
              position: absolute;
              left: 0;
              top: 80%;
              display: none;
              padding: 12px 0;
              width: 176px; }
            .my-design .body .content .template-list .item .item-top .mask-operate .menus {
              background: #fff;
              padding: 16px 0;
              font-size: 16px;
              border-radius: 8px;
              color: #333;
              box-shadow: 0px 4px 10px 0px rgba(34, 37, 41, 0.2); }
              .my-design .body .content .template-list .item .item-top .mask-operate .menus > div {
                width: 100%;
                padding: 8px 16px; }
              .my-design .body .content .template-list .item .item-top .mask-operate .menus > div:hover {
                background: #F5F7FA; }
              .my-design .body .content .template-list .item .item-top .mask-operate .menus .line {
                padding: 0;
                height: 1px;
                width: 100%;
                background: #E2E2E2; }
        .my-design .body .content .template-list .item .maskShow:hover > .maskbg {
          display: block; }
        .my-design .body .content .template-list .item .maskShow:hover > .mask-operate {
          display: block; }
        .my-design .body .content .template-list .item .item-bottom {
          width: 100%;
          height: 112px;
          padding: 8px 0; }
          .my-design .body .content .template-list .item .item-bottom > div {
            margin-top: 8px; }
          .my-design .body .content .template-list .item .item-bottom .name {
            font-size: 16px;
            font-weight: 500;
            color: #222529;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis; }
          .my-design .body .content .template-list .item .item-bottom .property {
            display: flex; }
            .my-design .body .content .template-list .item .item-bottom .property .productType {
              color: #264DF0;
              padding-left: 0; }
            .my-design .body .content .template-list .item .item-bottom .property span {
              padding: 0 8px;
              border-right: 1px solid #D1D5DC;
              color: #89919D; }
            .my-design .body .content .template-list .item .item-bottom .property :last-child {
              border-right: none; }
          .my-design .body .content .template-list .item .item-bottom .tags {
            display: flex;
            gap: 8px;
            color: #505760; }
            .my-design .body .content .template-list .item .item-bottom .tags span {
              padding: 3px 8px;
              background: #F5F7FA;
              border-radius: 2px;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 30%; }
    .my-design .body .content .data-null {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 200px; }
      .my-design .body .content .data-null img {
        width: 336px; }
      .my-design .body .content .data-null .themecolor {
        color: #264DF0; }
      .my-design .body .content .data-null .iconfont {
        font-size: 12px;
        margin-left: 5px;
        color: #505760; }

.my-design .batchOperate {
  height: 72px;
  background: #FF4444;
  position: fixed;
  bottom: 0;
  left: 264px;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 32px;
  color: #FFF;
  font-size: 16px; }
  .my-design .batchOperate > * {
    cursor: pointer; }
  .my-design .batchOperate .iconfont {
    font-size: 24px; }
  .my-design .batchOperate .delete .iconfont {
    font-size: 20px;
    margin-right: 5px; }

.themecolor {
  color: #264DF0; }

.group-form {
  display: flex;
  align-items: center; }
  .group-form .group-form-input {
    border: 1px solid #E1E5EB;
    height: 40px;
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 4px;
    gap: 1px; }
    .group-form .group-form-input .line {
      width: 1px;
      height: 20px;
      background: #E1E5EB; }
    .group-form .group-form-input .group-form-item {
      position: relative; }
      .group-form .group-form-input .group-form-item input {
        width: 100%;
        height: 100%;
        padding-left: 26px;
        z-index: 99; }
      .group-form .group-form-input .group-form-item .group-form-item-title {
        position: absolute;
        left: 10px;
        color: #89919D;
        z-index: 1; }
  .group-form .group-form-input:has(input:focus), .group-form .group-form-select:has(select:focus) {
    border: 1px solid #264DF0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .group-form .group-form-input:has(input:hover), .group-form .group-form-select:has(select:hover) {
    border: 1px solid #264DF0; }
  .group-form .group-form-select {
    border: 1px solid #E1E5EB;
    height: 40px;
    width: 134px;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 8px;
    border-radius: 4px; }
    .group-form .group-form-select select {
      width: 100%;
      height: 100%;
      padding-left: 46px; }
    .group-form .group-form-select .group-form-item-title {
      position: absolute;
      left: 16px;
      color: #89919D; }
  .group-form input {
    border: none !important; }
  .group-form select {
    border: none !important; }
  .group-form input[type="text"]:focus, .group-form input[type="number"]:focus {
    box-shadow: none !important;
    border: none !important; }
  .group-form input[type="text"]:hover, .group-form input[type="password"]:hover, .group-form input[type="number"]:hover, .group-form select:hover, .group-form textarea:hover {
    box-shadow: none !important;
    border: none !important; }
  .group-form select:focus {
    box-shadow: none !important;
    border: none !important; }

.upload-form {
  width: 96px;
  height: 96px;
  border-radius: 1px;
  border: 1px solid #E1E5EB;
  position: relative; }
  .upload-form .upload-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #89919D; }
    .upload-form .upload-input .iconfont {
      margin-bottom: 10px;
      font-size: 20px; }
  .upload-form .upload-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .upload-form .upload-img img {
      max-width: 100%;
      max-height: 100%; }
    .upload-form .upload-img .iconfont {
      position: absolute;
      right: 8px;
      top: 8px;
      color: red;
      z-index: 99;
      font-weight: bold; }

.model-item-3d {
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid #E2E2E2;
  display: flex;
  align-items: center; }
  .model-item-3d .item-img {
    width: 64px;
    height: 64px;
    background: #EEF0F3;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .model-item-3d .item-img img {
      max-width: 64px;
      max-height: 64px; }
  .model-item-3d .item-name {
    flex: 1;
    margin-left: 15px; }

.model-item-3d:hover {
  background-color: #ebeffe; }

.c-model-head-title-input {
  width: 240px;
  height: 36px;
  position: relative; }
  .c-model-head-title-input input {
    width: 100%;
    height: 100%;
    border: 1px solid #E1E5EB;
    border-radius: 2px;
    padding-left: 40px; }
  .c-model-head-title-input .iconfont {
    position: absolute;
    left: 15px;
    top: 6px; }

.iconWarn {
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 14px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EA5E2A; }

.iconWarn1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; }

.iconWarn1 img {
  max-width: 60%; }

.c-editor-head-btn2 {
  min-width: 64px;
  height: 40px;
  padding: 0 16px;
  background: #264DF0;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin-right: 8px; }

.c-editor-head-btn1 {
  min-width: 64px;
  height: 40px;
  padding: 0 16px;
  background: #222529;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff; }

.c-editor-head-btn3 {
  min-width: 64px;
  height: 40px;
  padding: 0 16px;
  background: rgba(38, 77, 240, 0.09);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #264DF0;
  margin-right: 8px; }

.c-editor-head-btn4 {
  min-width: 64px;
  height: 40px;
  padding: 0 16px;
  background: #F5F7FA;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #505760;
  margin-right: 8px; }

.c-editor .c-editor-head {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  border-bottom: 1px solid #E2E2E2; }
  .c-editor .c-editor-head .c-editor-head-fl {
    height: 71px;
    display: flex;
    align-items: center;
    color: #505760; }
    .c-editor .c-editor-head .c-editor-head-fl .page-head-file {
      position: relative;
      margin-right: 32px; }
      .c-editor .c-editor-head .c-editor-head-fl .page-head-file .tooltip {
        min-width: 65px !important; }
      .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-text {
        height: 40px;
        width: 40px;
        background: #F5F7FA;
        border-radius: 4px;
        cursor: pointer;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-text .iconfont {
          margin: 0 !important; }
      .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size {
        position: absolute;
        z-index: 999;
        background: #fff;
        color: #232323;
        width: 280px;
        left: 0;
        top: 40px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 20px 16px;
        display: none; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title {
          font-size: 18px;
          font-weight: bold;
          color: #222529;
          line-height: 25px;
          margin-bottom: 10px; }
          .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title .page-file-size-title-view {
            border-radius: 4px;
            width: 100%;
            padding: 8px;
            border: none;
            font-size: 18px; }
            .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title .page-file-size-title-view:hover {
              background: #F0F1F6; }
          .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title .page-file-size-title-textarea {
            border-radius: 4px;
            width: 248px;
            padding: 8px;
            max-height: 60px;
            border: none;
            font-size: 18px; }
            .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title .page-file-size-title-textarea:hover {
              background: #F0F1F6; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title1 {
          font-weight: bold;
          font-size: 14px;
          display: flex; }
          .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title1 .page-file-size-title1-type {
            margin-left: 8px;
            display: inline-block;
            height: 22px;
            padding: 0 8px;
            background: #264DF0;
            border-radius: 1px;
            color: #fff;
            font-size: 12px;
            line-height: 22px;
            font-weight: 400; }
          .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-title1 .page-file-size-title1-type1 {
            max-width: 140px;
            margin-left: 8px;
            display: inline-block;
            height: 22px;
            padding: 0 8px;
            background: rgba(38, 77, 240, 0.09);
            border-radius: 1px;
            color: #264DF0;
            font-size: 12px;
            line-height: 22px;
            font-weight: 400;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-hr {
          width: 100%;
          height: 1px;
          background: #E2E2E2;
          margin: 20px 0; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-1 {
          display: flex;
          height: 20px;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 17px; }
          .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-1 .page-file-size-1-btn {
            display: flex;
            color: #264DF0;
            font-size: 12px; }
            .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-1 .page-file-size-1-btn .iconfont {
              font-size: 12px;
              margin-right: 5px; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-2 {
          color: #89919D;
          line-height: 20px; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-3 {
          background: #fff;
          padding: 0 8px;
          color: #222529;
          font-weight: bold;
          height: 40px;
          line-height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 4px; }
          .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-3 .iconfont {
            margin: 0;
            font-size: 14px; }
        .c-editor .c-editor-head .c-editor-head-fl .page-head-file .page-file-size .page-file-size-3:hover {
          background: #F0F1F6; }
    .c-editor .c-editor-head .c-editor-head-fl .page-head-file:hover .page-file-size {
      display: block; }
    .c-editor .c-editor-head .c-editor-head-fl .c-editor-head-name-view {
      width: 288px;
      height: 35px;
      margin-right: 96px; }
    .c-editor .c-editor-head .c-editor-head-fl .c-editor-head-name {
      border: 0 !important;
      box-shadow: none !important;
      width: 100%;
      height: 35px;
      padding: 0 8px;
      border-radius: 4px; }
    .c-editor .c-editor-head .c-editor-head-fl .c-editor-head-name1 {
      width: 100%;
      height: 35px;
      line-height: 35px;
      transition: all .1s;
      padding: 0 8px;
      border-radius: 4px;
      font-size: 14px; }
    .c-editor .c-editor-head .c-editor-head-fl .c-editor-head-name1:hover {
      background: #F0F1F6; }
    .c-editor .c-editor-head .c-editor-head-fl .iconfont {
      font-size: 24px;
      margin-right: 24px;
      cursor: pointer; }
  .c-editor .c-editor-head .c-editor-head-fr {
    height: 72px;
    display: flex;
    align-items: center; }
    .c-editor .c-editor-head .c-editor-head-fr .iconfont {
      margin-right: 3px;
      height: 19px; }

.c-editor .c-editor-nav {
  position: fixed;
  left: 0;
  top: 72px;
  bottom: 0;
  width: 72px;
  padding: 6px;
  z-index: 999;
  border-right: 1px solid #E2E2E2;
  background: #fff;
  overflow: auto; }
  .c-editor .c-editor-nav .c-editor-nav-li {
    width: 60px;
    height: 66px;
    transition: all .1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #505760;
    cursor: pointer; }
    .c-editor .c-editor-nav .c-editor-nav-li .iconfont {
      margin-bottom: 4px;
      font-size: 18px;
      height: 18px;
      line-height: 18px; }
    .c-editor .c-editor-nav .c-editor-nav-li span {
      font-size: 14px; }
  .c-editor .c-editor-nav .c-editor-nav-li:hover, .c-editor .c-editor-nav .c-editor-nav-navOn {
    color: #264DF0;
    background: rgba(38, 77, 240, 0.09); }

.c-editor .c-editor-nav-view {
  position: fixed;
  left: 72px;
  top: 72px;
  bottom: 0;
  width: 320px;
  z-index: 999;
  border-right: 1px solid #E2E2E2;
  background: #fff;
  overflow: auto; }
  .c-editor .c-editor-nav-view .c-editor-nav-view-li {
    width: 100%;
    padding: 16px; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-title {
      font-size: 16px;
      color: #222529;
      line-height: 22px;
      height: 22px;
      font-weight: bold; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-title1 {
      font-size: 14px;
      color: #222529;
      line-height: 20px;
      height: 20px;
      display: flex;
      justify-content: space-between; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-1 {
      position: relative;
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-1 .c-editor-nav-view-li-1-choose-color {
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        opacity: 0; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-1 .c-editor-nav-view-li-1-color {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        border-radius: 2px;
        border: 1px solid #E2E2E2;
        margin: 0 11px 11px 0; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-1 .c-editor-nav-view-li-1-color:last-child {
        margin: 0; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-1 .c-editor-nav-view-li-1-color1 {
        background: url("../images/editor-icon1.png") no-repeat;
        background-size: 100% 100%; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-btn1 {
      position: relative;
      width: 100%;
      height: 40px;
      background: #F5F7FA;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #264DF0;
      font-size: 14px;
      font-weight: bold;
      margin-top: 10px;
      cursor: pointer; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-btn1 .c-editor-nav-view-li-btn1-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-hr {
      width: 100%;
      height: 1px;
      background: #E2E2E2;
      margin: 24px 0; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-2 {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-bottom: 16px; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-2 div {
        position: relative;
        height: 30px;
        width: 50%;
        text-align: center;
        cursor: pointer;
        color: #505760;
        transition: all .1s; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-2 div .div-hr {
          position: absolute;
          left: 50%;
          bottom: 0;
          height: 3px;
          width: 16px;
          margin-left: -8px;
          background: #264DF0;
          border-radius: 2px;
          display: none; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-2 div:hover {
        color: #264DF0; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-2 div.c-editor-nav-view-li-2-active {
        color: #264DF0; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-2 div.c-editor-nav-view-li-2-active .div-hr {
          display: block; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li {
        position: relative;
        width: 136px;
        height: 136px;
        background: #F7F8FA;
        border: 1px solid #F7F8FA;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li .c-editor-nav-view-li-3-li-img {
          max-width: 90%;
          max-height: 90%; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li .c-editor-nav-view-li-3-li-recommend {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          height: 30px;
          width: 30px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 0px 0px 6px 0px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #fff; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li .c-editor-nav-view-li-3-li-del {
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          height: 30px;
          width: 30px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 0px 0px 0 6px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #fff; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li:hover {
        border: 1px solid #264DF0; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li:hover .c-editor-nav-view-li-3-li-recommend, .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li:hover .c-editor-nav-view-li-3-li-del {
          display: flex; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-view-li-3 .c-editor-nav-view-li-3-li-more {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        cursor: pointer;
        margin-top: 20px; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-rahmen-view {
      display: flex;
      justify-content: space-between;
      margin-top: 24px; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-rahmen-view .c-rahmen-view-li {
        width: 136px;
        height: 136px;
        background: #F5F7FA;
        display: flex;
        justify-content: center;
        align-items: center; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-rahmen-view .c-rahmen-view-li img {
          max-width: 120px;
          max-height: 120px;
          filter: brightness(85%); }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify {
      margin-top: 24px; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify .c-editor-nav-classify-view {
        display: flex;
        flex-wrap: wrap;
        margin: -8px; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify .c-editor-nav-classify-view .c-editor-nav-classify-view-btn {
          padding: 0 8px;
          height: 36px;
          background: #F5F7FA;
          border-radius: 2px;
          color: #505760;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 8px;
          transition: all .1s; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify .c-editor-nav-classify-view .c-editor-nav-classify-view-btn:hover, .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify .c-editor-nav-classify-view .c-editor-nav-classify-view-btn-active {
          background: #264DF0;
          color: #fff; }
      .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify .c-editor-nav-classify-view-cursorPointer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 8px; }
        .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify .c-editor-nav-classify-view-cursorPointer .c-editor-nav-classify-view-cursorPointer-btn {
          width: 64px;
          height: 24px;
          background: #F5F7FA;
          border-radius: 12px;
          color: #89919D;
          font-size: 12px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center; }
          .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-classify .c-editor-nav-classify-view-cursorPointer .c-editor-nav-classify-view-cursorPointer-btn .iconfont {
            margin-left: 3px; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-textbox div {
      width: 100%;
      height: 48px;
      background: #F5F7FA;
      border-radius: 2px;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-textbox .text-size-24 {
      font-size: 24px;
      color: #505760; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-textbox .text-size-20 {
      font-size: 20px;
      color: #505760; }
    .c-editor .c-editor-nav-view .c-editor-nav-view-li .c-editor-nav-textbox .text-size-16 {
      font-size: 16px;
      color: #505760; }

.c-editor .c-editor-operation {
  position: fixed;
  left: 392px;
  top: 72px;
  right: 200px;
  height: 40px;
  z-index: 999;
  border-bottom: 1px solid #E2E2E2;
  background: #fff;
  display: flex;
  justify-content: space-between; }
  .c-editor .c-editor-operation .c-editor-operation-fl {
    display: flex;
    height: 40px;
    align-items: center; }
  .c-editor .c-editor-operation .c-editor-operation-fr {
    display: flex;
    height: 40px;
    align-items: center; }

.c-editor .c-editor-canvas {
  position: fixed;
  left: 392px;
  top: 112px;
  right: 200px;
  bottom: 120px;
  z-index: 1;
  background: #F5F7FA; }
  .c-editor .c-editor-canvas .c-editor-canvas-view {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .c-editor .c-editor-canvas .page-scale {
    position: absolute;
    bottom: 10px;
    width: 324px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 28px;
    right: 20px;
    z-index: 900;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px; }
  .c-editor .c-editor-canvas .c-editor-canvas-page-turning {
    position: absolute;
    bottom: 10px;
    width: 161px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 28px;
    right: 355px;
    z-index: 900;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px; }
    .c-editor .c-editor-canvas .c-editor-canvas-page-turning .iconfont {
      font-size: 14px;
      cursor: pointer; }
    .c-editor .c-editor-canvas .c-editor-canvas-page-turning span {
      font-size: 12px;
      color: #222529; }
  .c-editor .c-editor-canvas .parts-view {
    position: absolute;
    height: 36px;
    left: 20px;
    bottom: 10px;
    z-index: 998; }
    .c-editor .c-editor-canvas .parts-view .parts-view-1 {
      padding: 0 30px;
      border-radius: 18px;
      background: #fff;
      display: flex;
      align-items: center;
      max-width: 600px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      overflow-x: auto; }
      .c-editor .c-editor-canvas .parts-view .parts-view-1 .parts-li {
        padding: 0 6px;
        margin: 0 9px;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 13px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        text-align: center; }
      .c-editor .c-editor-canvas .parts-view .parts-view-1 .parts-li:hover {
        color: #505760;
        background: #F0F1F6; }
    .c-editor .c-editor-canvas .parts-view .parts-li1 {
      position: absolute;
      right: -45px;
      top: 2px;
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px; }
    .c-editor .c-editor-canvas .parts-view .parts-li-select, .c-editor .c-editor-canvas .parts-view .parts-li1:hover {
      color: #264DF0 !important;
      background: #fff !important; }

.c-editor .c-editor-canvas-pagelist {
  position: fixed;
  left: 392px;
  bottom: 0;
  right: 200px;
  height: 120px;
  background: #fff;
  z-index: 999;
  overflow: auto; }
  .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul {
    display: flex;
    height: 100%;
    align-items: center;
    padding-right: 60px;
    width: fit-content; }
    .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li {
      position: relative;
      margin: 0 10px;
      height: 100px; }
      .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li.cell-line {
        width: 1px;
        background: #DDDDDD; }
      .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li .add-box {
        display: none; }
      .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li.add-page-menu {
        display: flex;
        align-items: center;
        cursor: pointer; }
        .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li.add-page-menu .add-box {
          width: 48px;
          height: 80px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-left: 20px; }
          .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li.add-page-menu .add-box p {
            margin: 0; }
      .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li .page-del {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0px 0px 0px 6px;
        cursor: pointer;
        color: #fff;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 999; }
      .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li:hover .page-del {
        display: flex; }
      .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-ul li .page-seq {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #89919D;
        width: 30px;
        height: 30px;
        background: #fff;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 0px 16px 0px 0px; }
  .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-page {
    position: fixed;
    right: 210px;
    bottom: 25px;
    z-index: 999; }
    .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-page .page-noNav {
      width: 48px;
      height: 80px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff; }
      .c-editor .c-editor-canvas-pagelist .c-editor-canvas-pagelist-page .page-noNav p {
        margin: 0; }

.c-editor .c-editor-content {
  position: fixed;
  top: 72px;
  left: 72px;
  right: 200px;
  bottom: 0;
  background: #F5F7FA; }

.c-editor .c-editor-attribute {
  position: fixed;
  top: 72px;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1;
  overflow: auto;
  width: 288px;
  border-left: 1px solid #E2E2E2; }
  .c-editor .c-editor-attribute .c-editor-attribute-head {
    display: flex;
    justify-content: space-between; }
    .c-editor .c-editor-attribute .c-editor-attribute-head div {
      height: 40px;
      width: 98px;
      background: #F5F7FA;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #505760;
      transition: all .1s; }
    .c-editor .c-editor-attribute .c-editor-attribute-head div:hover, .c-editor .c-editor-attribute .c-editor-attribute-head .c-editor-attribute-head-selected {
      background: #fff;
      color: #222529; }
  .c-editor .c-editor-attribute .c-editor-attribute-body {
    padding: 16px; }
  .c-editor .c-editor-attribute .rightPanel-head {
    height: 40px;
    border-bottom: 1px solid #E2E2E2;
    padding: 0 16px;
    display: flex;
    align-items: center; }
    .c-editor .c-editor-attribute .rightPanel-head div:first-child {
      color: #505760;
      margin-right: 8px; }
    .c-editor .c-editor-attribute .rightPanel-head div:last-child {
      font-size: 12px;
      color: #89919D; }
  .c-editor .c-editor-attribute .c-editor-attribute-view {
    padding: 16px; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-hr {
      width: 100%;
      height: 1px;
      background: #E2E2E2;
      margin: 16px 0; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .imageartOnlyshow {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      color: #666666;
      font-size: 12px; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .imageartOnlyshow .iconfont {
        margin-right: 2px; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-1 {
      width: 100%;
      height: 36px;
      border: 1px solid #E1E5EB;
      border-radius: 2px;
      background: #fff;
      overflow: hidden;
      display: flex; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-1 div {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #222529;
        cursor: pointer;
        transition: all .1s; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-1 div:hover {
        color: #264DF0; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-1 div.c-editor-attribute-view-1-selected, .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-1 div.c-editor-attribute-view-1-selected:hover {
        background: #264DF0;
        color: #fff; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 {
      width: 100%;
      height: 36px;
      border: 1px solid #E1E5EB;
      border-radius: 2px;
      background: #fff;
      display: flex; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view {
        position: relative;
        width: 50%;
        height: 100%;
        color: #222529;
        transition: all .1s; }
        .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view .c-editor-attribute-view-2-title {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view .c-editor-attribute-view-2-title:hover {
          color: #264DF0; }
        .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view .c-editor-attribute-view-2-title.c-editor-attribute-view-1-selected, .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view .c-editor-attribute-view-2-title.c-editor-attribute-view-1-selected:hover {
          background: #264DF0;
          color: #fff; }
        .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view .c-editor-attribute-view-2-ul {
          position: absolute;
          top: 34px;
          width: 120px;
          background: #fff;
          border: 1px solid #E1E5EB;
          display: none;
          box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.2);
          padding: 8px 0; }
          .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view .c-editor-attribute-view-2-ul .c-editor-attribute-view-2-ul-li {
            width: 100%;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 12px; }
          .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view .c-editor-attribute-view-2-ul .c-editor-attribute-view-2-ul-li:hover {
            color: #264DF0; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-2 .c-editor-attribute-view-2-view:hover .c-editor-attribute-view-2-ul {
        display: block; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn {
      width: 100%;
      height: 36px;
      transition: all .1s;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #222529;
      background: #F5F7FA;
      cursor: pointer;
      border-radius: 18px;
      margin-bottom: 16px; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn1 {
      background: rgba(38, 77, 240, 0.09);
      color: #264DF0; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn2 {
      background: #264DF0;
      color: #fff; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn3 {
      width: 100%;
      position: relative; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn3 .c-editor-attribute-view-btn3-text {
        width: 100%;
        height: 36px;
        transition: all .1s;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #222529;
        background: #F5F7FA;
        cursor: pointer;
        border-radius: 18px;
        margin-bottom: 16px; }
      .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn3 .c-editor-attribute-view-btn3-view {
        position: absolute;
        top: 36px;
        left: 0;
        width: 100%;
        padding: 10px;
        display: none; }
        .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn3 .c-editor-attribute-view-btn3-view .c-editor-attribute-view-btn3-view1 {
          background: #fff;
          width: 100%;
          box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding: 4px 0; }
          .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn3 .c-editor-attribute-view-btn3-view .c-editor-attribute-view-btn3-view1 .c-editor-attribute-view-btn3-btn {
            width: 100%;
            height: 35px;
            text-align: center;
            line-height: 35px;
            color: #0a0a0a;
            cursor: pointer; }
          .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn3 .c-editor-attribute-view-btn3-view .c-editor-attribute-view-btn3-view1 .c-editor-attribute-view-btn3-btn:hover {
            color: #264DF0; }
    .c-editor .c-editor-attribute .c-editor-attribute-view .c-editor-attribute-view-btn3:hover .c-editor-attribute-view-btn3-view {
      display: block; }
  .c-editor .c-editor-attribute .editor-page-element-body {
    width: 100%;
    padding: 16px; }
    .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view {
      position: relative;
      width: 100%;
      margin-bottom: 20px; }
      .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view .editor-page-element-body-view-img {
        width: 100%;
        height: 95px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F7FA; }
        .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view .editor-page-element-body-view-img img {
          max-width: 100%;
          max-height: 100%; }
        .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view .editor-page-element-body-view-img .iconfont {
          font-size: 28px; }
      .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view .selected {
        outline: 1px solid #264DF0 !important; }
    .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view-1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      color: #505760; }
      .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view-1 .editor-page-element-body-view-sh {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
      .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view-1 .editor-page-element-body-view-text {
        position: relative;
        width: 116px;
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all; }
      .c-editor .c-editor-attribute .editor-page-element-body .editor-page-element-body-view-1 .editor-page-element-body-view-delete {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
  .c-editor .c-editor-attribute .editor-page-element-foot {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #264DF0;
    cursor: pointer;
    position: fixed;
    right: 0;
    bottom: 0;
    font-size: 16px;
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.1);
    background: #fff; }

.c-editor .c-editor-toggle-left {
  position: fixed;
  top: 50%;
  left: 392px;
  width: 20px;
  height: 112px;
  background: #FFFFFF;
  margin-top: -56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 20px 20px 0;
  z-index: 9;
  cursor: pointer; }

.c-editor .c-editor-toggle-right {
  position: fixed;
  top: 50%;
  right: 200px;
  width: 20px;
  height: 112px;
  background: #FFFFFF;
  margin-top: -56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0 0 20px;
  z-index: 9;
  cursor: pointer; }

.c-editor-attribute-view-title {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .c-editor-attribute-view-title span {
    font-weight: bold; }
  .c-editor-attribute-view-title .c-editor-attribute-view-title-select {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 0 !important;
    box-shadow: none !important;
    color: #505760; }
  .c-editor-attribute-view-title .c-editor-attribute-view-title-select:hover, .c-editor-attribute-view-title .c-editor-attribute-view-title-select:focus {
    border: 0 !important;
    box-shadow: none !important; }

.c-editor-attribute-view-input {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  background: #F5F7FA;
  margin-top: 10px; }
  .c-editor-attribute-view-input .c-editor-attribute-view-input-view {
    width: 50%;
    height: 20px;
    display: flex;
    align-items: center;
    color: #89919D;
    font-size: 12px;
    padding: 0 0 0 8px;
    border-right: 1px solid #ddd; }
    .c-editor-attribute-view-input .c-editor-attribute-view-input-view span {
      display: inline-block;
      width: 20px; }
    .c-editor-attribute-view-input .c-editor-attribute-view-input-view input {
      width: 100%;
      height: 20px;
      line-height: 20px;
      border: 0 !important;
      box-shadow: none !important;
      padding: 0 !important;
      color: #505760;
      font-size: 12px;
      background: #F5F7FA; }
    .c-editor-attribute-view-input .c-editor-attribute-view-input-view input:hover, .c-editor-attribute-view-input .c-editor-attribute-view-input-view input:focus {
      border: 0 !important;
      box-shadow: none !important; }
  .c-editor-attribute-view-input .c-editor-attribute-view-input-view:last-child {
    border: 0; }

.custom-grid {
  background-color: #fff;
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee), linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee);
  background-size: 16px 16px;
  background-position: 0 0, 8px 8px;
  width: 100%;
  height: 100%; }

.custom-grid2 {
  background-color: #fff;
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee), linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee);
  background-size: 16px 16px;
  background-position: 0 0, 8px 8px; }

.c-select1 {
  width: 100%;
  height: 36px;
  border-radius: 2px;
  border: 1px solid #E1E5EB;
  padding: 0 8px;
  font-size: 12px;
  color: #222529; }

.filleted-corner-view {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 4px; }
  .filleted-corner-view input {
    border: 0 !important;
    box-shadow: none !important;
    width: 25%;
    padding: 0 10px;
    border-right: 1px solid #ddd !important;
    height: 20px;
    border-radius: 0;
    font-size: 12px; }
  .filleted-corner-view input:hover, .filleted-corner-view input:focus {
    border: 0 !important;
    border-right: 1px solid #ddd !important; }
  .filleted-corner-view input:last-child {
    border: 0 !important; }

.c-editor-form-view {
  background: #F5F7FA;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 16px; }

.c-editor-form-view1 {
  position: relative;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E1E5EB;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px; }
  .c-editor-form-view1 .c-editor-form-view1-1 {
    height: 20px;
    width: 100%;
    position: relative;
    border-right: 1px solid #ddd; }
    .c-editor-form-view1 .c-editor-form-view1-1 span {
      display: block;
      width: 30px;
      text-align: right;
      color: #89919D;
      line-height: 20px; }
    .c-editor-form-view1 .c-editor-form-view1-1 input, .c-editor-form-view1 .c-editor-form-view1-1 select {
      position: absolute;
      width: 100%;
      height: 20px;
      line-height: 20px;
      border: 0 !important;
      box-shadow: none !important;
      padding: 0 8px 0 38px !important;
      font-size: 12px;
      color: #222529;
      background: none;
      left: 0;
      top: 0; }
    .c-editor-form-view1 .c-editor-form-view1-1 input:hover, .c-editor-form-view1 .c-editor-form-view1-1 input:focus, .c-editor-form-view1 .c-editor-form-view1-1 select:hover, .c-editor-form-view1 .c-editor-form-view1-1 select:focus {
      border: 0 !important;
      box-shadow: none !important; }
  .c-editor-form-view1 .c-editor-form-view1-1:last-child {
    border: 0; }
  .c-editor-form-view1 .c-editor-form-view1-1-border {
    border-right: 1px solid #E2E2E2; }
  .c-editor-form-view1 .timesSign {
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -12px;
    height: 24px;
    cursor: pointer;
    width: 24px;
    background: #F5F7FA;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px; }

.pageEditAdd {
  position: absolute;
  right: -30px;
  padding: 0;
  font-size: 25px;
  color: #264DF0; }

.cut-image-box {
  width: 100%;
  height: 100%;
  position: relative; }

.pos-abso {
  position: absolute; }

.c-editor-data-completed {
  color: #89919D;
  text-align: center;
  margin-top: 12px;
  width: 100%; }

.c-add-part-view {
  position: relative;
  width: 100%; }
  .c-add-part-view .c-add-part-view-name {
    width: 100%;
    height: 40px;
    border: 1px solid #E1E5EB;
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid #E1E5EB;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .c-add-part-view .c-add-part-view-list {
    width: 100%;
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    background: #fff;
    box-shadow: 0px 1px 8px 0px rgba(34, 37, 41, 0.12);
    border-radius: 4px;
    padding: 8px 0;
    z-index: 1; }
    .c-add-part-view .c-add-part-view-list .c-add-part-view-list-li {
      width: 100%;
      height: 36px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: #fff;
      color: #505760; }
    .c-add-part-view .c-add-part-view-list .c-add-part-view-list-li:hover, .c-add-part-view .c-add-part-view-list .c-add-part-view-list-li-selected {
      background: #F5F7FA; }
    .c-add-part-view .c-add-part-view-list .c-add-part-view-list-li:last-child {
      border-bottom: 1px solid #ddd; }
    .c-add-part-view .c-add-part-view-list .c-add-part-view-list-li-btn {
      width: 100%;
      height: 36px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: #fff;
      color: #264DF0; }

.c-add-part-view:hover .c-add-part-view-list {
  display: block; }

.c-add-part-view1-model {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }

.c-add-part-view1 {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 512px;
  background: #FFFFFF;
  box-shadow: 0px 1px 8px 0px rgba(34, 37, 41, 0.12);
  border-radius: 4px;
  padding: 16px 24px 32px;
  margin-top: -140px;
  margin-left: -206px;
  z-index: 100; }
  .c-add-part-view1 .c-add-part-view1-title {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #222529;
    line-height: 25px; }
  .c-add-part-view1 .c-add-part-view1-body {
    width: 100%;
    border-bottom: 1px solid #E2E2E2;
    height: 40px;
    margin-top: 69px; }
    .c-add-part-view1 .c-add-part-view1-body input, .c-add-part-view1 .c-add-part-view1-body select {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 0 !important;
      box-shadow: none !important;
      padding: 0 8px 0 8px !important;
      font-size: 14px;
      color: #222529;
      background: none;
      left: 0;
      top: 0; }
    .c-add-part-view1 .c-add-part-view1-body input:hover, .c-add-part-view1 .c-add-part-view1-body input:focus, .c-add-part-view1 .c-add-part-view1-body select:hover, .c-add-part-view1 .c-add-part-view1-body select:focus {
      border: 0 !important;
      box-shadow: none !important; }
  .c-add-part-view1 .c-add-part-view1-foot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px; }

.c-icon-btn {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: rgba(38, 77, 240, 0.09);
  color: #5271F3; }

.lineReference-x {
  position: absolute;
  width: 1px;
  height: 100vh;
  background: #d2a0d4;
  left: 0;
  top: 50%;
  margin-top: -50vh;
  z-index: 501; }

.lineReference-x1 {
  position: absolute;
  width: 5px;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 500;
  cursor: e-resize; }
  .lineReference-x1 .lineReference-x1-hr {
    width: 1px;
    height: 100%;
    background: #2dceed; }
  .lineReference-x1 .lineReference-item {
    position: absolute;
    top: 0;
    left: 0;
    background: #2dceed;
    color: #b4f1ff;
    height: 20px;
    display: none;
    align-items: center;
    padding: 0 1px; }

.lineReference-x1:hover .lineReference-item {
  display: flex; }

.lineReference-y {
  position: absolute;
  height: 1px;
  background: #d2a0d4;
  left: 0;
  top: 0;
  right: 0;
  z-index: 501; }

.lineReference-y1 {
  position: absolute;
  height: 5px;
  left: 0;
  top: 0;
  right: 0;
  z-index: 500;
  cursor: n-resize; }
  .lineReference-y1 .lineReference-y1-hr {
    height: 1px;
    width: 100%;
    background: #2dceed; }
  .lineReference-y1 .lineReference-item {
    display: none;
    position: absolute;
    left: 0;
    bottom: 5px;
    background: #2dceed;
    color: #b4f1ff;
    height: 20px;
    align-items: center;
    padding: 0 1px; }

.lineReference-y1:hover .lineReference-item {
  display: flex; }

.page-not-month {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  z-index: 1; }
  .page-not-month .iconfont {
    height: 18px;
    margin-right: 3px; }

.part-page-last {
  margin-right: 30px !important; }
  .part-page-last .page-border {
    position: absolute;
    right: -20px;
    top: 0;
    height: 100%;
    width: 1px;
    background: #ddd; }

.share-content {
  padding-bottom: 60px; }
  .share-content .share-head {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .share-content .share-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #222529; }
  .share-content .batch-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc( 100vw - 264px);
    padding: 0 32px;
    height: 72px;
    background: #264DF0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    color: #FFFFFF; }
    .share-content .batch-bar .btn-use {
      cursor: pointer;
      line-height: 72px;
      text-align: center; }
      .share-content .batch-bar .btn-use .iconfont {
        font-size: 20px;
        margin-right: 4px; }
    .share-content .batch-bar .btn-close {
      cursor: pointer; }
      .share-content .batch-bar .btn-close .iconfont {
        font-size: 18px;
        color: #FFFFFF; }
  .share-content .search-box {
    display: flex;
    align-items: center; }
    .share-content .search-box .search-input {
      position: relative; }
      .share-content .search-box .search-input .iconfont {
        position: absolute;
        top: 50%;
        left: 16px;
        font-size: 16px;
        color: #89919D;
        transform: translate(0, -50%); }
      .share-content .search-box .search-input input {
        height: 36px;
        border-radius: 2px;
        border: 1px solid #E1E5EB;
        color: #89919D;
        font-size: 12px;
        padding: 0 16px 0 40px; }
    .share-content .search-box .handle {
      margin-left: 8px; }
      .share-content .search-box .handle .batch-btn {
        line-height: 34px;
        width: 112px;
        font-size: 14px;
        text-align: center;
        border: 1px solid #E1E5EB;
        cursor: pointer; }
        .share-content .search-box .handle .batch-btn .iconfont {
          margin-right: 8px; }
      .share-content .search-box .handle .quit-btn {
        width: 112px;
        line-height: 36px;
        border-radius: 2px;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
        background: #264DF0; }
  .share-content .back-view {
    display: flex;
    margin-bottom: 28px; }
    .share-content .back-view .back-link {
      color: #505760;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer; }
      .share-content .back-view .back-link:hover {
        color: #264DF0; }
      .share-content .back-view .back-link .iconfont {
        font-size: 16px;
        margin-right: 2px; }

.share-screen .screen-box {
  padding: 20px 0;
  display: flex;
  border-bottom: 1px solid #E2E2E2; }

.share-screen .screen-tit {
  font-size: 14px;
  color: #222529;
  line-height: 32px;
  width: 50px; }

.share-screen .option-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  position: relative; }
  .share-screen .option-list.more {
    height: 32px;
    overflow: hidden;
    padding-right: 50px; }
  .share-screen .option-list.open {
    height: auto; }
    .share-screen .option-list.open .more-btn {
      color: #264DF0; }
  .share-screen .option-list .option-item {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #505760;
    padding: 0 16px;
    border-radius: 2px;
    margin-right: 16px;
    cursor: pointer; }
    .share-screen .option-list .option-item:hover {
      background: #F5F7FA; }
    .share-screen .option-list .option-item.active {
      background: rgba(38, 77, 240, 0.09);
      color: #264DF0;
      font-weight: bold; }
  .share-screen .option-list .more-btn {
    font-size: 14px;
    line-height: 32px;
    color: #89919D;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .share-screen .option-list .more-btn .iconfont {
      font-size: 12px;
      margin-left: 4px; }

.share-screen .screen-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0; }
  .share-screen .screen-line .screen {
    display: flex; }
  .share-screen .screen-line .genre {
    display: flex;
    position: relative;
    padding-right: 56px; }
    .share-screen .screen-line .genre:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 2px;
      height: 24px;
      background: #E2E2E2; }
    .share-screen .screen-line .genre .item {
      font-size: 16px;
      color: #505760;
      line-height: 32px;
      margin-right: 30px;
      cursor: pointer; }
      .share-screen .screen-line .genre .item.active {
        color: #264DF0;
        font-size: 20px;
        font-weight: bold; }
      .share-screen .screen-line .genre .item:last-child {
        margin-right: 0; }
  .share-screen .screen-line .price-select {
    display: flex;
    padding-left: 56px; }
    .share-screen .screen-line .price-select .tit {
      font-size: 16px;
      color: #222529;
      line-height: 32px;
      margin-right: 8px; }
  .share-screen .screen-line .skip-btn {
    display: block;
    padding: 0 23px;
    line-height: 36px;
    border-radius: 18px;
    font-size: 14px;
    color: #FFFFFF;
    background: #264DF0;
    text-decoration: none; }

.share-content .template-list, .share-content .text-list, .share-content .picture-list {
  display: flex;
  flex-wrap: wrap; }
  .share-content .template-list .empty-view, .share-content .text-list .empty-view, .share-content .picture-list .empty-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding: 50px 0; }
    .share-content .template-list .empty-view .empty-img, .share-content .text-list .empty-view .empty-img, .share-content .picture-list .empty-view .empty-img {
      width: 240px; }
    .share-content .template-list .empty-view .empty-text, .share-content .text-list .empty-view .empty-text, .share-content .picture-list .empty-view .empty-text {
      margin-top: 16px;
      color: #89919D;
      font-size: 16px; }
  .share-content .template-list .template-item, .share-content .template-list .text-item, .share-content .template-list .picture-item, .share-content .text-list .template-item, .share-content .text-list .text-item, .share-content .text-list .picture-item, .share-content .picture-list .template-item, .share-content .picture-list .text-item, .share-content .picture-list .picture-item {
    width: calc((100% - 80px) / 5);
    overflow: hidden;
    margin-bottom: 24px;
    margin-right: 20px; }
    .share-content .template-list .template-item:nth-child(5n), .share-content .template-list .text-item:nth-child(5n), .share-content .template-list .picture-item:nth-child(5n), .share-content .text-list .template-item:nth-child(5n), .share-content .text-list .text-item:nth-child(5n), .share-content .text-list .picture-item:nth-child(5n), .share-content .picture-list .template-item:nth-child(5n), .share-content .picture-list .text-item:nth-child(5n), .share-content .picture-list .picture-item:nth-child(5n) {
      margin-right: 0; }
  .share-content .template-list .img-view, .share-content .text-list .img-view, .share-content .picture-list .img-view {
    width: 100%;
    height: 300px;
    background: #F5F7FA;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden; }
    .share-content .template-list .img-view:hover .shade-view, .share-content .text-list .img-view:hover .shade-view, .share-content .picture-list .img-view:hover .shade-view {
      display: block; }
  .share-content .template-list .cover-view, .share-content .text-list .cover-view, .share-content .picture-list .cover-view {
    width: 100%;
    height: 100%;
    position: relative; }
    .share-content .template-list .cover-view .check-box, .share-content .text-list .cover-view .check-box, .share-content .picture-list .cover-view .check-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 5;
      border-radius: 4px;
      border: 1px solid #E1E5EB;
      background: #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(34, 37, 41, 0.13);
      color: #FFFFFF; }
      .share-content .template-list .cover-view .check-box .iconfont, .share-content .text-list .cover-view .check-box .iconfont, .share-content .picture-list .cover-view .check-box .iconfont {
        font-size: 18px; }
      .share-content .template-list .cover-view .check-box.checked, .share-content .text-list .cover-view .check-box.checked, .share-content .picture-list .cover-view .check-box.checked {
        background: #264DF0;
        border-color: #264DF0; }
    .share-content .template-list .cover-view .cover-img, .share-content .text-list .cover-view .cover-img, .share-content .picture-list .cover-view .cover-img {
      max-width: 90%;
      max-height: 90%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%); }
    .share-content .template-list .cover-view .buy-tag, .share-content .text-list .cover-view .buy-tag, .share-content .picture-list .cover-view .buy-tag {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 14px;
      color: #F8941E;
      line-height: 32px;
      padding: 0 16px;
      display: inline-block;
      background: #FFFFFF;
      border-radius: 0 0 16px 0;
      z-index: 5;
      box-shadow: 0 1px 8px 0 rgba(34, 37, 41, 0.12); }
    .share-content .template-list .cover-view .personal-tag, .share-content .text-list .cover-view .personal-tag, .share-content .picture-list .cover-view .personal-tag {
      position: absolute;
      line-height: 26px;
      padding: 0 12px;
      right: 8px;
      bottom: 8px;
      font-size: 14px;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 13px;
      z-index: 5; }
  .share-content .template-list .shade-view, .share-content .text-list .shade-view, .share-content .picture-list .shade-view {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10; }
    .share-content .template-list .shade-view .preview-btn, .share-content .text-list .shade-view .preview-btn, .share-content .picture-list .shade-view .preview-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      text-align: center;
      background: rgba(255, 255, 255, 0.95);
      border-radius: 4px;
      position: absolute;
      top: 16px;
      left: 16px; }
      .share-content .template-list .shade-view .preview-btn .iconfont, .share-content .text-list .shade-view .preview-btn .iconfont, .share-content .picture-list .shade-view .preview-btn .iconfont {
        font-size: 16px; }
    .share-content .template-list .shade-view .temp-btn, .share-content .text-list .shade-view .temp-btn, .share-content .picture-list .shade-view .temp-btn {
      width: calc(100% - 32px);
      line-height: 40px;
      background: rgba(255, 255, 255, 0.95);
      border-radius: 4px;
      color: #264DF0;
      font-size: 14px;
      text-align: center;
      position: absolute;
      left: 16px;
      bottom: 16px; }
  .share-content .template-list .template-info, .share-content .template-list .text-info, .share-content .template-list .picture-info, .share-content .text-list .template-info, .share-content .text-list .text-info, .share-content .text-list .picture-info, .share-content .picture-list .template-info, .share-content .picture-list .text-info, .share-content .picture-list .picture-info {
    padding: 16px 0; }
    .share-content .template-list .template-info .template-name, .share-content .template-list .template-info .text-name, .share-content .template-list .text-info .template-name, .share-content .template-list .text-info .text-name, .share-content .template-list .picture-info .template-name, .share-content .template-list .picture-info .text-name, .share-content .text-list .template-info .template-name, .share-content .text-list .template-info .text-name, .share-content .text-list .text-info .template-name, .share-content .text-list .text-info .text-name, .share-content .text-list .picture-info .template-name, .share-content .text-list .picture-info .text-name, .share-content .picture-list .template-info .template-name, .share-content .picture-list .template-info .text-name, .share-content .picture-list .text-info .template-name, .share-content .picture-list .text-info .text-name, .share-content .picture-list .picture-info .template-name, .share-content .picture-list .picture-info .text-name {
      font-size: 16px;
      color: #222529;
      line-height: 22px;
      font-weight: bold;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .share-content .template-list .template-info .info-list, .share-content .template-list .text-info .info-list, .share-content .template-list .picture-info .info-list, .share-content .text-list .template-info .info-list, .share-content .text-list .text-info .info-list, .share-content .text-list .picture-info .info-list, .share-content .picture-list .template-info .info-list, .share-content .picture-list .text-info .info-list, .share-content .picture-list .picture-info .info-list {
      display: flex;
      margin-bottom: 8px; }
      .share-content .template-list .template-info .info-list .item, .share-content .template-list .text-info .info-list .item, .share-content .template-list .picture-info .info-list .item, .share-content .text-list .template-info .info-list .item, .share-content .text-list .text-info .info-list .item, .share-content .text-list .picture-info .info-list .item, .share-content .picture-list .template-info .info-list .item, .share-content .picture-list .text-info .info-list .item, .share-content .picture-list .picture-info .info-list .item {
        font-size: 12px;
        color: #89919D;
        line-height: 18px;
        padding: 0 8px;
        position: relative; }
        .share-content .template-list .template-info .info-list .item:after, .share-content .template-list .text-info .info-list .item:after, .share-content .template-list .picture-info .info-list .item:after, .share-content .text-list .template-info .info-list .item:after, .share-content .text-list .text-info .info-list .item:after, .share-content .text-list .picture-info .info-list .item:after, .share-content .picture-list .template-info .info-list .item:after, .share-content .picture-list .text-info .info-list .item:after, .share-content .picture-list .picture-info .info-list .item:after {
          content: "";
          width: 1px;
          height: 12px;
          background: #D1D5DC;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%); }
        .share-content .template-list .template-info .info-list .item:first-child, .share-content .template-list .text-info .info-list .item:first-child, .share-content .template-list .picture-info .info-list .item:first-child, .share-content .text-list .template-info .info-list .item:first-child, .share-content .text-list .text-info .info-list .item:first-child, .share-content .text-list .picture-info .info-list .item:first-child, .share-content .picture-list .template-info .info-list .item:first-child, .share-content .picture-list .text-info .info-list .item:first-child, .share-content .picture-list .picture-info .info-list .item:first-child {
          padding-left: 0;
          color: #264DF0; }
        .share-content .template-list .template-info .info-list .item:last-child, .share-content .template-list .text-info .info-list .item:last-child, .share-content .template-list .picture-info .info-list .item:last-child, .share-content .text-list .template-info .info-list .item:last-child, .share-content .text-list .text-info .info-list .item:last-child, .share-content .text-list .picture-info .info-list .item:last-child, .share-content .picture-list .template-info .info-list .item:last-child, .share-content .picture-list .text-info .info-list .item:last-child, .share-content .picture-list .picture-info .info-list .item:last-child {
          padding-right: 0; }
          .share-content .template-list .template-info .info-list .item:last-child:after, .share-content .template-list .text-info .info-list .item:last-child:after, .share-content .template-list .picture-info .info-list .item:last-child:after, .share-content .text-list .template-info .info-list .item:last-child:after, .share-content .text-list .text-info .info-list .item:last-child:after, .share-content .text-list .picture-info .info-list .item:last-child:after, .share-content .picture-list .template-info .info-list .item:last-child:after, .share-content .picture-list .text-info .info-list .item:last-child:after, .share-content .picture-list .picture-info .info-list .item:last-child:after {
            display: none; }
    .share-content .template-list .template-info .tags-view, .share-content .template-list .text-info .tags-view, .share-content .template-list .picture-info .tags-view, .share-content .text-list .template-info .tags-view, .share-content .text-list .text-info .tags-view, .share-content .text-list .picture-info .tags-view, .share-content .picture-list .template-info .tags-view, .share-content .picture-list .text-info .tags-view, .share-content .picture-list .picture-info .tags-view {
      display: flex;
      height: 24px; }
      .share-content .template-list .template-info .tags-view.one .tag, .share-content .template-list .text-info .tags-view.one .tag, .share-content .template-list .picture-info .tags-view.one .tag, .share-content .text-list .template-info .tags-view.one .tag, .share-content .text-list .text-info .tags-view.one .tag, .share-content .text-list .picture-info .tags-view.one .tag, .share-content .picture-list .template-info .tags-view.one .tag, .share-content .picture-list .text-info .tags-view.one .tag, .share-content .picture-list .picture-info .tags-view.one .tag {
        max-width: 100%; }
      .share-content .template-list .template-info .tags-view.two .tag, .share-content .template-list .text-info .tags-view.two .tag, .share-content .template-list .picture-info .tags-view.two .tag, .share-content .text-list .template-info .tags-view.two .tag, .share-content .text-list .text-info .tags-view.two .tag, .share-content .text-list .picture-info .tags-view.two .tag, .share-content .picture-list .template-info .tags-view.two .tag, .share-content .picture-list .text-info .tags-view.two .tag, .share-content .picture-list .picture-info .tags-view.two .tag {
        max-width: calc((100% - 8px) / 2); }
      .share-content .template-list .template-info .tags-view.three .tag, .share-content .template-list .text-info .tags-view.three .tag, .share-content .template-list .picture-info .tags-view.three .tag, .share-content .text-list .template-info .tags-view.three .tag, .share-content .text-list .text-info .tags-view.three .tag, .share-content .text-list .picture-info .tags-view.three .tag, .share-content .picture-list .template-info .tags-view.three .tag, .share-content .picture-list .text-info .tags-view.three .tag, .share-content .picture-list .picture-info .tags-view.three .tag {
        max-width: calc((100% - 16px) / 3); }
      .share-content .template-list .template-info .tags-view .tag, .share-content .template-list .text-info .tags-view .tag, .share-content .template-list .picture-info .tags-view .tag, .share-content .text-list .template-info .tags-view .tag, .share-content .text-list .text-info .tags-view .tag, .share-content .text-list .picture-info .tags-view .tag, .share-content .picture-list .template-info .tags-view .tag, .share-content .picture-list .text-info .tags-view .tag, .share-content .picture-list .picture-info .tags-view .tag {
        line-height: 24px;
        padding: 0 8px;
        background: #F5F7FA;
        border-radius: 2px;
        color: #505760;
        font-size: 12px;
        margin-right: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
        .share-content .template-list .template-info .tags-view .tag:last-child, .share-content .template-list .text-info .tags-view .tag:last-child, .share-content .template-list .picture-info .tags-view .tag:last-child, .share-content .text-list .template-info .tags-view .tag:last-child, .share-content .text-list .text-info .tags-view .tag:last-child, .share-content .text-list .picture-info .tags-view .tag:last-child, .share-content .picture-list .template-info .tags-view .tag:last-child, .share-content .picture-list .text-info .tags-view .tag:last-child, .share-content .picture-list .picture-info .tags-view .tag:last-child {
          margin-right: 0; }

.pay-model-view {
  font-size: 14px;
  line-height: 20px;
  color: #505760; }
  .pay-model-view .pay-head {
    display: flex;
    align-items: center;
    line-height: 34px;
    margin-bottom: 16px; }
    .pay-model-view .pay-head .amount {
      color: #264DF0;
      font-size: 24px;
      font-weight: bold; }
  .pay-model-view .pay-type {
    display: flex; }
    .pay-model-view .pay-type .pay-title {
      line-height: 40px;
      margin-right: 8px; }
  .pay-model-view .pay-tabs {
    display: flex;
    border: 1px solid #264DF0;
    border-radius: 4px;
    margin-bottom: 24px; }
    .pay-model-view .pay-tabs .tab-item {
      width: 96px;
      line-height: 38px;
      text-align: center;
      font-size: 14px;
      color: #264DF0;
      font-weight: bold;
      cursor: pointer; }
      .pay-model-view .pay-tabs .tab-item.active {
        color: #FFFFFF;
        background: #264DF0; }
  .pay-model-view .pay-code-view {
    width: 176px;
    text-align: center;
    color: #505760;
    font-size: 14px; }
    .pay-model-view .pay-code-view .pay-code {
      width: 176px;
      height: 176px;
      box-shadow: 0 4px 12px 0 rgba(154, 160, 171, 0.3);
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .pay-model-view .pay-code-view .pay-code .code-img {
        width: 100%;
        height: 100%; }
      .pay-model-view .pay-code-view .pay-code .alipay-icon {
        width: 140px; }

.pay-model-foot .btn-close {
  line-height: 40px;
  width: 96px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  color: #505760;
  background: #FFFFFF;
  margin-right: 16px; }

.pay-model-foot .btn-pay {
  line-height: 40px;
  width: 96px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  background: #264DF0;
  color: #FFFFFF; }

.preview-model {
  background: #FFFFFF;
  padding: 0 24px 24px;
  border-radius: 8px; }
  .preview-model .preview-model-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px; }
    .preview-model .preview-model-head .title {
      font-size: 18px;
      color: #222529;
      font-weight: bold; }
    .preview-model .preview-model-head .close-btn {
      cursor: pointer; }
      .preview-model .preview-model-head .close-btn .iconfont {
        color: #89919D; }
  .preview-model .preview-model-content {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0; }
    .preview-model .preview-model-content img {
      max-width: 80%;
      max-height: 80%; }

.modele-pagination {
  display: flex;
  align-items: center;
  justify-content: center; }
  .modele-pagination.empty {
    display: none; }
  .modele-pagination .laypage_main {
    font-size: 12px;
    color: #525661; }
    .modele-pagination .laypage_main .laypage_curr {
      border: 1px solid #264DF0;
      height: 30px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      padding: 0;
      margin: 0 4px; }
    .modele-pagination .laypage_main a {
      border: 1px solid #EDEEF0;
      height: 30px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      padding: 0;
      color: #525661;
      border-radius: 2px;
      margin: 0 4px; }
    .modele-pagination .laypage_main .laypage_prev, .modele-pagination .laypage_main .laypage_next {
      border: 0; }
    .modele-pagination .laypage_main input {
      width: 48px;
      height: 30px;
      line-height: 30px; }
    .modele-pagination .laypage_main span {
      margin: 0 4px; }
    .modele-pagination .laypage_main label {
      margin-bottom: 0; }
    .modele-pagination .laypage_main button {
      width: 36px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #FFFFFF;
      background: #264DF0;
      border-radius: 2px;
      border: 0;
      padding: 0;
      vertical-align: middle; }
  .modele-pagination .page-count {
    font-size: 12px;
    color: #89919D; }

.workbench {
  padding-bottom: 80px; }
  .workbench .work-head {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .workbench .tab-location {
    display: flex; }
    .workbench .tab-location .location-item {
      width: 96px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #E1E5EB;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #222529;
      font-weight: bold;
      cursor: pointer; }
      .workbench .tab-location .location-item:last-child {
        margin-right: 0; }
      .workbench .tab-location .location-item.active {
        border: 0;
        background: rgba(38, 77, 240, 0.09);
        color: #264DF0; }
  .workbench .work-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #222529; }
  .workbench .search-box {
    display: flex;
    align-items: center; }
    .workbench .search-box .search-input {
      position: relative; }
      .workbench .search-box .search-input .iconfont {
        position: absolute;
        top: 50%;
        left: 16px;
        font-size: 16px;
        color: #89919d;
        transform: translate(0, -50%); }
      .workbench .search-box .search-input input {
        height: 36px;
        border-radius: 2px;
        border: 1px solid #e1e5eb;
        color: #89919d;
        font-size: 12px;
        padding: 0 16px 0 40px; }
    .workbench .search-box .handle {
      display: flex;
      margin-left: 8px; }
      .workbench .search-box .handle .btn-primary {
        width: 112px;
        line-height: 36px;
        border-radius: 2px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        background: #264DF0; }
        .workbench .search-box .handle .btn-primary:hover {
          background: rgba(58, 124, 255, 0.85); }
      .workbench .search-box .handle .create-model {
        position: relative; }
        .workbench .search-box .handle .create-model:hover .menu-box {
          display: block; }
        .workbench .search-box .handle .create-model .menu-box {
          display: none;
          position: absolute;
          top: 36px;
          right: 0;
          padding-top: 8px;
          z-index: 20; }
          .workbench .search-box .handle .create-model .menu-box .menu-list {
            width: 160px;
            text-align: left;
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0 4px 10px 0 rgba(34, 37, 41, 0.2);
            padding: 8px 0; }
            .workbench .search-box .handle .create-model .menu-box .menu-list .menu-item {
              padding: 0 16px;
              height: 38px;
              line-height: 38px;
              font-size: 16px;
              color: #333333;
              cursor: pointer; }
              .workbench .search-box .handle .create-model .menu-box .menu-list .menu-item:hover {
                background: #f5f7fa; }
      .workbench .search-box .handle .batch-btn {
        line-height: 34px;
        width: 112px;
        font-size: 14px;
        text-align: center;
        border: 1px solid #e1e5eb;
        cursor: pointer; }
        .workbench .search-box .handle .batch-btn .iconfont {
          margin-right: 8px; }
  .workbench .template-list {
    display: flex;
    flex-wrap: wrap; }
    .workbench .template-list .empty-view {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      padding: 50px 0; }
      .workbench .template-list .empty-view .empty-img {
        width: 240px; }
      .workbench .template-list .empty-view .empty-text {
        margin-top: 16px;
        color: #89919d;
        font-size: 16px; }
        .workbench .template-list .empty-view .empty-text .link {
          cursor: pointer;
          color: #264DF0; }
          .workbench .template-list .empty-view .empty-text .link .iconfont {
            color: #333333; }
    .workbench .template-list .template-item {
      width: calc((100% - 80px) / 5);
      margin-bottom: 24px;
      margin-right: 20px; }
      .workbench .template-list .template-item:nth-child(5n) {
        margin-right: 0; }
        .workbench .template-list .template-item:nth-child(5n) .menu-view .menu-content {
          left: auto;
          right: 0; }
    .workbench .template-list .img-view {
      width: 100%;
      height: 300px;
      background: #f5f7fa;
      border-radius: 8px;
      cursor: pointer;
      position: relative; }
      .workbench .template-list .img-view:hover .shade-view {
        display: block; }
    .workbench .template-list .cover-view {
      width: 100%;
      height: 100%;
      position: relative; }
      .workbench .template-list .cover-view .check-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 5;
        border-radius: 4px;
        border: 1px solid #e1e5eb;
        background: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(34, 37, 41, 0.13);
        color: #ffffff; }
        .workbench .template-list .cover-view .check-box .iconfont {
          font-size: 18px; }
        .workbench .template-list .cover-view .check-box.checked {
          background: #264DF0;
          border-color: #264DF0; }
      .workbench .template-list .cover-view .cover-img {
        max-width: 90%;
        max-height: 90%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%); }
      .workbench .template-list .cover-view .tag-box {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5; }
      .workbench .template-list .cover-view .tag {
        font-size: 14px;
        color: #222529;
        line-height: 32px;
        padding: 0 16px;
        display: inline-block;
        background: #ffffff;
        border-radius: 0 0 16px 0;
        box-shadow: 0 1px 8px 0 rgba(34, 37, 41, 0.12); }
        .workbench .template-list .cover-view .tag .iconfont {
          font-size: 16px;
          margin-left: 4px; }
        .workbench .template-list .cover-view .tag.tag-success {
          color: #264DF0; }
        .workbench .template-list .cover-view .tag.tag-warn {
          color: #ff4444; }
      .workbench .template-list .cover-view .personal-tag {
        position: absolute;
        line-height: 26px;
        padding: 0 12px;
        right: 8px;
        bottom: 8px;
        font-size: 14px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 13px;
        z-index: 5; }
    .workbench .template-list .shade-view {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 16px;
      background: rgba(0, 0, 0, 0.4);
      z-index: 10; }
      .workbench .template-list .shade-view .preview-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        text-align: center;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 4px;
        position: absolute;
        top: 16px;
        left: 16px; }
        .workbench .template-list .shade-view .preview-btn .iconfont {
          font-size: 18px; }
      .workbench .template-list .shade-view .tag-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        text-align: center;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 4px;
        position: absolute;
        top: 16px;
        right: 56px; }
        .workbench .template-list .shade-view .tag-btn .iconfont {
          font-size: 18px; }
      .workbench .template-list .shade-view .menu-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 4px;
        position: absolute;
        top: 16px;
        right: 16px; }
        .workbench .template-list .shade-view .menu-view .iconfont {
          font-size: 18px; }
        .workbench .template-list .shade-view .menu-view:hover .menu-content {
          display: block; }
        .workbench .template-list .shade-view .menu-view .menu-content {
          display: none;
          position: absolute;
          left: 0;
          top: 32px;
          padding-top: 8px;
          z-index: 50; }
          .workbench .template-list .shade-view .menu-view .menu-content .menu-list {
            background: #ffffff;
            box-shadow: 0 4px 10px 0 rgba(34, 37, 41, 0.2);
            width: 176px;
            border-radius: 8px;
            padding: 8px 0; }
            .workbench .template-list .shade-view .menu-view .menu-content .menu-list .cut-line {
              height: 1px;
              background: #e2e2e2;
              margin: 8px 0; }
            .workbench .template-list .shade-view .menu-view .menu-content .menu-list .menu-item {
              padding: 0 16px;
              line-height: 38px;
              font-size: 16px;
              color: #333333; }
              .workbench .template-list .shade-view .menu-view .menu-content .menu-list .menu-item:hover {
                background: #f5f7fa; }
      .workbench .template-list .shade-view .temp-btn {
        width: calc(100% - 32px);
        line-height: 40px;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 4px;
        color: #264DF0;
        font-size: 14px;
        text-align: center;
        position: absolute;
        left: 16px;
        bottom: 16px; }
    .workbench .template-list .template-info {
      padding: 16px 0; }
      .workbench .template-list .template-info .template-product {
        display: flex;
        height: 24px;
        margin-bottom: 8px; }
        .workbench .template-list .template-info .template-product .product-name {
          background: rgba(38, 77, 240, 0.09);
          padding: 0 8px;
          color: #264DF0;
          border-radius: 2px;
          font-size: 12px;
          line-height: 24px; }
      .workbench .template-list .template-info .template-name,
      .workbench .template-list .template-info .text-name {
        font-size: 16px;
        color: #222529;
        line-height: 22px;
        font-weight: bold;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .workbench .template-list .template-info .info-list {
        display: flex;
        margin-bottom: 8px; }
        .workbench .template-list .template-info .info-list .item {
          font-size: 12px;
          color: #89919d;
          line-height: 18px;
          padding: 0 8px;
          position: relative; }
          .workbench .template-list .template-info .info-list .item:after {
            content: "";
            width: 1px;
            height: 12px;
            background: #d1d5dc;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%); }
          .workbench .template-list .template-info .info-list .item:first-child {
            padding-left: 0;
            color: #264DF0; }
          .workbench .template-list .template-info .info-list .item:last-child {
            padding-right: 0; }
            .workbench .template-list .template-info .info-list .item:last-child:after {
              display: none; }
      .workbench .template-list .template-info .tags-view {
        display: flex;
        height: 24px; }
        .workbench .template-list .template-info .tags-view.one .tag {
          max-width: 100%; }
        .workbench .template-list .template-info .tags-view.two .tag {
          max-width: calc((100% - 8px) / 2); }
        .workbench .template-list .template-info .tags-view.three .tag {
          max-width: calc((100% - 16px) / 3); }
        .workbench .template-list .template-info .tags-view .tag {
          line-height: 24px;
          padding: 0 8px;
          background: #F5F7FA;
          border-radius: 2px;
          color: #505760;
          font-size: 12px;
          margin-right: 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
          .workbench .template-list .template-info .tags-view .tag:last-child {
            margin-right: 0; }
  .workbench .batch-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 264px);
    padding: 0 32px;
    height: 72px;
    background: #264DF0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    color: #ffffff; }
    .workbench .batch-bar .btns {
      display: flex; }
      .workbench .batch-bar .btns .batch-btn {
        display: flex;
        align-items: center;
        margin-right: 80px;
        cursor: pointer; }
        .workbench .batch-bar .btns .batch-btn:last-child {
          margin-right: 0; }
        .workbench .batch-bar .btns .batch-btn .iconfont {
          font-size: 24px; }
    .workbench .batch-bar .btn-close {
      cursor: pointer; }
      .workbench .batch-bar .btn-close .iconfont {
        font-size: 18px;
        color: #FFFFFF; }

.share-screen .screen-box {
  padding: 20px 0;
  display: flex;
  border-bottom: 1px solid #e2e2e2; }

.share-screen .screen-tit {
  font-size: 14px;
  color: #222529;
  line-height: 32px;
  width: 50px; }

.share-screen .option-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  position: relative; }
  .share-screen .option-list .theme-btn {
    margin-top: 4px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: rgba(38, 77, 240, 0.09);
    text-align: center;
    border-radius: 50%;
    cursor: pointer; }
    .share-screen .option-list .theme-btn .iconfont {
      color: #264DF0; }
  .share-screen .option-list.more {
    height: 32px;
    overflow: hidden;
    padding-right: 50px; }
  .share-screen .option-list.open {
    height: auto; }
    .share-screen .option-list.open .more-btn {
      color: #264DF0; }
  .share-screen .option-list .option-item {
    margin-top: 4px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #505760;
    padding: 0 16px;
    border-radius: 2px;
    margin-right: 16px;
    cursor: pointer; }
    .share-screen .option-list .option-item:hover {
      background: #f5f7fa; }
    .share-screen .option-list .option-item.active {
      background: rgba(38, 77, 240, 0.09);
      color: #264df0;
      font-weight: bold; }
  .share-screen .option-list .more-btn {
    font-size: 14px;
    line-height: 32px;
    color: #89919d;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .share-screen .option-list .more-btn .iconfont {
      font-size: 12px;
      margin-left: 4px; }

.share-screen .screen-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0; }
  .share-screen .screen-line .screen {
    display: flex; }
  .share-screen .screen-line .genre {
    display: flex;
    position: relative;
    padding-right: 56px; }
    .share-screen .screen-line .genre:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 2px;
      height: 24px;
      background: #e2e2e2; }
    .share-screen .screen-line .genre.no-cut:after {
      display: none; }
    .share-screen .screen-line .genre .item {
      font-size: 16px;
      color: #505760;
      line-height: 32px;
      margin-right: 30px;
      cursor: pointer; }
      .share-screen .screen-line .genre .item.active {
        color: #264DF0;
        font-size: 20px;
        font-weight: bold; }
      .share-screen .screen-line .genre .item:last-child {
        margin-right: 0; }
  .share-screen .screen-line .price-select {
    display: flex;
    padding-left: 56px; }
    .share-screen .screen-line .price-select .tit {
      font-size: 16px;
      color: #222529;
      line-height: 32px;
      margin-right: 8px; }
  .share-screen .screen-line .skip-btn {
    display: block;
    padding: 0 23px;
    line-height: 36px;
    border-radius: 18px;
    font-size: 14px;
    color: #ffffff;
    background: #264DF0;
    text-decoration: none; }

.release-view {
  position: relative; }
  .release-view .loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-size: 14px; }
  .release-view .release-loading {
    padding: 30px 0;
    text-align: center; }
  .release-view .progress-view {
    padding: 20px 0;
    background: rgba(38, 77, 240, 0.09);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; }
    .release-view .progress-view .progress-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #89919d; }
      .release-view .progress-view .progress-item.active {
        color: #264DF0; }
        .release-view .progress-view .progress-item.active .num {
          border-color: #264DF0; }
      .release-view .progress-view .progress-item .num {
        width: 24px;
        height: 24px;
        line-height: 22px;
        text-align: center;
        border: 1px solid #89919d;
        border-radius: 50%;
        text-align: center;
        margin: 0 8px 0 0; }
    .release-view .progress-view .cut-line {
      width: 156px;
      height: 1px;
      border: 1px dashed #89919d;
      margin: 0 24px; }
      .release-view .progress-view .cut-line.active {
        border-color: #264DF0; }
  .release-view .info-list {
    min-height: 284px;
    position: relative; }
    .release-view .info-list .info-empty {
      height: 284px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .release-view .info-list .info-empty img {
        width: 164px; }
    .release-view .info-list .info-item {
      padding: 16px 0;
      border-bottom: 1px solid #e2e2e2;
      display: flex;
      align-items: center; }
      .release-view .info-list .info-item .error-img {
        width: 64px;
        height: 64px;
        background: #eef0f3;
        border-radius: 2px;
        margin-right: 16px; }
        .release-view .info-list .info-item .error-img img {
          max-width: 100%;
          max-height: 100%; }
      .release-view .info-list .info-item .info .tit {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
        color: #222529;
        font-weight: bold; }
      .release-view .info-list .info-item .info .desc {
        color: #505760;
        font-size: 14px;
        line-height: 24px; }
  .release-view .channel-view .channel-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px; }
    .release-view .channel-view .channel-head .channel-title {
      font-size: 14px;
      color: #505760; }
    .release-view .channel-view .channel-head .check-box {
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .release-view .channel-view .channel-head .check-box.active .iconfont {
        color: #264DF0; }
      .release-view .channel-view .channel-head .check-box .iconfont {
        font-size: 16px;
        color: #88919c;
        margin-right: 2px; }
  .release-view .channel-view .channel-list {
    display: flex;
    flex-wrap: wrap; }
    .release-view .channel-view .channel-list .channel-item {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      color: #505760;
      padding: 0 12px;
      border-radius: 2px;
      background: #f5f7fa;
      margin-right: 16px;
      margin-bottom: 8px; }
      .release-view .channel-view .channel-list .channel-item.active {
        background: #264DF0;
        color: #ffffff; }
  .release-view .result-view {
    min-height: 284px;
    position: relative; }
    .release-view .result-view .success-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 284px; }
      .release-view .result-view .success-box .success-icon {
        margin-bottom: 15px; }
        .release-view .result-view .success-box .success-icon img {
          width: 172px;
          display: block; }
      .release-view .result-view .success-box .success-tips {
        font-size: 14px;
        color: #89919d; }
  .release-view .result-List .result-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 48px;
    border-bottom: 1px solid #e2e2e2; }
    .release-view .result-List .result-item .result-name {
      font-size: 16px;
      color: #222529; }
    .release-view .result-List .result-item .result-info {
      font-size: 14px; }
      .release-view .result-List .result-item .result-info .iconfont {
        font-size: 16px;
        margin-right: 4px; }
    .release-view .result-List .result-item .result-success {
      color: #00a854; }
    .release-view .result-List .result-item .result-error {
      color: #ff4444; }

.release-foot-view {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .release-foot-view.center {
    justify-content: space-between; }
  .release-foot-view .prompt {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #89919d; }
    .release-foot-view .prompt .iconfont {
      font-size: 12px;
      margin-right: 4px; }
  .release-foot-view .btns .btn {
    margin-right: 8px; }
    .release-foot-view .btns .btn:last-child {
      margin-right: 0; }

.group-form-view {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .group-form-view .group-size-input {
    display: flex;
    width: 380px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e1e5eb;
    overflow: hidden; }
    .group-form-view .group-size-input:has(input:focus) {
      border-color: #264DF0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
    .group-form-view .group-size-input input {
      border: 0;
      height: 38px;
      padding: 0 16px 0 46px;
      color: #222529;
      font-size: 14px;
      border-radius: 0;
      width: 100%;
      height: 100%; }
      .group-form-view .group-size-input input:focus, .group-form-view .group-size-input input:hover {
        box-shadow: none;
        border: 0 !important; }
    .group-form-view .group-size-input .item {
      position: relative;
      flex-grow: 1; }
      .group-form-view .group-size-input .item:after {
        content: "";
        display: block;
        width: 1px;
        height: 20px;
        background: #e2e2e2;
        position: absolute;
        top: 10px;
        right: 0; }
      .group-form-view .group-size-input .item:last-child:after {
        display: none; }
      .group-form-view .group-size-input .item .title {
        position: absolute;
        top: 0;
        left: 16px;
        line-height: 38px;
        font-size: 14px;
        color: #89919d; }
  .group-form-view .group-size-select {
    position: relative;
    border-radius: 4px;
    border: 1px solid #e1e5eb;
    overflow: hidden; }
    .group-form-view .group-size-select:has(select:focus) {
      border-color: #264DF0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
    .group-form-view .group-size-select .title {
      position: absolute;
      font-size: 14px;
      color: #89919d;
      line-height: 40px;
      top: 0;
      left: 16px; }
    .group-form-view .group-size-select select {
      width: 134px;
      height: 38px;
      border: 0 !important;
      padding: 0 16px 0 56px; }
      .group-form-view .group-size-select select:focus, .group-form-view .group-size-select select:hover {
        box-shadow: none; }

.c-form .upload-view {
  margin-left: 70px;
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto; }
  .c-form .upload-view .upload-item {
    width: 96px;
    height: 96px;
    border-radius: 2px;
    background: #f5f7fa;
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    overflow: hidden; }
    .c-form .upload-view .upload-item:nth-child(5n) {
      margin-right: 0; }
    .c-form .upload-view .upload-item .btn-delete {
      display: block;
      width: 56px;
      height: 56px;
      background: rgba(0, 0, 0, 0.64);
      border-radius: 50%;
      position: absolute;
      right: -28px;
      top: -28px;
      cursor: pointer; }
      .c-form .upload-view .upload-item .btn-delete .iconfont {
        position: absolute;
        bottom: 5px;
        left: 8px;
        color: #FFFFFF;
        font-size: 16px; }
    .c-form .upload-view .upload-item .item-preview {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: absolute; }
      .c-form .upload-view .upload-item .item-preview img {
        max-width: 100%;
        max-height: 100%; }
      .c-form .upload-view .upload-item .item-preview .error-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.4); }
        .c-form .upload-view .upload-item .item-preview .error-box .iconfont {
          font-size: 18px; }
  .c-form .upload-view .upload-box {
    margin-left: 0;
    margin-bottom: 8px; }

.c-form .upload-box {
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  width: 96px;
  height: 96px;
  position: relative;
  border-radius: 2px;
  border: 1px solid #e1e5eb;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #89919d; }
  .c-form .upload-box .iconfont {
    font-size: 20px;
    margin-bottom: 2px; }
  .c-form .upload-box input {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 100px;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 1; }

.c-form .upload-preview {
  margin-left: 70px;
  display: flex;
  align-items: flex-end; }
  .c-form .upload-preview .img-view {
    width: 96px;
    height: 96px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F8FA; }
  .c-form .upload-preview img {
    max-width: 100%;
    max-height: 100%; }
  .c-form .upload-preview .reload-btn {
    font-size: 14px;
    color: #89919d;
    line-height: 20px;
    cursor: pointer; }
    .c-form .upload-preview .reload-btn .iconfont {
      font-size: 16px;
      margin-right: 2px; }

.c-form .radio-group {
  display: flex; }
  .c-form .radio-group .radio-item {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #505760;
    margin-right: 32px; }
    .c-form .radio-group .radio-item:last-child {
      margin-right: 0; }
    .c-form .radio-group .radio-item.active .iconfont {
      color: #264DF0; }
    .c-form .radio-group .radio-item .iconfont {
      font-size: 14px;
      margin-right: 2px; }

.c-form .upload-box2 {
  margin-left: 70px;
  display: flex;
  flex-direction: column; }
  .c-form .upload-box2 .upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 106px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #e1e5eb;
    margin-bottom: 8px;
    color: #89919d;
    font-size: 14px; }
    .c-form .upload-box2 .upload-btn .iconfont {
      font-size: 14px;
      margin-right: 2px; }
    .c-form .upload-box2 .upload-btn input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0; }
  .c-form .upload-box2 .upload-tips {
    font-size: 14px;
    color: #89919d; }

.c-form .model-info {
  font-size: 14px;
  color: #222529;
  margin-left: 70px;
  display: flex;
  flex-wrap: wrap; }
  .c-form .model-info .info-item {
    line-height: 40px; }

.upload-font-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 20px 100px !important; }
  .upload-font-box .upload-font-img img {
    width: 288px; }
  .upload-font-box .upload-tips {
    color: #505760;
    font-size: 16px;
    margin-bottom: 8px; }
  .upload-font-box .upload-btn {
    color: #264DF0;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    cursor: pointer; }
    .upload-font-box .upload-btn input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }

.font-list-view {
  padding: 8px 0 !important;
  max-height: 440px !important;
  min-height: 284px;
  overflow-y: auto; }
  .font-list-view .font-item {
    display: flex;
    cursor: pointer;
    position: relative; }
    .font-list-view .font-item .font-info {
      line-height: 64px;
      padding-left: 27px;
      display: flex;
      align-items: center; }
      .font-list-view .font-item .font-info .iconfont {
        color: #264DF0;
        font-size: 26px;
        margin-right: 20px; }
      .font-list-view .font-item .font-info .name {
        color: #222529;
        font-size: 16px; }
    .font-list-view .font-item .del-font {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translate(0, -50%);
      display: none; }
      .font-list-view .font-item .del-font .iconfont {
        color: #88919d;
        font-size: 20px; }
        .font-list-view .font-item .del-font .iconfont:hover {
          color: #264DF0; }
    .font-list-view .font-item:hover {
      background: #f5f7fa; }
      .font-list-view .font-item:hover .del-font {
        display: block; }
    .font-list-view .font-item .status-view {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translate(0, -50%); }
      .font-list-view .font-item .status-view .status-item {
        display: flex;
        align-items: center;
        font-size: 14px; }
        .font-list-view .font-item .status-view .status-item .iconfont {
          font-size: 14px;
          margin-right: 4px; }
        .font-list-view .font-item .status-view .status-item.status-loading {
          color: #264df0; }
        .font-list-view .font-item .status-view .status-item.status-success {
          color: #00a854; }
        .font-list-view .font-item .status-view .status-item.status-error {
          color: #ff4444; }

.upload-font-foot {
  text-align: left !important; }
  .upload-font-foot .btns {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .upload-font-foot .add-font {
    font-size: 16px;
    color: #264DF0;
    cursor: pointer;
    position: relative; }
    .upload-font-foot .add-font .iconfont {
      font-size: 16px;
      margin-right: 2px; }
    .upload-font-foot .add-font input[type="file"] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0; }

.stack-topcenter {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 604px; }
  .stack-topcenter .alert-success, .stack-topcenter .alert-warning, .stack-topcenter .alert-info, .stack-topcenter .alert-danger {
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    padding: 13px 17px; }
  .stack-topcenter .alert-success {
    background: #F6FFED;
    border: 1px solid #B7EB8F; }
    .stack-topcenter .alert-success .iconfont {
      color: #52C41A; }
  .stack-topcenter .alert-warning {
    background: #FFFBE6;
    border: 1px solid #FFE58F; }
    .stack-topcenter .alert-warning .iconfont {
      color: #FAAD14; }
  .stack-topcenter .alert-info {
    background: #E6F7FF;
    border: 1px solid #91D5FF; }
    .stack-topcenter .alert-info .iconfont {
      color: #1890FF; }
  .stack-topcenter .alert-danger {
    background: #FFF1F0;
    border: 1px solid #FFA39E; }
    .stack-topcenter .alert-danger .iconfont {
      color: #FF4D4F; }
  .stack-topcenter .ui-pnotify-shadow {
    box-shadow: none; }
  .stack-topcenter .alert {
    margin-bottom: 0; }
  .stack-topcenter .ui-pnotify-title {
    margin-bottom: 0;
    font-size: 14px; }
  .stack-topcenter .ui-pnotify-icon {
    height: 16px;
    display: flex;
    align-items: center; }
  .stack-topcenter .ui-pnotify-icon .iconfont {
    font-size: 14px; }

/* MessageService.confirm */
.confirm-dialog {
  width: 528px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 24px 32px; }

.confirm-dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.confirm-dialog-title {
  font-size: 18px;
  font-weight: 600;
  color: #333333; }

.confirm-dialog-content {
  padding: 24px 0;
  font-size: 16px;
  font-weight: 400;
  color: #333333; }

.confirm-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: right; }

.confirm-dialog-footer-cancel {
  padding: 10px 30px;
  color: #333333;
  background: #F7F8FA;
  border-radius: 4px; }

.confirm-dialog-footer-theme {
  padding: 10px 30px;
  color: #ffffff;
  background: #264DF0;
  border-radius: 4px;
  margin-left: 16px; }

.confirm-dialog-footer-delete {
  background: #ff4544 !important; }

.modele-select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #E1E5EB;
  padding: 0 32px 0 16px;
  cursor: pointer; }
  .modele-select:hover, .modele-select.open {
    box-shadow: 0 0 4px 0 rgba(58, 124, 255, 0.45);
    border: 1px solid #264DF0; }
  .modele-select .prefix {
    color: #89919D;
    font-size: 14px;
    margin-right: 12px; }
  .modele-select .modele-select-input {
    flex: 1 !important;
    height: 100% !important;
    color: #222529 !important;
    font-size: 14px !important;
    cursor: pointer;
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    background: transparent !important; }
    .modele-select .modele-select-input:hover, .modele-select .modele-select-input:focus {
      box-shadow: none;
      border: none !important; }
  .modele-select .arrow {
    position: absolute;
    color: #222529;
    font-size: 16px;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    cursor: pointer; }

.modele-select-option {
  position: fixed;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0 1px 8px 0 rgba(34, 37, 41, 0.12);
  border-radius: 2px;
  padding: 8px 0;
  min-height: 44px;
  max-height: 296px;
  overflow-y: auto;
  z-index: 9999;
  display: none; }
  .modele-select-option .option-item {
    line-height: 36px;
    color: #505760;
    font-size: 14px;
    padding: 0 16px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .modele-select-option .option-item.active {
      color: #264DF0;
      font-weight: bold; }
    .modele-select-option .option-item:hover {
      background: #F5F7FA; }

.choose-size-box {
  position: absolute;
  width: 324px;
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  right: 8px;
  bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: #222529;
  font-size: 14px;
  z-index: 900; }
  .choose-size-box .left-menu, .choose-size-box .right-menu {
    display: flex;
    align-items: center;
    position: relative; }
  .choose-size-box .lessen, .choose-size-box .magnify {
    cursor: pointer; }
    .choose-size-box .lessen .iconfont, .choose-size-box .magnify .iconfont {
      font-size: 16px;
      color: #222529; }
  .choose-size-box .right-menu .menu-item {
    cursor: pointer;
    margin-right: 16px; }
    .choose-size-box .right-menu .menu-item:last-child {
      margin-right: 0; }
    .choose-size-box .right-menu .menu-item .iconfont {
      font-size: 20px; }
      .choose-size-box .right-menu .menu-item .iconfont.active {
        color: #fac779; }
  .choose-size-box .ratio {
    margin: 0 30px; }

.widget-select-model {
  background: #FFFFFF; }
  .widget-select-model .widget-select-head {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px; }
    .widget-select-model .widget-select-head .title {
      color: #222529;
      font-size: 18px;
      font-weight: bold; }
    .widget-select-model .widget-select-head .search-input {
      position: relative; }
      .widget-select-model .widget-select-head .search-input .iconfont {
        position: absolute;
        top: 50%;
        left: 16px;
        font-size: 16px;
        color: #89919D;
        transform: translate(0, -50%); }
      .widget-select-model .widget-select-head .search-input input {
        height: 36px;
        border-radius: 2px;
        border: 1px solid #e1e5eb;
        color: #89919D;
        font-size: 12px;
        padding: 0 16px 0 40px;
        width: 208px; }
  .widget-select-model .option-list {
    height: 420px;
    overflow-y: auto; }
    .widget-select-model .option-list .option-item {
      padding: 16px 0;
      border-bottom: 1px solid #E2E2E2;
      display: flex; }
      .widget-select-model .option-list .option-item:last-child {
        border-bottom: 0; }
    .widget-select-model .option-list .cover-img {
      width: 64px;
      height: 64px;
      background: #EEF0F3;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px; }
      .widget-select-model .option-list .cover-img img {
        max-height: 100%;
        max-width: 100%; }
    .widget-select-model .option-list .option-info {
      flex: 1;
      padding-right: 30px;
      display: flex;
      align-items: center;
      position: relative; }
      .widget-select-model .option-list .option-info .option-name {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #222529; }
      .widget-select-model .option-list .option-info .select-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        cursor: pointer;
        border: 1px solid #E1E5EB;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        text-align: center; }
        .widget-select-model .option-list .option-info .select-icon.active {
          border: 0; }
          .widget-select-model .option-list .option-info .select-icon.active .iconfont {
            opacity: 1; }
        .widget-select-model .option-list .option-info .select-icon .iconfont {
          font-size: 20px;
          line-height: 22px;
          color: #264DF0;
          opacity: 0; }

.widget-select-model-foot .btn {
  height: 40px;
  padding: 0 34px; }

.widget-select-model-foot .btn-cancel {
  background: #F7F8FA; }

.uib-monthpicker .btn, .uib-yearpicker .btn {
  border: none; }
  .uib-monthpicker .btn.btn-info, .uib-yearpicker .btn.btn-info {
    color: #333;
    background: #FFFFFF; }
  .uib-monthpicker .btn:hover, .uib-yearpicker .btn:hover {
    background: #264DF0;
    color: #FFFFFF; }
    .uib-monthpicker .btn:hover .text-info, .uib-yearpicker .btn:hover .text-info {
      color: #FFFFFF; }
  .uib-monthpicker .btn .text-info, .uib-yearpicker .btn .text-info {
    color: #264DF0; }
  .uib-monthpicker .btn.active, .uib-yearpicker .btn.active {
    background: #264DF0;
    color: #FFFFFF; }
    .uib-monthpicker .btn.active:hover, .uib-yearpicker .btn.active:hover {
      border: none; }
    .uib-monthpicker .btn.active .text-info, .uib-yearpicker .btn.active .text-info {
      color: #FFFFFF; }
  .uib-monthpicker .btn:focus, .uib-yearpicker .btn:focus {
    outline: none; }

.c-form .c-form-div input.c-input1 {
  padding: 0 16px !important; }

.icon-closeicon1 {
  color: #89919d; }

.c-form .c-form-required {
  color: #ff0101; }

.page-item-cover {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 99999; }

.page-item-cover img {
  width: 100%;
  height: 100%; }

.cover-move {
  transform: translate(-2520px, 132px);
  transition: all 1s linear;
  position: fixed !important; }

.cover-scale {
  transform: scale(0.1);
  transition: all 0.5s linear; }

.tooltip {
  word-break: break-word; }

li {
    list-style: none
}

img {
    border: none
}

button {
    background: none;
    border: none;
}
.c-color{
    color: #fe5454;
}

.wrap {
    margin: 50px 0 0 254px;
    transition: all .5s ease;
}

.company-info {
    position: absolute;
    font-size: 12px;
    color: #979797
}

.company-info2 {
    margin-top: 20px;
    font-size: 12px;
    color: #979797;
}

.wrap-mar50 {
    margin-left: 50px !important;
}

.login-wrap {
    height: 100vh;
    /*background     : url("../images/login_background.png") no-repeat;*/
    /*background-size: 100% 100%;*/
    text-align: center;
}

.login-content {
    height: 466px;
    margin-top: 30vh;
}

.login-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
}

.login-left {
    height: 466px;
    line-height: 466px;
    background: #F7F7F7;
}

.login-right {
    height: 466px;
    background: #fff;
}

.login-right h3 {
    font-size: 24px;
    color: #232323;
    padding: 90px 0 58px 74px;
    margin: 0;
    text-align: left;
}

.login-right input,
.register-content input {
    font-size: 16px;
    border: none;
    outline: none;
}

.login-content input::-webkit-input-placeholder,
.register-content input::-webkit-input-placeholder {
    color: #ddd;
}

.login-forget {
    color: #848484;
    font-size: 14px;
    margin-top: 8px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.btn-red {
    background: #fe5454;
    color: #fff;
    height: 48px;
    font-size: 16pt;
    border-radius: 26px;
    width: 210px;
}

.btn-red:hover {
    background: #fe4632;
    color: #fff;
}

.login-text {
    font-size: 14px;
    color: #fe5454;
}

.navbar {
    padding: 0;
    margin: 0;
    border: none;
}

.navbar-top {
    background: #3b3b3b;
    line-height: 28px;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #ddd;
    outline: none;
    z-index: 7;
}

.navbar-logo {
    outline: none;
    float: left;
    margin-top: 13px;
    width: 140px;
    text-align: center;
}

.navbar-logo img {
    width: 197px;
    height: 28px;
    margin-left: 30px;
}

.titleList {
    outline: none;
    float: left;
    margin-left: 120px;
    height: 60px;
}

.titleList li {
    float: left;
    color: #fff;
    line-height: 57px;
    margin-right: 50px;
    font-size: 14px;
    outline: none;
}

.sidebar1-shrink {
    height: 20px;
    text-align: center;
    background: #262626;
    margin-bottom: 2px;
}

.logo-text {
    color: #fff;
    font-size: 28px;
    margin-left: 33px;
    outline: none;
}

#account-menu {
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 50px;
    width: 110px;
    line-height: 48px;
    text-align: center;

}

.navbar-setting {
    float: right;
    margin-right: 20px;
    display: block !important;
    height: 60px !important;
}

.navbar-user {
    float: right;
    margin-top: 5px;
}

.users {
    display: block;
    margin-top: -18px;
    width: 48px;
    text-align: center;
}

.users-a {
    text-align: center;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70px;
    /*display    : inline-block;*/
}

.users-a:hover {
    color: #ff6c5f;
}

.arrows-icon {
    width: 8px;
    height: 8px;
    padding: 8px;
    background: url("../images/icons/arrow.png") no-repeat center;
}

.user {
    display: block;
    background: #fff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

/* ==========================================================================
register 注册页面
========================================================================== */
.register-wrap {
    height: 100vh;
    background: url("../images/login_background2.png") no-repeat;
    background-size: contain;
    text-align: center;
    position: relative;
}

.register-content {
    height: 690px;
    background: #fff;
    margin-left: 37vw;
    width: 25vw
}

.register-title {
    padding: 40px 0 40px 0;
    margin: 0;
    text-align: left;
    font-size: 24px;
    color: #232323;
}

.register-condition {
    margin-bottom: 15px;
}

.register-btn {
    width: 180px;
    height: 40px;
    margin: 30px 0;
}

/* ==========================================================================
navbar in sidebar1
========================================================================== */
.side {
    display: block !important;
}

.sidebar1 {
    left: 0;
    top: 60px;
    position: fixed;
    width: 254px;
    height: 100%;
    background: #fff;
    transition: all .5s ease;
    z-index: 105;
    padding-top: 20px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, .2);*/
}

.sidebar1 li a {
    display: block;
    color: #232323;
    font-size: 14px;
    border-left: 2px solid transparent;
    transition: .3s linear;
    padding: 0 10px 0 50px;
    text-decoration: none;
}

.sidebar1>li>a>i {
    margin-right: 10px;
}

.sidebar1 .hover,
.sidebar1 li a:hover {
    color: #FF4747;
    /*background   : #ccc;*/
    text-decoration: none;
}

.sidebar1 li {
    line-height: 40px;
    white-space: nowrap;
    cursor: pointer;
}

.transition {
    transition: all .5s ease;
}

.sidebar1 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.arrows {
    z-index: 1001;
    position: absolute;
    right: 20px;
    border: 1px solid #c8c8c8;
    border-width: 1px 1px 0 0;
    display: inline-block;
    height: 8px;
    -moz-transform-origin: center center;
    -moz-transform: rotate(45deg);
    -moz-transition: all .3s ease-in .1s;
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(45deg);
    -webkit-transition: all .3s ease-in .1s;
    -o-transform-origin: center center;
    -o-transform: rotate(45deg);
    -o-transition: all .3s ease-in .1s;
    -ms-transform-origin: center center;
    -ms-transform: rotate(45deg);
    -ms-transition: all .3s ease-in .1s;
    transform-origin: center center;
    transform: rotate(45deg);
    transition: all .3s ease-in .1s;
    width: 8px;
    margin-top: 6px;
}

.arrowsTop {
    z-index: 1001;
    position: absolute;
    right: -16px;
    top: 8px;
    border: 1px solid #c8c8c8;
    border-width: 1px 1px 0 0;
    display: inline-block;
    height: 8px;
    -moz-transform-origin: center center;
    -moz-transform: rotate(135deg);
    -moz-transition: all .3s ease-in .1s;
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(135deg);
    -webkit-transition: all .3s ease-in .1s;
    -o-transform-origin: center center;
    -o-transform: rotate(135deg);
    -o-transition: all .3s ease-in .1s;
    -ms-transform-origin: center center;
    -ms-transform: rotate(135deg);
    -ms-transition: all .3s ease-in .1s;
    transform-origin: center center;
    transform: rotate(135deg);
    transition: all .3s ease-in .1s;
    width: 8px;
}

.arrowHover:hover b {
    -moz-transform: rotate(-5deg);
    -moz-transition: all .2s ease-in .1s;
    -webkit-transform: rotate(-45deg);
    -webkit-transition: all .2s ease-in .1s;
    -o-transform: rotate(-45deg);
    -o-transition: all .2s ease-in .1s;
    -ms-transform: rotate(-45deg);
    -ms-transition: all .2s ease-in .1s;
    transform: rotate(-45deg);
    transition: all .2s ease-in .1s;
    top: 12px;
}

/* ==========================================================================
伸缩侧边css代码
========================================================================== */
.sidebar-collapse .sidespan {
    display: none;
}

.sidebar-collapse .sidebar1 {
    width: 50px;
}

.sidebar-collapse .sidebar1 li {
    position: relative;
    z-index: 101;
    text-align: center;
}

.sidebar-collapse .sidebar1 li a {
    padding: 0 14px;
}

.sidebar-collapse .sidebar1 li:hover a {
    border-left: 2px solid #ff6c5f;
    background: #262626;
    text-decoration: none;
}

.btn1-shrik1 {
    background: url("../images/icons/menu-shrink1.png") no-repeat center;
}

.btn1-shrik2 {
    background: url("../images/icons/menu-open1.png") no-repeat center;
}

.icon {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.25em;
    margin-right: 0.8em;
    fill: currentColor;
    overflow: hidden;
}

.listBGC {
    background-color: #FAFAFA !important;
    margin: -38px 0 0 -10px;
    padding: 20px;
    position: relative;
}

.list-body {
    background-color: #fff !important;
    padding-top: 15px;
    padding-left: 15px;
}

.list-table {
    color: white !important;
}

.listCreate {
    border: 1px solid #bbb;
    /*width      : 700px;*/
    background: #fff !important;
    border-radius: 5px;
}

.listDetail {
    border: 1px solid #bbb;
    width: 700px;
    padding: 0 !important;
    background: #fff !important;
}

.current-location {
    margin-bottom: 20px;
}

.modal-detail {
    background: #fff !important;
    height: 56px;
    line-height: 56px;
    padding-left: 20px;
    border-bottom: 1px solid rgb(229, 229, 229);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.modal-detail span,
.modal-detail h4 {
    color: #232323;
    font-size: 16px;
    font-weight: 600;
    line-height: 56px;
}

.moduleNameBGC {
    background: #fff !important;
    margin-bottom: 10px !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.close {
    margin: 14px 26px 0 0;
    outline: none;
}

.sidebar1-btn {
    outline: none;
    border: none;
    width: 21px;
    height: 12px;
}

.current-menu {
    color: #ff6c5f;
}

.moduleNameBGC {
    background: #fff !important;
    margin-bottom: 10px !important;
}

/*-----------------------------------searchbar  start-----------------------------------------*/

.searchBar {
    width: auto;
    height: 30px;
    float: left;
}

.createBar {
    height: 30px;
    float: left;
    margin-left: 10px;
}

.createBar .pull-right {
    background: #ff6c5f !important;
    border-color: #ff6c5f !important;
    outline: none;
}

#searchQuery {
    font-size: 14px;
    border: 1px solid #ff6c5f !important;
    width: 137px;
    height: 30px;
    border-radius: 5px;
    background: none;
    box-shadow: none;
    border-right: none;
    color: #ff6c5f !important;
}

.btn-search {
    height: 30px;
    width: 38px;
    margin-left: -6px !important;
    /*padding  : 0!important;*/
    background: #ff6c5f url("../images/icons/q-icon.png") no-repeat center !important;
}

.btn-searched {
    display: inline-block;
    margin-right: 40px;
}

.btn-searched button {}

.btn-clear {
    margin: 0 30px;
    display: inline-block;
    vertical-align: middle;
}

.btn-clear button {
    border-radius: 4px !important;
    height: 30px;
}

/*-----------------------------------searchbar  end-----------------------------------------*/

/*----------------------------------------table  start---------------------------------------*/
.list-table {
    color: white !important;
    background-color: #fff !important;
    text-align: center;
    color: #3e3e3e !important;
    font-weight: normal;
}

.jh-table {
    border-top: 1px solid #ddd;
}

.table {
    margin: 0 !important;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: white !important;
}

.table-responsive {
    background: #fff !important;
    text-align: center;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 1px solid #ddd;
}

.table-thead {
    height: 35px;
    line-height: 35px;
}

.table-thead th {
    text-align: center;
    padding: 18px 0 !important;
}

.table-thead span {
    color: #3e3e3e !important;
    font-weight: normal;
}

.table-tbody td {
    color: #3e3e3e !important;
    text-align: center;
    font-size: 14px;
    padding: 9px 0 !important;
}

.table-tbody .text-right span {
    display: inline-block;
    width: 23px;
    height: 23px;
}

.btn-check {
    color: #f60;
    background: url("../images/icon/show1.png") no-repeat center;
}

.btn1:hover .btn-check {
    background: url("../images/icon/show.png") no-repeat center;
}

.btn-edit {
    background: url("../images/icon/edit1.png") no-repeat center;
}

.btn2:hover .btn-edit,
.btn-edit:hover {
    background: url("../images/icon/edit.png") no-repeat center;
}
.btn-approve {
    background: url("../images/icon/approve.png") no-repeat center;
}

.btn-approve:hover {
    background: url("../images/icon/approve2.png") no-repeat center;
}
.btn-simple {
    background: url("../images/icon/simple.png") no-repeat center;
}

.btn-simple:hover {
    background: url("../images/icon/simple2.png") no-repeat center;
}

.btn-copy {
    background: url("../images/icon/copy1.png") no-repeat center;
}

.btn-copy:hover {
    background: url("../images/icon/copy.png") no-repeat center;
}

.btn-up-type {
    background: url("../images/icon/up_type1.png") no-repeat center;
}

.btn-up-type:hover {
    background: url("../images/icon/up_type.png") no-repeat center;
}

.btn-del {
    background: url("../images/icon/del1.png") no-repeat center;
}

.btn-del:hover {
    background: url("../images/icon/del.png") no-repeat center;
}

.btn-ups {
    background: url("../images/icon/ups1.png") no-repeat center;
}

.btn-ups:hover {
    background: url("../images/icon/ups.png") no-repeat center;
}

.btn-sale {
    background: url("../images/icon/sale.png") no-repeat center;
}

.btn-sale:hover {
    background: url("../images/icon/sale1.png") no-repeat center;
}

.btn-delivery {
    background: url("../images/icon/delivery1.png") no-repeat center;
}

.btn-delivery:hover {
    background: url("../images/icon/delivery.png") no-repeat center;
}

.btn-down {
    background: url("../images/icon/down1.png") no-repeat center;
}

.btn-down:hover {
    background: url("../images/icon/down.png") no-repeat center;
}

.checkbox-0 {
    background: url("../images/icon/checkbox0.png") no-repeat center;
}

.checkbox-1 {
    background: url("../images/icon/checkbox1.png") no-repeat center;
}

.btn-resetPassword {
    background: url("../images/icon/resetPassword1.png") no-repeat center;
}

.btn-resetPassword:hover {
    background: url("../images/icon/resetPassword.png") no-repeat center;
}

.btn-sub:hover .hover-box {
    display: block !important;
}

.btn-sub:hover .hover-box2 {
    display: block !important;
}

.btn-sub {
    background: none;
    padding: 0;
    border: none;
    position: relative;
    outline: none;
}

.hover-box2 {
    position: absolute;
    top: -26px;
    left: -15px;
    width: 65px !important;
    height: 26px !important;
    display: none;
    font-size: 9px;
    color: #3e3e3e;
    padding: 4px;
    background: #fff url("../images/icon/hoverbox55.png") no-repeat center;
}

.hover-box {
    position: absolute;
    top: -26px;
    left: 0;
    width: 40px !important;
    height: 24px !important;
    display: none;
    font-size: 9px;
    color: #3e3e3e;
    padding: 4px;
    background: #fff url("../images/icon/hoverbox.png") no-repeat center;
}

/*----------------------------------------table  end---------------------------------------*/

/*----------------------------------------页数跳转  start---------------------------------------*/
.pagination-sm {
    background: #f1f2f7 !important
}

.pagination-sm li {}

.pagination-sm a {
    border: none !important;
    color: #3e3e3e !important;
    font-size: 14px !important;
    background: #f1f2f7 !important;
    margin: 0 10px !important;
    padding: 7px 14px !important;
}

.pagination-sm .active a,
.pagination-sm a:hover {
    background: #ff6c5f !important;
    border-radius: 5px;
    color: #fff !important;
}

.pagination-next a,
.pagination-prev a {
    font-family: "宋体";
    font-weight: 600;
}

.text-jump {
    float: left;
    height: 70px;
    line-height: 70px;
}

.text-jump input {
    vertical-align: middle;
    display: table-cell;
    border: 1px solid #ccc;
}

.page {
    line-height: 76px;
    width: 60px;
    height: 30px;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
}

.page-text {
    text-indent: 8px;
    height: 35px;
    width: 50px;
    color: #3e3e3e;
}

.page-btn {
    background: #ff6c5f;
    line-height: 76px;
    color: #fff;
    padding: 6px 10px;
    cursor: pointer
}

/*----------------------------------------页数跳转  end---------------------------------------*/
.page-wrap {
    height: 89vh;
    padding-left: 50px;
    margin-top: -40px;
}

.page-moban-text {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 100px;
}

.page-moban-text .line {
    display: inline-block;
    width: 40%;
    border-top: 1px solid #979797;
}

.page-moban-text .txt {
    color: #232323;
    vertical-align: middle;
    vertical-align: -20%;
    margin: 0 32px;
}

.other-size {
    width: 160px;
    margin-left: 96px;
    text-align: center;
    outline: none;
}

.other-size-box {
    width: 160px;
    height: 160px;
    border: 1px dashed #b3b3b3;
    margin-bottom: 20px;
    background: #fff url("../images/icons/ic_add2.svg") no-repeat center;
}

/*----------------------------------------详情页面  start---------------------------------------*/
.readLabel {
    padding-top: 0px !important;
}

.modal-body label {
    font-size: 14px;
    color: #3e3e3e;
    font-weight: normal;
    padding-right: 0 !important;
}

.modal-detail span,
.modal-detail h4 {
    color: #ff6c5f;
    font-size: 14px;
    line-height: 40px;
}

/*----------------------------------------详情页面  end---------------------------------------*/
/*首页修改样式*/
ul.sidebar-menu,
ul.sub {
    margin: 0;
    padding: 0;
    list-style: none;
}

.subMenu {
    max-height: 300px;
    overflow: auto;
}

.moduleList .sub {
    width: 120px;
}

.sidebar {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 80px;
    outline: none;
}

.firstMenu {
    padding-left: 50px;
    outline: none;
}

.bg-fff {
    background: #fafafa;
    position: relative;
}

.templateItem {
    width: 90%;
    border-bottom: 1px solid #E1E1E1;
    padding: 40px 0;
}

.templateTitle {
    font-size: 14px;
    color: #000;
    margin-bottom: 30px;
}

.templateW {
    float: left;
    margin-right: 60px;
    outline: none;
    margin-bottom: 20px;
}

.templateW-img {
    width: 120px;
    height: 125px;
    line-height: 125px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.templateW-img img {
    max-width: 100%;
    max-height: 100%;
}

.addTemplate {
    display: inline-block;
}

.product-img {
    max-height: 125px;
    width: 120px;
    display: inline-block;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.product-img:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.product-no-img {
    width: 120px;
    height: 120px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

ul,
li {
    padding: 0;
    margin: 0;
}

.templateName {
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    color: #232323;
}

.templateSize {
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
}

.moreTemplate {
    font-size: 12px;
    color: #A5A5A5;
    float: right;
}

.catalogItem {
    width: 100%;
    padding: 40px 0;
}

.catalogW {
    text-align: center;
    line-height: 50px;
    width: 260px;
    height: 50px;
    float: left;
    margin-right: 30px;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    font-size: 14px;
    color: #232323;
    position: relative;
    cursor: pointer;
    outline: none;
    border-radius: 5px 5px;
}

.catalogAdd {
    text-align: center;
    width: 100px;
    height: 50px;
    float: left;
    margin-right: 30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    font-size: 14px;
    color: #232323;
    cursor: pointer;
    outline: none;
}

.catalog {
    color: #fff;
    font-size: 9px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    outline: none;
    z-index: 200;
}

.catalogEnable {
    background: #264DF0;
}

.catalogUnable {
    background: #CACACA;
}

.catalogEditable {
    color: #fff;
    font-size: 9px;
    border-radius: 0 3px 3px 0;
    background: #FE5454;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    z-index: 200;
    outline: none;
}

.catalogDelete {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 200;
    height: 20px;
    line-height: 20px;
}

.pad-80 {
    padding-left: 80px !important;
}

.templateListItem {
    width: 90%;
}

.templateListW {
    width: 256px;
    height: 400px;
    padding: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
    float: left;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.templateListTitle {
    width: 100%;
    padding: 30px 0;
}

.templateListLeft {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    padding-right: 20px;
    float: left;
    width: auto;
}

.templateListLeft li {
    float: left;
    height: 55px;
    line-height: 55px;
    font-size: 14px;
    margin-left: 20px;
    outline: none;
    padding: 0 10px;
}

.templateListRight {
    width: 256px;
    height: 55px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    position: relative;
    float: right;
}

.width-70 {
    display: inline-block;
    width: 70px;
    height: 100%;
    outline: none;
    background: #FE5454;
    color: #fff;
    text-align: center;
    line-height: 55px;
    border-radius: 0 4px 4px 0;
}

#querySearch {
    font-size: 14px;
    width: 186px;
    height: 55px;
    background: none;
    box-shadow: none;
    outline: none;
    border: 1px solid #ddd;
}

.btn-right {
    position: absolute;
    left: 286px;
    top: 0;
}

.templateContent {
    width: 100%;
}

.templateC {
    width: 100%;
    height: 100%;
    position: relative;
}

.preview {
    position: absolute;
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 3px;
    background: #FE5454;
    color: #fff;
    top: 5px;
    right: 5px;
}

.add-label {
    position: absolute;
    width: 70px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 3px;
    background: #DEDEDE;
    color: #777;
    top: 5px;
    left: 5px;
    font-size: 12px;
    cursor: pointer;
}

.product-img2 {
    width: 100%;
    height: 230px;
    text-align: center;
    line-height: 230px;
}

.product-img2 img {
    max-width: 100%;
    max-height: 100%;
    zoom: 2;
}

.template_1 {
    width: 100%;
    margin: 20px 0 10px;
}

.operationList {
    cursor: pointer;
    position: relative;
}

.op {
    position: absolute;
    width: 100px;
    left: -25px;
    top: 20px;
    z-index: 200;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.op li {
    padding: 5px 0;
    text-align: center;
}

.labelList {
    width: 100%;
}

.templateS {
    width: 70%;
}

.templateV {
    width: 30%;
}

.templateS li {
    float: left;
    margin-right: 10px;
}

.outline-none {
    outline: none;
}

.col-2323 {
    color: #232323;
    font-size: 14px;
}

.col-2324 {
    color: #232323;
    font-size: 11px;
    opacity: 0.6;
}

/*预览显示*/
.slide-content {
    background-color: #fff;
}

.leftBtn,
.rightBtn,
.leftBtn-page,
.rightBtn-page {
    position: absolute;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    top: 45%;
    z-index: 999;
}

.rightBtn {
    right: -100px;
}

.leftBtn-page {
    left: 2%;
}

.rightBtn-page {
    right: 2%;
}

.leftBtn {
    display: none;
    left: -100px;
}

.bb-bg {
    min-height: 100%;
    background-size: cover;
    background-attachment: fixed;
}

.bb-custom-wrapper {
    position: relative;
    text-align: center;
    /*width   : 80vw;*/
}

.bb-bg {
    /*margin-top: 30vh;*/
    /*background-image: url("../images/view_bg.png");*/
    min-height: 100%;
    background-size: cover;
    background-attachment: fixed;
}

.opacity-zero {
    opacity: 0 !important;
    -ms-filter: alpha(opacity=0) !important;
    filter: alpha(opacity=0) !important;
}

.bb-disabled {
    pointer-events: none;
    cursor: default;
}

.bb-custom-side {
    width: 50%;
    float: left;
    height: 100%;
    overflow: hidden;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
}


.half-margin {
    margin-left: 50% !important;
}

.half-width {
    width: 50% !important;
}

ul li {
    list-style: none !important;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}

.logistice-0 {
    float: left;
    width: 148px;
    height: 60px;
    padding-top: 23px;
    list-style: none;
}

.logistice-1 {
    float: left;
    width: 148px;
    height: 60px;
    padding-top: 23px;
    list-style: none;
}

.page-loading {
    display: inline-block;
    margin: 10% 16%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px
}

.page-loading img {
    width: 20px;
    height: 20px
}

.page-loading span {
    color: #fff
}

.borderLine-top {
    border-top: 1px solid #EFEFEF;
    padding-top: 10px;
}

.mar-top-30 {
    margin-top: 30px !important;
}

.mar-top-50 {
    margin-top: 50px !important;
}

.view-article {
    margin: 0 auto;
    position: relative;
}

.save-page {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    width: 130px;
    /*display: none;*/
}

.save-page div {
    position: absolute;
    top: 42%;
    left: 42%;
}

.save-page img {
    width: 20px;
    margin: -1px 4px 0 0;
}

.save-page span {
    color: #fff;
}

.templateView-carousel {
    margin: 0 auto;
    position: relative;
}

.box-shadows {
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.slide-content {
    background-color: #fff;
}

.diy-box {
    position: absolute;
}

.diy-box input,
.diy-box textarea,
.diy-box .choose-img-box {
    border: none !important;
    background-color: transparent;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 0;
}

.diy-box input[type="text"],
.diy-box .choose-img-box {
    line-height: 12px;
    font-family: 'xujinglei', sans-serif;
    font-weight: bold;
    position: relative;
}

.diy-box input:active,
.diy-box textarea:active {
    outline: none;
}

.diy-box input[type='file'],
.diy-box .choose-img-box {
    opacity: 0;
    -ms-filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    left: 0;
}

.relative-box {
    width: 100%;
    height: 100%;
    position: relative;
}

.indexSet-800 {
    z-index: 800;
}

/** 文字 **/
.text-box {
    position: absolute;
}

.text-content {
    position: absolute;
    top: 0;
    left: 0;
}

.text-box .selected {
    border: 1px solid #F8E71C;
}

.selecteds {
    border: 1px solid #F8E71C;
}

.text-box p {
    width: 100%;
    height: 100%;
    word-break: break-all;
    margin: 0;
}

.text-box span,
.sticker-edit-box span {
    width: 20px;
    height: 20px;
    position: absolute;
}

.text-box img {
    width: 100%;
}

.half-width {
    width: 50% !important;
}

.half-margin {
    margin-left: 50% !important;
}

.quarter-margin {
    margin-left: 25% !important;
}

.hide1 {
    display: none !important;
}

.origin-image {
    transition: opacity .15s linear;
    min-width: 100vw;
    min-height: 103vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    text-align: center;
    display: table;
    overflow: auto;
}

.origin-wrap {
    margin: auto;
    vertical-align: middle;
    text-align: center;
    width: auto;
    height: auto;
    display: table-cell;
}

.origin-wrap img {
    max-width: 70vw;
    max-height: 88vh;
}

.width-maxd {
    width: 100%;
    height: auto;
}

.height-maxd {
    height: 100%;
    width: auto;
}

.clear-model {
    margin: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.barcode {
    position: absolute;
}

.opacity0 {
    background-color: #ffffff00 !important;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
}

.background {
    background-color: white;
}

.box-shadow {
    box-shadow: 0 2px 5px rgba(187, 187, 187, 1)
}

.box-shadow2 {
    box-shadow: 0px 0px 5px #D0D0D0;
}

/*end*/
.outline {
    outline: none;
}

.catalog_item {
    width: 100%;
    padding: 0 20px;
}

.catalog_W {
    text-align: center;
    line-height: 50px;
    width: 80px;
    height: 50px;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    font-size: 14px;
    color: #232323;
    position: relative;
    cursor: pointer;
    outline: none;
}

.bgcFF4747 {
    background-color: #FF4747
}

.cFF4747 {
    color: #FF4747;
}

.limitWidth {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-upload {
    width: 104px;
    height: 104px;
    opacity: 0;
    z-index: 100;
    position: relative;
    cursor: pointer;
}

.pic-upload {
    position: absolute;
    top: 0;
    z-index: 10;
}

.no-events {
    pointer-events: none !important;
}

.list-body-title {
    padding-left: 30px;
}

.list-body-title>span {
    width: 53px;
    height: 14px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 900;
    color: #000000;
    line-height: 39px;
}

.list-body-main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.list-body-main>div {
    margin-left: 60px;
}

.list-body-main>div>button {
    width: 106px;
    height: 28px;
    background: #FE5454;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 900;
    color: #FFFFFF;
}

.list-body-main-div-span1 {
    width: 83px;
    height: 12px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 39px;
}

.list-body-main-div-select {
    width: 90px;
    height: 25px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
}

.list-body-main-div-span1 {
    width: 18px;
    height: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 39px;
}

.no-events {
    pointer-events: none !important;
}

.checkbox-icon {
    width: 13px;
    height: 13px;
    margin-top: 10px;
    float: left;
}

.cut-img {
    position: absolute;
    /*left: 50vw;*/
    /*top: 50vh;*/
    /*transform: translate(-50%, -45%);*/
    left: 10vw;
    top: 14vw;
    z-index: 9999;
}

.creat-guanlian {
    position: absolute;
    left: 33%;
    top: 20%;
    z-index: 999
}

.page-head {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
    position: relative;
    /* overflow: hidden; */
    /*min-width: 1120px;*/
}

.page-head-icon {
    float: left;
}

.page-head-icon img {
    margin-left: 19px;
    height: 26px;
    width: 185px;
}

.page-head-title {
    /*position: absolute;*/
    /*left: 50%;*/
    /*text-align: center;*/
    /*transform: translate(-50%, 0);*/
    /*padding: 0 20px;*/
    float: left;
    margin-left: 40px;
}

.page-head-title div {
    outline: none;
    min-width: 30px;
}

.page-head-title:hover {
    background: #121212;
}

.page-head-save {
    float: right;
    height: 100%;
}

.page-head-save span {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}

.page-head-save img {
    margin-left: 4px;
}

.save-xml {
    padding: 0 6px;
}

.save-xml, .save-exit {
    text-align: center;
    width: 100px;
    color: #E1A03A;
    border-left: 2px solid #E1A03A;
}

.save-release {
    width: 200px;
    background: #FAC779;
    color: #232323;
}

/* ==========================================================================
左边侧边栏
========================================================================== */
.leftPanel {
    float: left;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.leftPanel-bg-head {
    position: relative;
    padding-left: 10px;
    text-align: left;
}

.leftPanel-bg-text {
    color: #9b9b9b;
    font-size: 12px;
    text-align: left;
    margin: 10px 10px 5px;
}

.leftPanel-bg-choose-color {
    cursor: pointer;
    width: 28px;
    padding: 0;
    height: 28px;
    border: 1px solid #ddd;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.leftPanel-bg-choose-mask {
    background: url('../../content/images/color_choose.png') no-repeat center -1px;
    background-size: 100% 120%;
    margin-right: 1px !important;
}

.leftPanel-bg-color {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    border: none;
    margin-right: 5px;
    padding: 0;
}

.leftPanels {
    height: calc(100vh - 50px);
    min-width: 60px;
    max-width: 300px;
    position: relative;
}

.leftPanel-leftPanelMenu {
    width: 60px;
    height: calc(100vh - 50px);
    background: #000;
    text-align: center;
    float: left;
    z-index: 2;
    position: relative;
    color: #9b9b9b;
    overflow: auto;
}

.leftPanel-leftPanelMenuList {
    padding-top: 38px !important;
    height: 60px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    outline: none;
    position: relative;
}

.leftPanel-leftPanelMenuList2 {
    position: relative;
    width: 100%;
    height: 60px;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.leftPanel-leftPanelMenuList2 i{
    font-size: 22px;
    margin: 0;
    color: #FAC779;
}

.leftPanel-border {
    height: 40px;
    width: 3px;
    background: #F7C475;
    position: absolute;
    left: 0;
    top: 10px;
    display: none;
    z-index: 400;
}

.leftPanel-bg {
    background: url("../images/icons/nav_bg.svg") no-repeat center 10px;
}

.leftPanel-bg:hover .leftPanel-bg div {
    display: block;
}

.leftPanel-bg:hover, .leftPanel-bg-active {
    background-color: #222;
    /*border-left: 3px solid #F7C475;*/
}

.leftPanel-bg-active div, .leftPanel-placement-active div, .leftPanel-mask-active div,
.leftPanel-material-active div, .leftPanel-font-active div, .leftPanel-bg-design-active div,
.leftPanel-bg-design-active div, .leftPanel-barcode-active div {
    display: block;
}

.leftPanel-bg-design:hover .leftPanel-bg-design div {
    display: block;
}

.leftPanel-bg-design {
    background: url("../images/icons/nav_design.svg") no-repeat center 10px;
}

.leftPanel-bg-design:hover, .leftPanel-bg-design-active {
    background-color: #222;
}

.leftPanel-bg-scene:hover .leftPanel-bg-scene div {
    display: block;
}

.leftPanel-bg-scene {
    background: url("../images/icons/nav_scene.svg") no-repeat center 10px;
}

.leftPanel-bg-scene:hover, .leftPanel-bg-scene-active {
    background-color: #222;
}

.leftPanel-placement:hover .leftPanel-placement div {
    display: block;
}

.leftPanel-placement {
    background: url("../images/icons/nav_photo.svg") no-repeat center 10px;
}

.leftPanel-placement:hover, .leftPanel-placement-active {
    background-color: #222;
}

.leftPanel-mask:hover .leftPanel-mask div {
    display: block;
}

.leftPanel-mask {
    background: url("../images/icons/nav_photo_frame.svg") no-repeat center 10px;
}

.leftPanel-mask:hover, .leftPanel-mask-active {
    background-color: #222;
}

.leftPanel-material {
    background: url("../images/icons/nav_material1.svg") no-repeat center 10px;
}

.leftPanel-material:hover .leftPanel-material div {
    display: block;
}

.leftPanel-material:hover, .leftPanel-material-active {
    background-color: #222;
}

.leftPanel-font {
    background: url("../images/icons/nav_font.svg") no-repeat center 10px;
}

.leftPanel-font:hover .leftPanel-font div {
    display: block;
}

.leftPanel-font:hover, .leftPanel-font-active {
    background-color: #222;
}

.leftPanel-barcode {
    background: url("../images/icons/nav_barcode.svg") no-repeat center 10px;
}

.leftPanel-barcode:hover .leftPanel-barcode div {
    display: block;
}

.leftPanel-barcode:hover, .leftPanel-barcode-active {
    background-color: #222;
}

.leftPanel-photo {
    background: url("../images/icons/nav_photo.svg") no-repeat center 10px;
}

.leftPanel-photo:hover .leftPanel-photo div {
    display: block;
}

.leftPanel-photo:hover, .leftPanel-photo-active {
    background-color: #222;
}

.leftPanel-shezhi {
    outline: none;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 60px;
    color: #9B9B9B;
    background: url("../images/icons/shezhi.svg") no-repeat center 10px;
}

.leftPanel-shezhi:hover, .leftPanel-shezhi-active {
    cursor: pointer;
    outline: none;
    background: #191919 url("../images/icons/shezhi.svg") no-repeat center 10px
}

.shezhi-tab {
    width: 120px;
    left: 60px;
    position: fixed;
    /*bottom: 10px;*/
    bottom: -20px;
    height: 80px;
    z-index: 999;
    line-height: 40px;
    text-align: center;
}

.shezhi-tab span {
    padding-left: 20px;
    display: inline-block;
}

.shezhi-tab li {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    background: #191919;
    color: #9B9B9B;
    outline: none;
}

.shezhi-tab li:first-child {
    display: none;
    background: #191919 url("../images/icons/new_guide.svg") no-repeat 16px center;
}

.shezhi-tab li:first-child:hover {
    color: #fe5454;
    background: #000 url("../images/icons/new_guide_pre.svg") no-repeat 16px center;
}

.shezhi-tab li:last-child {
    background: #191919 url("../images/icons/color_change.svg") no-repeat 16px center;
}

.shezhi-tab li:last-child:hover {
    color: #fe5454;
    background: #000 url("../images/icons/color_change_pre.svg") no-repeat 16px center;
}

.dark-theme {
    background: #4c4c4c !important;
    box-shadow: 0 2px 5px rgba(12, 12, 12, 0.6) !important;
}

.dark-theme-con {
    background: #282828 !important;
}

.dark-theme-color {
    color: #fff !important;
}

.dark-theme-btn {
    background: #fe5454 !important;
    color: #fff !important;
}

.dark-theme-shadow {
    box-shadow: none !important;
}

.dark-theme-shadow2 {
    box-shadow: 0 2px 5px rgba(12, 12, 12, 0.6) !important;
}

.leftPanel-mainContent {
    float: left;
    width: 240px;
    height: calc(100vh - 50px);
    text-align: center;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
    /*animation: .5s .4s searchAnimation ease forwards;*/
    position: relative;
    background: #f8f8f8;
    transition: left 0.3s linear;
}

.leftPanel-main-wrap {
    width: 240px;
    height: calc(100vh - 50px);
    position: relative;
    background: #f8f8f8;
    z-index: 2;
    overflow: auto;
}

.leftPanel-mainContent-toggle, .rightPanel-mainContent-toggle {
    width: 18px;
    height: 60px;
    /*top: 50%;*/
    transform: translateY(-50%);
    transition: .1s linear;
    cursor: pointer;
    z-index: 1;
    outline: none;
    background: #FAC779;
    border-radius: 0 5px 5px 0;
}

.leftPanel-mainContent-toggle {
    position: absolute;
    right: -7px;
    top: 44%;
    /*background: url(../images/icons/pop_left.png) no-repeat;*/
}

.leftPanel-mainContent-toggle:hover {
    right: -18px;
}

.rightPanel-mainContent-toggle {
    position: absolute;
    /*right: 13.51%;*/
    /*position: absolute;*/
    right: 190px;
    top: 47%;
    background: #FAC779;
    border-radius: 5px 0 0 5px;
    /*background: url(../images/icons/pop_right.png) no-repeat;*/
}

.rightPanel-mainContent-toggle:hover {
    right: 200px;
}

.rightPanel-toggle {
    right: -12px !important;
}

.rightPanel-toggle:hover {
    right: 0 !important;
}

.bottom-100 {
    width: 100% !important;
}

.page-scale-52 {
    left: 52% !important;
}

.page-scale-46 {
    left: 46% !important;
}

.page-scale-39 {
    left: 39% !important;
}

.leftPanel-con {
    border-top: 2px solid #e6e6e6;
    position: relative;
}

.leftPanel-con-mask, .clipart-con {
    height: calc(100vh - 220px);
}

.leftPanel-con-mask2, .clipart-con2, .productTemplate-con {
    height: calc(100vh - 60px) !important;
}

.backup-con {
    height: calc(100vh - 60px);
}

.load-more {
    box-shadow: 0px 0px 5px rgba(35, 35, 35, 0.3);
    position: fixed;
    left: 70px;
    bottom: 10px;
    width: 220px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #f8f8f8;
    color: #9b9b9b;
    font-size: 12px;
    z-index: 101;
}

.leftPanel-con ul {
    padding: 20px 5px;
}

.leftPanel-con li {
    float: left;
    width: 100px;
    /*height: 100px;*/
    /*line-height: 100px;*/
    /*overflow: hidden;*/
    margin-bottom: 10px;
    /*position: absolute;*/
    margin-left: 10px;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.left-panel-hover:hover .leftPanel-del, .left-panel-hover:hover .leftPanel-recommend {
    display: block;
}

.font-list {
    width: 200px;
    background: #fff;
    height: 34px;
    line-height: 34px;
    margin-left: 20px;
    margin-top: 10px;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
    cursor: pointer;
    outline: none;
    overflow: hidden;
}

.font-list div {
    outline: none;
}

.leftPanel-add2 {
    background: #fff8ee;
    border-radius: 50px;
    width: 220px;
    height: 36px;
    color: #E1A03A;
    margin: 6px auto 14px;
    text-align: center;
    border: 1px solid #E1A03A;
    position: relative;
}

.leftPanel-add {
    background: #fff8ee;
    border-radius: 50px;
    width: 220px;
    height: 36px;
    color: #E1A03A;
    margin: 16px auto 14px;
    text-align: center;
    border: 1px solid #E1A03A;
    position: relative;
}

.leftPanel-nav {
    height: 36px;
    line-height: 34px;
    width: 220px;
    margin: 0 auto 20px;
    text-align: center;
    border-radius: 50px;
    border: 1px solid #E1A03A;
    font-size: 12px;
    cursor: pointer;
    outline: none;
}

.leftPanel-nav div {
    float: left;
    color: #999;
    width: 50%;
    outline: none;
}

.leftPanel-nav div:first-child {
    float: left;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
}

.leftPanel-nav div:last-child {
    float: left;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}

.leftPanel-nav-active {
    background: #264DF0 !important;
    color: #FFFFFF !important;
}

.add-pic {
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -ms-filter: alpha(opacity=0);
    filter: alpha(opacity=0)
}

.leftPanel-add img {
    margin-top: -4px;
    border: none;
}

.change-label {
    position: absolute;
    right: 10px;
    top: 20px;
}

.leftPanel-tip {
    text-align: center;
    color: #979797;
    font-size: 12px;
    margin-bottom: 10px;
}

.page-del, .bg-del, .leftPanel-del, .leftPanel-recommend {
    position: absolute;
    top: 0px;
    right: 0;
    border: none;
    background: none;
    display: none;
    z-index: 100;
}

.leftPanel-del div {
    width: 30px;
    height: 30px;
    background: #000;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    border-bottom-left-radius: 10px;
}

.leftPanel-del img {
    position: absolute;
    z-index: 101;
    top: 5px;
    right: 5px;
}

.leftPanel-recommend {
    width: 30px;
    height: 30px;
    background: #000;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    border-bottom-right-radius: 10px;
}

.leftPanel-recommend img {
    position: absolute;
    z-index: 101;
    top: 5px;
    left: 5px;
}

.leftPanel-privacy {
    margin-top: 10px;
}

.leftPanel-privacy button {
    height: 30px;
    font-size: 12px;
    border: none;
    background: none;
    min-width: 70px;
    margin-left: 3px;
    color: #979797;
    background: #fff;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.leftPanel-privacy2 {
    margin-top: 10px;
}

.leftPanel-privacy2 button {
    height: 30px;
    font-size: 12px;
    border: none;
    background: none;
    color: #979797;
    background: #fff;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
    width: 45%;
    margin-right: 2%;
}

.button-sel {
    background: #fe5454 !important;
    color: #fff !important;
}


/* ==========================================================================
右边部分
========================================================================== */
.rightPanel {
    float: left;
    position: absolute;
    left: 300px;
    top: 0px;
    background: #ececec;
    transition: left .3s;
    /*width: calc(100vw - 294px);*/
    width: calc(100% - 500px);
    height: calc(100vh - 50px);
}

.rightPanel-2 {
    float: left;
    position: absolute;
    right: 0px;
    top: 0px;
    background: #ececec;
    transition: left .3s;
    /*width: calc(100vw - 294px);*/
    width: 200px;
    height: calc(100vh - 50px);
    /*min-width: 250px;*/

}

.rightPanel-big1 {
    left: 60px !important;
    width: calc(100vw - 60px) !important;
}

.rightPanel-big2 {
    left: 300px !important;
    width: calc(100vw - 300px) !important;
}

.rightPanel-big3 {
    left: 60px !important;
    width: calc(100vw - 261px) !important;
}

.home-page {
    position: relative;
    overflow: hidden;
}

.rightPanel-bottom {
    position: absolute;
    bottom: 0;
}

.page-top {
    height: 120px;
    background: #fff;
    position: relative;
}

.inputColor {
    /*border-color: #b9b9b9;*/
    border-radius: 5px !important;
    padding: 0 !important;
    width: 30px;
    height: 33px;
    border: none;
    background: none;
}

button {
    outline: none;
}

.nav_spacing {
    width: 40px !important;
    position: relative;
    outline: none;
    /*background: url("../images/icons/tools_bar_spacing.svg") no-repeat center;*/
}

.nav-spacing-active {
    /*background: url("../images/icons/tools_bar_spacing_pre.svg") no-repeat center;*/
}

.nav_spacing img {
    margin-top: -12px;
}

.space-slider {
    position: absolute;
    width: 278px;
    height: 92px;
    left: -58px;
    top: 33px;
    padding-top: 12px;
    /*box-shadow: 0 1px 3px rgba(0, 0, 0, .4);*/
    cursor: default;
    z-index: 1000;
    /*border-radius: 4px;*/
    /*background: #fff;*/
    background: url("../../content/images/icons/slider_space.svg") no-repeat 0 0;
}

.transparency-slider, .coverage-slider {
    position: absolute;
    width: 286px;
    height: 66px;
    left: -59px;
    top: 32px;
    line-height: 68px;
    /*box-shadow: 0 1px 3px rgba(0, 0, 0, .4);*/
    cursor: pointer;
    z-index: 1000;
    border-radius: 4px;
    background: url("../../content/images/icons/transparency.svg") no-repeat center;
}

.slider-rela {
    position: relative;
    z-index: 1;
}

.coverage-slider {
    width: 180px;
    height: 62px;
    left: -70px;
    background: url("../../content/images/icons/coverage.svg") no-repeat center;
}

.coverage-slider ul {
    padding: 0 7px;
    margin: 0;
    height: 62px !important;
    line-height: 62px !important;
}

.coverage-slider li {
    cursor: pointer;
    float: left;
    width: 39px;
    height: 20px;
    line-height: 62px;
    margin-top: 24px;
    position: relative;
}

.coverage-slider li:hover {
    /*color: #fe5454;*/
    /*background: #e6e6e6;*/
    cursor: pointer;
}

.coverage li:first-child {
    background: url("../images/icons/coverage_move_up.svg") no-repeat center;
}

.coverage li:first-child:hover {
    background: url("../images/icons/coverage_move_up_pre.svg") no-repeat center;
}

.coverage li:nth-child(2) {
    background: url("../images/icons/coverage_move_down.svg") no-repeat center;
}

.coverage li:nth-child(2):hover {
    background: url("../images/icons/coverage_move_down_pre.svg") no-repeat center;
}

.coverage li:nth-child(3) {
    background: url("../images/icons/coverage_stick.svg") no-repeat center;
}

.coverage li:nth-child(3):hover {
    background: url("../images/icons/coverage_stick_pre.svg") no-repeat center;
}

.coverage li:last-child {
    background: url("../images/icons/coverage_bottom.svg") no-repeat center;
}

.coverage li:last-child:hover {
    background: url("../images/icons/coverage_bottom_pre.svg") no-repeat center;
}

.operation li {
    cursor: pointer;
    float: left;
    width: 31px;
    height: 20px;
    line-height: 62px;
    margin-top: 24px !important;
    position: relative;
}

.operation li:first-child {
    background: url("../images/icons/tools_bar_left_justifying.svg") no-repeat center;
}

.operation li:first-child:hover {
    background: url("../images/icons/tools_bar_left_justifying_Selected.svg") no-repeat center;
}

.operation li:nth-child(2) {
    background: url("../images/icons/tools_bar_align_center.svg") no-repeat center;
}

.operation li:nth-child(2):hover {
    background: url("../images/icons/tools_bar_align_center_Selected.svg") no-repeat center;
}

.operation li:nth-child(3) {
    background: url("../images/icons/tools_bar_align_right.svg") no-repeat center;
}

.operation li:nth-child(3):hover {
    background: url("../images/icons/tools_bar_align_right_Selected.svg") no-repeat center;
}

.operation li:last-child {
    background: url("../images/icons/tools_bar_justify_align.svg") no-repeat center;
}

.operation li:last-child:hover {
    background: url("../images/icons/tools_bar_justify_align_Selected.svg") no-repeat center;
}

.slider {
    position: relative;
    /*width: 260px;*/
    height: 40px;
    z-index: 1;
    cursor: default;
    /*background: url("../../content/images/icons/transparency.svg") no-repeat 0 0;*/
}

.slider-label {
    position: absolute;
    left: 16px;
}

.slider-area {
    position: absolute;
    left: 67px;
    width: 120px;
    height: 40px;
}

.slider-input {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 50px;
    height: 30px;
    padding-left: 10px;
    border: 1px solid #e6e6e6;
    background-color: #f4f4f4;
}

.slider-back {
    position: absolute;
    top: 22px;
    width: 0;
    height: 0;
    border-left: 120px solid transparent;
    border-bottom: 5px solid #515151;
    cursor: pointer;
}

.slider-block {
    position: absolute;
    top: 18px;
    left: 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #515151;
    cursor: pointer;
}

/* 去除webkit中input的type="number"时出现的上下图标 */
.slider-input::-webkit-outer-spin-button,
.slider-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.slider-input[type="number"] {
    -moz-appearance: textfield;
}

.page-input::-webkit-outer-spin-button,
.page-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.page-input[type="number"] {
    -moz-appearance: textfield;
}

.nav-alignment, .nav-distribution,.nav-center {
    margin-top: 4px;
    outline: none;
    display: inline-block;
    width: 40px !important;
    height: 30px !important;
    padding: 0;
    background: url("../images/icons/tools_bar_alignment.svg") no-repeat center 10px;
}

.dark-theme-navali {
    background: url("../images/icons/tools_bar_alignment_dark.svg") no-repeat center 10px !important;
}

.nav-alignment:hover, .nav-alig-avtive {
    background: url("../images/icons/tools_bar_alignment_pre.svg") no-repeat center 10px;
}

.nav-distribution {
    background: url("../images/icons/tools_bar_distribution.svg") no-repeat center 10px;
}

.nav-center {
    background: url("../images/icons/tools_bar_center.svg") no-repeat center 10px !important;
}

.dark-theme-navdis {
    background: url("../images/icons/tools_bar_distribution_dark.svg") no-repeat center 10px !important;
}

.nav-distribution:hover, .nav-distri-active {
    background: url("../images/icons/tools_bar_distribution_pre.svg") no-repeat center 10px;
}

.tool-bar-alignment, .tool-bar-distribution {
    position: absolute;
    top: 40px;
    height: 62px !important;
    line-height: 62px !important;
    z-index: 999;
}

.tool-bar-alignment {
    width: 250px;
    left: -6px;
    background: url("../images/icons/tool-bar-alignment.svg") no-repeat center;
}

.tool-bar-distribution {
    width: 102px;
    left: -32px;
    background: url("../images/icons/tool-bar-distribution.svg") no-repeat center;
}

.tool-bar-alignment ul, .tool-bar-distribution ul {
    padding: 0 7px;
    margin: 0;
    height: 62px !important;
    line-height: 62px !important;
}

.tool-bar-alignment li, .tool-bar-distribution li {
    cursor: pointer;
    float: left;
    width: 39px;
    height: 20px;
    line-height: 62px;
    margin-top: 24px;
    position: relative;
}

.tool-bar-alignment li:hover, .tool-bar-distribution li:hover {
    /*color: #fe5454;*/
    /*background: #e6e6e6;*/
    cursor: pointer;
}

.tool-bar-alignment li:first-child {
    background: url("../images/icons/tools_bar_alignment.svg") no-repeat center;
}

.tool-bar-alignment li:first-child:hover {
    background: url("../images/icons/tools_bar_alignment_pre.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(2) {
    background: url("../images/icons/tools_bar_rightalign.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(2):hover {
    background: url("../images/icons/tools_bar_rightalign_pre.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(3) {
    background: url("../images/icons/tools_bar_topalign.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(3):hover {
    background: url("../images/icons/tools_bar_topalign_pre.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(4) {
    background: url("../images/icons/tools_bar_btmalign.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(4):hover {
    background: url("../images/icons/tools_bar_btmalign_pre.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(5) {
    background: url("../images/icons/tools_bar_verticalalign.svg") no-repeat center;
}

.tool-bar-alignment li:nth-child(5):hover {
    background: url("../images/icons/tools_bar_verticalalign_pre.svg") no-repeat center;
}

.tool-bar-alignment li:last-child {
    background: url("../images/icons/tools_bar_levelalign.svg") no-repeat center;
}

.tool-bar-alignment li:last-child:hover {
    background: url("../images/icons/tools_bar_levelalign_pre.svg") no-repeat center;
}

.tool-bar-distribution li:first-child {
    background: url("../images/icons/tools_bar_level.svg") no-repeat center;
}

.tool-bar-distribution li:first-child:hover {
    background: url("../images/icons/tools_bar_level_pre.svg") no-repeat center;
}

.tool-bar-distribution li:last-child {
    background: url("../images/icons/tools_bar_vertical.svg") no-repeat center;
}

.tool-bar-distribution li:last-child:hover {
    background: url("../images/icons/tools_bar_vertical_pre.svg") no-repeat center;
}

.tool-bar-alignment span, .tool-bar-distribution span {
    margin-left: 28px;
    padding: 0 16px;
}

.barcode {
    /*border: 1px dashed #ff6c5f;*/
    position: absolute;
}

.barcode img {
    width: 100%;
    height:100%;
}

.nav-active {
    border: 2px solid #E1A03A !important;
}

.page-nav {
    padding: 10px 20px 0 20px;
    /*height: 100px;*/
    /*background: #fff;*/
    white-space: nowrap;

    overflow: hidden;
}

.nav-ul {
    /*width: 100%;*/
    white-space: nowrap;
    overflow-x: auto;
}

.sort-ul {
    min-width: 100%;
}

.nav-ul::-webkit-scrollbar {
    width: 8px !important;
}

/* 这是针对缺省样式 (必须的) */
.nav-ul::-webkit-scrollbar-track {
    background-color: #fff;
}

/* 滚动条的滑轨背景颜色 */

.nav-ul::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 20px;
    border: 5px solid #fff;
}

/* 滑块颜色 */

.nav-ul::-webkit-scrollbar-button {
    background-color: #fff;
    display: none;
}

/* 滑轨两头的监听按钮颜色 */

.nav-ul::-webkit-scrollbar-corner {
    background-color: black;
}
.template-edit::-webkit-scrollbar {
    width: 8px !important;
}

/* 这是针对缺省样式 (必须的) */
.template-edit::-webkit-scrollbar-track {
    background-color: #fff;
}

/* 滚动条的滑轨背景颜色 */
.template-edit::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 20px;
}

/* 滑块颜色 */
.template-edit::-webkit-scrollbar-button {
    background-color: #fff;
    display: none;
}

/* 滑轨两头的监听按钮颜色 */
.template-edit::-webkit-scrollbar-corner {
    background-color: black;
}

.add-page {
    position: absolute;
    right: 10px;
    outline: none;
    width: 42px;
    z-index: 999;
}

/* 横向滚动条和纵向滚动条相交处尖角的颜色 */
ol {
    padding: 0;
    margin: 4px 0 0 0;
}

.page-nav ul {
    padding: 0;
    margin: 0;
    height: 100px;
    width: fit-content;
    display: flex;
    padding-right: 50px !important;
}

.page-nav li {
    position: relative;
    margin: 0 14px 0 0;
}

.page-nav-box {
    position: relative;
    height: 100px;
    margin-bottom: 2px;
    border: 1px solid #dfdfdf;
    overflow: hidden;
}

.page-nav-box div {
    /*overflow: hidden;*/
    /*margin-top: 3px;*/
}

.page-cover{
    background: rgba(39, 39, 39, 0.5);
    color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    height: 25px;
    line-height: 25px;
    z-index: 599;
}

.page-nav-box:hover .page-del {
    display: block;
}

.page-del svg {
    color: #fe5454;
    margin-right: 0.2em;
}

.add-all {
    /*width: 70%;*/
    overflow: hidden;
    top: 0 !important;
    transition: .3s;
}

.nav-bg {
    background: #fff;
    height: 42px;
    line-height: 42px;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
    /*overflow: hidden;*/
}

.tip, .tip2, .tip3, .tip4, .tip5 {
    width: 44px;
    height: 30px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    background: #fff;
    font-size: 12px;
    position: absolute;
    z-index: 900;
    left: -8px;
    top: 42px;
    background: url("../images/icons/tooltip2.svg") no-repeat top left;
    display: none;
}

.tip3 {
    width: 56px;
    height: 30px;
    line-height: 32px;
    background: url("../images/icons/tooltip3.svg") no-repeat top left;
}

.tip4 {
    width: 70px;
    height: 32px;
    line-height: 34px;
    left: -21px;
    background: url("../images/icons/tooltip4.svg") no-repeat top left;
}

.tip6 {
    text-align: center;
    color: #fff;
    background: #fff;
    font-size: 12px;
    position: absolute;
    z-index: 900;
    width: 70px;
    height: 32px;
    line-height: 34px;
    left: -17px;
    top: 40px;
    background: url("../images/icons/click_lock.svg") no-repeat top left;
}

.rollback-undo:hover div, .rollback-redo:hover div, .nav-coverage:hover .tip5, .nav-lock:hover div, .nav-delete:hover div, .nav-copy:hover div,
.nav-alignment:hover .tip, .nav-alignment li:hover .tip3, .nav-alignment li:hover .tip4, .nav-distribution:hover .tip, .nav-distribution li:hover .tip4,
.lihover:hover .tip4, .coverage li:hover .tip, .coverage li:hover .tip4, .nav-operation li:hover .tip, .operation li:hover .tip3, .operation li:hover .tip4 {
    display: block;
}

.tool-bar-btn {
    width: 40px;
    height: 30px;
    margin: 7px 6px;
    background: #f0f3f4;
    position: relative;
    line-height: 27px;
    border-radius: 5px;
}

/*.rollback-undo:hover {*/
/*background: url("../images/icons/tools_bar_revocation_pre.svg") no-repeat center;*/
/*}*/

/*.rollback-redo {*/
/*width: 16px;*/
/*height: 20px;*/
/*background: url("../images/icons/tools_bar_recover.svg") no-repeat center;*/
/*}*/

/*.rollback-redo:hover {*/
/*background: url("../images/icons/tools_bar_recover_pre.svg") no-repeat center;*/
/*}*/

.text-range {
    display: inline-block !important;
    -webkit-appearance: none;
    width: 140px !important;
    padding: 2px !important;
    height: 2px;
    border-radius: 5px;
    margin: 18px auto;
    outline: 0;
    flex: 1;
    padding:2px 0 !important;
    /*background: -webkit-linear-gradient(#F0951B, #F0951B) no-repeat, #d8d8d8;*/
    background-size: 30% 100%; /*设置左右宽度比例*/
    background-color: #C8C8C8;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none; /*清除系统默认样式*/
    height: 15px; /*拖动块高度*/
    width: 15px; /*拖动块宽度*/
    background: #4D4D4D; /*拖动块背景*/
    border-radius: 50%; /*外观设置为圆形*/
    border: solid 2px #fff; /*设置边框*/
}

.transparency-num {
    text-align: center;
    padding: 0;
    width: 34px;
    border-radius: 4px;
    right: 25px;
    top: 20px;
}

.page-menu h3 {
    height: 40px;
    line-height: 40px;
}

.page-menu h3, .img-menu h3, .text-menu h3, .art-menu h3 {
    padding: 0;
    margin: 0;
    height: 44px;
    line-height: 40px;
    font-weight: normal;
    font-size: 16px;
    border-bottom: 2px solid #f0f2f7;
}

.top-img {
    position: absolute;
    top: 0;
    left: 0
}

.imgBoxs img {
    position: absolute;
}

.page-right {
    position: absolute;
    right: 0;
    bottom: 50px;
    z-index: 999;
}

.page-right button {
    display: block;
    margin-top: 6px;
    width: 40px;
    height: 26px;
    border: none;
    background: #bcbcbc;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    /*padding: 0;*/
    padding-left: 10px;
}

.page-right span {
    color: #3e3e3e;
    font-size: 11px;
}

.add-pic {
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -ms-filter: alpha(opacity=0);
    filter: alpha(opacity=0)
}

.con-bg::-webkit-scrollbar, .con-mask::-webkit-scrollbar {
    width: 8px !important;
}

/* 这是针对缺省样式 (必须的) */
.con-bg::-webkit-scrollbar-track, .con-mask::-webkit-scrollbar-track {
    background-color: #fff;
}

/* 滚动条的滑轨背景颜色 */

.con-bg::-webkit-scrollbar-thumb, .con-mask::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 20px;
    border: 5px solid #fff;
}

/* 滑块颜色 */

.con-bg::-webkit-scrollbar-button, .con-mask::-webkit-scrollbar-button {
    background-color: #fff;
    display: none;
}

/* 滑轨两头的监听按钮颜色 */

.con-bg::-webkit-scrollbar-corner, .con-mask::-webkit-scrollbar-corner {
    background-color: black;
}

/* 横向滚动条和纵向滚动条相交处尖角的颜色 */

.content-scroll {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
}

.content-scroll::-webkit-scrollbar, .scrollbar::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
}

/* 这是针对缺省样式 (必须的) */
.content-scroll::-webkit-scrollbar-track, .scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
}

/* 滚动条的滑轨背景颜色 */

.content-scroll::-webkit-scrollbar-thumb, .scrollbar::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 20px;
    border: 2px solid #fff;
}

/* 滑块颜色 */

.content-scroll::-webkit-scrollbar-button, .scrollbar::-webkit-scrollbar-button {
    display: none;
    background-color: #fff;
}

/* 滑轨两头的监听按钮颜色 */

.content-scroll::-webkit-scrollbar-corner, .scrollbar::-webkit-scrollbar-corner {
    background-color: #fff;
}

.home-page nav.navbar-nav > li {
    display: inline-block;
    margin-right: 16px;
}

.home-page .navbar-right {
    float: right;
    display: inline-block;
}

.home-page .menu-right.btn {
    position: relative;
    float: right;
    right: 1em;
    top: 5px;
}

.home-page .menu-margin.btn {
    margin-right: 14px;
}

.border-right {
    display: inline-block;
    width: 1px;
    height: 42px;
    border-right: 1px solid #e6e6e6;
    position: absolute;
}

.select-text {
    outline: none;
    border: none;
    width: 100px;
    display: inline-block;
    text-align: center;
    text-align-last: center;
    /*height: 34px;*/
    padding: 6px 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    cursor: pointer;
}

.select-text:hover {
    /*border: 1px solid #ccc;*/
    /*border-radius: 4px;*/
}

.select-text-dark {
    background-color: transparent;
    color: #fff
}

.button-text {
    width: 30px;
    border-radius: 5px;
}

.button-B {
    /*border-left: none;*/
    margin: 0 10px;
    background: url("../images/icons/tools_bar_bold.svg") no-repeat center 10px;
}

.button-B-dark {
    background: url("../images/icons/tools_bar_bold_dark.svg") no-repeat center 10px;
}

.button-I {
    font-family: Verdana, Arial, sans-serif;
    background: url("../images/icons/tools_bar_italic.svg") no-repeat center 10px;
}

.button-I-dark {
    background: url("../images/icons/tools_bar_italic_dark.svg") no-repeat center 10px;
}

.button-B, .button-I {
    border: none;
    outline: none;
    height: 31px;
}

.buttonB {
    background: url("../images/icons/tools_bar_bold_pre.svg") no-repeat center 10px;
}

.buttonI {
    background: url("../images/icons/tools_bar_italic_pre.svg") no-repeat center 10px;
}

.rightPanel-btn {
    box-shadow: 0px 0px 5px rgba(35, 35, 35, 0.3);
    border-radius: 50px;
}

.button-b, .button-i {
    border: none;
    outline: none;
    height: 32px;
    /*padding: 0 10px !important;*/
    color: #232323;
    background-color: #fff;
}

.button-b {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.button-i {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.buttonA {
    background-color: #FAC779;
    font-weight: bold;
}

.button-un {
    cursor: no-drop;
    disabled: true;
}

.cursorPointer {
    cursor: pointer !important;
}

.cursor-no {
    cursor: no-drop !important;
}

.input-wrapper {
    margin: 45vh auto 30px;
    max-width: 300px;
    height: 34px;
    border: 1px dashed #F0951B;
}

input {
    width: 100%;
    height: 100%;
    line-height: 22px;
    /*color: white;*/
    padding: 6px;
    word-break: break-all;
}

.input-wrapper input, .input-wrapper input:hover, .input-wrapper input:focus, .input-wrapper input:active {
    background-color: transparent;
    outline: none;
    border: none;
}

.btn-w-8 {
    width: 66.66vw;
}

.home-page .navbar {
    margin-bottom: 0;
}

.home-page .navbar-collapse {
    position: relative;
    top: 6px;
}

.home-page .navbar-collapse li {
    margin-right: 14px;
}

.home-page .container-fluid {
    max-width: 1200px;
}

.template-display, .template-edit {
    /*border: 1px solid lightgray;*/
    border-top: 0;
    height: calc(100% - 232px);
    /*overflow: auto;*/
}

.template-edit {
    /*height: calc(100vh - 170px);*/
    height: calc(100vh - 50px);
    background: #fff;
    text-align: center;
    z-index: 2;
    position: relative;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
    overflow-y: auto;
}

.rightPanel-title {
    font-size: 12px;
    color: #A6A6A6;
    text-align: left;
    margin-top: 20px;
}

.no-appearance {
    -moz-appearance: textfield;
    padding: 0 !important;
    text-align: center;
    border: none;
    background: rgba(255, 255, 255, 0);
    outline: none;
}

.no-appearance::-webkit-outer-spin-button,
.no-appearance::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input-line {
    height: 20px;
    width: 1px;
    background: #DADADA;
    position: absolute;
    top: 8px;
    left: 0;
}

.rightPanel-input::-webkit-outer-spin-button,
.rightPanel-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.rightPanel-input {
    -moz-appearance: textfield;
    padding: 0 !important;
    text-align: center;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 0px 5px rgba(35, 35, 35, 0.3);
    border-radius: 50px;
}

.color-A6 {
    color: #A6A6A6;
    font-size: 12px;
}

.home-page .template-display {
    /*background-color: lightgray;*/
}

.home-page .input-group {
    margin: 1em 0;
    width: 100%;
}

.home-page .page-menu .input-group-addon:first-child {
    width: 120px;
}

.home-page .page-item, .home-page .page-item1{
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.home-page .page-item.selected, .home-page .page-item1.selected{
    border: 1px dashed #ff6d60;
}

.home-page .content-container {
    width: 560px;
    position: relative;
    display: block;
    /*overflow: hidden;*/
}

.home-page .content-container2 {
    width: 560px;
    position: relative;
    display: block;
    overflow: hidden;
}

.home-page .body-content {
    /*border-top: 1px solid #e0e0e0;*/
    padding: 0;
    position: relative;
    overflow: hidden;
    height: calc(100vh - 212px);
}

/*.ctrl-mask{*/
/*border: 1px dashed #121212;*/
/*position: absolute;*/
/*z-index: 9999;*/
/*}*/

.home-page .img-place-holder {
    background-color: transparent;
    position: absolute;
    z-index: 998;
    border: 1px solid lightsteelblue;
}

.home-page .mask-place-holder {
    background-color: transparent;
    position: absolute;
    z-index: 999;
    border: 1px solid lightsteelblue;
}

.home-page .mask-place-holder.selected {
    border: 1px dashed #F0951B;
}

.home-page .text-box {
    position: absolute;
}

.textContext {
    opacity: 0;
    -ms-filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    min-width: 100px;
    min-height: 24px;
    padding: 0 4px;
    transform-origin: left bottom;
    position: absolute;
}

.home-page .text-box:hover {
    cursor: text;
}

.textHolder {
    color: #757575 !important;
    font-weight: normal !important;
    font-style: normal !important;
}

.home-page .textHolder:empty:before {
    content: attr(placeholder);
    color: #757575;
}

/*焦点时内容为空*/
.home-page .textHolder:focus:before {
    content: none;
}

.home-page .text-box.selected, .home-page .selected {
    outline: 2px dashed #ffb741 !important;
}

.sel-top, .sel-right, .sel-bottom, .sel-left, .sel-top1, .sel-right1, .sel-bottom1, .sel-left1 {
    display: inline-block;
    position: absolute;
    width: 14px;
    height: 14px;
    outline: none;
    z-index: 1;
}

.sel-top {
    background: url("../images/icons/ic_leftup.svg") no-repeat;
    left: -7px;
    top: -7px;
    cursor: se-resize;
}

.sel-top1 {
    background: url("../images/icons/ic_top1.svg") no-repeat;
    left: 50%;
    top: -7px;
    margin-left: -7px;
    cursor: n-resize;
}

.sel-right {
    background: url("../images/icons/ic_rightup.svg") no-repeat;
    right: -7px;
    top: -7px;
    cursor: sw-resize;
}

.sel-right1 {
    background: url("../images/icons/ic_right.svg") no-repeat;
    right: -14px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize;
}

.sel-bottom {
    background: url("../images/icons/ic_rightbelow.svg") no-repeat;
    right: -7px;
    bottom: -7px;
    cursor: se-resize;
}

.sel-bottom1 {
    background: url("../images/icons/ic_bottom1.svg") no-repeat;
    left: 50%;
    margin-left: -7px;
    z-index: 1;
    cursor: n-resize;
}

.sel-left {
    background: url("../images/icons/ic_leftbelow.svg") no-repeat;
    left: -7px;
    bottom: -7px;
    cursor: sw-resize;
}

.sel-left1 {
    background: url("../images/icons/ic_left.svg") no-repeat;
    left: -7px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize;
}

.c-xml-page-material-rotate1 {
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 1px;
    height: 20px;
    background: #264DF0;
}

.rotate-box {
    position: absolute;
    text-align: center;
    outline: none;
}

.rotate-line {
    display: inline-block;
    width: 1px;
    height: 20px;
    margin-left: 14px;
    border-left: 1px dashed #264DF0;
}

.rotate-icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    outline: none;
    margin: -4px 0 0 14px;
    background: url("../images/icons/rotate_gray.svg") no-repeat;
}

.rotate-icon:hover, .rotate-icon-active {
    /* background: url("../images/icons/copy.svg") no-repeat; */
    /*left: -7px;*/
    /*bottom: -7px;*/
}

textarea {
    padding: 0;
}

.home-page .text-content {
    /*background-color: transparent;*/
    box-shadow: none;
    border: 0;
    outline: none;
    overflow: hidden;
    resize: none;
}

.home-page canvas {
    max-width: 100%;
}

.home-page .error-msg {
    color: #fb807a;
}

.page-item{
    position: relative;
}

.page-item-cover{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 99999;
}

.page-item-cover img{
    width: 100%;
    height: 100%;
}

.page-item2{
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    font-variant: normal;
}

.border-red {
    position: absolute;
    border: 1px solid #E1A03A;
    z-index: 390;
    pointer-events: none;
}

.border-blue {
    position: absolute;
    border: 1px dashed #299DFF;
    z-index: 399
}

.imgBox {
    position: absolute;
    z-index: 998;
    outline: none;
}

.imgBox .img-holder {
    background-color: transparent;
    /*border: 1px solid lightsteelblue;*/
}

.img-place-holder {
    text-align: center;
    width: 100%;
    top: calc(59% - 30px);
    font-size: 8vw;
    color: #ffffff;
    position: absolute;
}

.img-place-holder .plus {
    font-size: 30px;
    display: block;
}

.center-rotation {
    transform-origin: center;
}

.leftbo-rotation {
    transform-origin: left bottom !important;
}

/**
    素材
    **/
.sticker-edit-box {
    position: absolute;
    outline: none;
    /*transform-origin: center*/
}

.no-events {
    pointer-events: none !important;
}

/**
    文字
    **/
.text-box, .img-art {
    position: absolute;
    /*padding: 0 12px;*/
    /*min-width: 100px;*/
}

.text-content {
    position: absolute;
    top: 0;
    left: 0;
}

.text-box .selected {
    border: 1px solid #ffb130;
}

.selecteds {
    border: 1px solid #ffb130;
}

.selecteds-text {
    border: 1px dashed #ffb130;
}

.text-box p {
    width: 100%;
    height: 100%;
    word-break: break-all;
    margin: 0;
}

.text-box span, .sticker-edit-box span {
    width: 24px;
    height: 24px;
    position: absolute;
}

.text-box img {
    width: 100%;
}

.page-warn{
    position: absolute;
    left: 0;
    bottom: 50px;
    color: #EA5E2A;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 20px;
}
.page-warn .icon-waring{
    font-size: 14px !important;
}

.page-scale span {
    display: inline-block;
    cursor: pointer;
    height: 36px;
    outline: none;
}

.page-scale-origin {
    width: 60px;
    background: url("../images/icons/design_scale_screen.svg") no-repeat center;
    border-right: 1px solid #e6e6e6;
}

.page-scale-minus {
    width: 16px;
    margin: 0 20px;
    background: url("../images/icons/design_scale_narrow.svg") no-repeat center;

}

.page-scale-num {
    display: inline-block;
    width: 35px;
    position: absolute;
    top: 10px;
}

.page-scale-plus {
    width: 16px;
    margin: 0 20px 0 60px;
    background: url("../images/icons/design_scale_amplification.svg") no-repeat center;
}

/*.panelCanshu{*/
/*float: left;*/
/*min-width: 250px;*/
/*}*/

.rightPanel-canshu {
    /*box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);*/
}

/*自定义右键菜单样式*/
#menus {
    width: 140px;
    height: auto;
    position: absolute;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: none;
    z-index: 999999;
    background: #fff;
}

.menu {
    width: 100%;
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
}

.menu:hover {
    background: #eee;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.selectedCtrl {
    border: 1px solid red;
}

.picture-border {
    width: 60px;
    height: 60px;
    border: 1px dashed red;
}

.clip-area {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: move;
    z-index: 1;
}

.clip-wrap {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
}

.clip-btn {
    width: 20px;
    height: 20px;
    position: absolute;
}

.clip-right-top-btn {
    right: -8px;
    top: -8px;
    cursor: ne-resize;
    background: url("../../content/images/icons/clip_right_top.svg") no-repeat center;
}

.clip-right-bottom-btn {
    right: -8px;
    bottom: -8px;
    cursor: se-resize;
    background: url("../../content/images/icons/clip_right_bottom.svg") no-repeat center;
}

.clip-left-bottom-btn {
    left: -8px;
    bottom: -8px;
    cursor: sw-resize;
    background: url("../../content/images/icons/clip_left_bottom.svg") no-repeat center;
}

.clip-left-top-btn {
    left: -8px;
    top: -8px;
    cursor: nw-resize;
    background: url("../../content/images/icons/clip_left_top.svg") no-repeat center;
}

.exit-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.exit-box-mask {
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
}

.exit-box-con {
    width: 30vw;
    margin: 35vh auto;
    min-height: 22vh;
    /*background: #fff;*/
    border-radius: 5px;
    position: relative;
    text-align: center;
}

.exit-box-con-head {
    border-bottom: 1px solid #d9d9d9;
    padding: 16px 0;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.back-btn1 {
    background: #fff;
    height: 50px;
    color: #232323;
    font-size: 14px;
    font-weight: bold;
    border-right: 1px solid #dfdfdf;
    border-bottom-left-radius: 5px;
}

.back-btn2 {
    background: #FAC779;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    border-bottom-right-radius: 5px;
}

.back-btn3 {
    background: #AADC45;
    height: 57px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-bottom-left-radius: 5px;
}

.back-btn4 {
    background: #FFA91C;
    height: 57px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-bottom-right-radius: 5px;
}

.radius-btn {
    height: 30px;
    font-size: 12px;
    border: none;
    background: none;
    min-width: 76px;
    margin-left: 6px;
    margin-bottom: 10px;
    color: #979797;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.icon-retract {
    height: 32px;
    color: #9B9B9B;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0px 0px 5px rgba(35, 35, 35, 0.3);
    line-height: 34px;
    text-align: center;
    margin-bottom: 10px;
}

.icon-drop-down {
    height: 32px;
    color: #E1A03A;
    background: #fff;
    border-radius: 50px;
    border: 1px solid #E1A03A;
    line-height: 34px;
    text-align: center;
    margin-bottom: 10px;
    /*box-shadow: 0px 0px 5px rgba(35, 35, 35, 0.3);*/
}



.preview-article{
    background: #ECECEC;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}
.preview-bg{
    width: 100%;
    height: 100%;
    position: absolute;
}
.preview-article-close{
    position: absolute;
    right: 40px;
    top: 30px;
    z-index: 99;
}

.preview-article-con{
    background: #ECECEC;
    width: 80vw;
    margin: 8vw auto;
    height: 73vh;
}

.loading-page2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    color: #fff;
    z-index: 9999;
}
.loading-page3 {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    /*width: 100vw;*/
    /*height: calc(100vh - 50px);*/
    text-align: center;
    color: #fff;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-center{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.loading-center img{
    width: auto;
    height: 100%;
}

.loading-center2{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-center2 img{
    width: auto;
    height: 100%;
}

.preview-article-con1{
    background: url("../images/icon-bg.jpg") no-repeat;
    background-size: 100% 100%;
    width: 100vw;
    height: 100vh;
}
.threeView-body{
    width: 100%;
    height: 100%;
    position: relative;
}
.threeView{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.threeLoading{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    z-index: 1;
    margin: -40px 0 0 -40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.threeLoading1{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.2);
    color: #fff;
}
.threeError{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    height: 80px;
    z-index: 1;
    margin: -40px 0 0 -150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #999;
}
.threeHide{
    position: absolute;
    right: 0;
    top: 0;
    height: 35px;
    width: 35px;
    cursor: pointer;
    z-index: 99;
    text-align: center;
    line-height: 35px;
    background: url("../images/icon/icon-delete.png") no-repeat center;
    background-size: 18px;
}

.thumbnail-btn{
    width: 80px;
    height: 25px;
    background: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 4px;
    color: #979797;
    margin-right: 10px;
    position: relative;
    display: inline-block;
    text-align: center;
}

.thumbnail-btn-active{
    border: 1px solid #E96A5A!important;
    border-radius: 4px;
    color: #E96A5A!important;
}

.cover-move{
    transform:translate(-2520px,132px);
    transition: all 1s linear;
    position: fixed!important;
}
.cover-move2{
    transform:translate(-2520px,-90px);
    transition: all 1s linear;
    position: fixed!important;
}
.cover-scale{
    transform:scale(0.1);
    transition: all 0.5s linear;
}

.choose-all-layout{
    text-align: left;
    padding: 10px 15px 0 15px;
}

.choose-all-layout input{
    width: 12px;
    height: 12px;
}

.choose-all-layout span{
    width: 28px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #7B7A7A;
    /*line-height: 48px;*/
}
.modal-xg {
    width: 480px;
}

.productTemplate-dark{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productTemplate-mask {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
}

.productTemplate-dark i{
    font-size: 24px;
    margin: 0;
    color: #264DF0;
    position: relative;
}

.productTemplate-name{
    font-size: 10px;
    color: #333333;
    margin: 10px 0 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.productTemplate-size{
    font-size: 10px;
    color: #838383;
}

.proTempModal-text{
    padding-top: 60px;
    height: 214px;
    font-size: 18px;
    color: #000000;
    background: #fff;
    line-height: 45px;
    text-align: left;
}


.patterns ul li{
    float: left;
    width: 250px;
    padding: 10px;
}
.patterns-li{
    width: 100%;
    height: 292px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border: 1px solid #ddd;
}
.patterns-li-top{
    width: 100%;
    height: 230px;
    line-height: 230px;
    text-align: center;
}
.patterns-li-top img{
    max-width: 100%;
    max-height: 100%;
}
.patterns-li-bottom{
    position: relative;
    width: 100%;
}
.patterns-li-bottom-text{
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 10px;
}
.color999{
    color: #999;
}
.patterns-li-bottom-operation{
    position: absolute;
    width: 80px;
    right: 0;
    top: 0;
    cursor: pointer;
}
.patterns-li-bottom-operation-top{
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-align: center;
}
.patterns-li-bottom-operation-bottom{
    display: none;
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
}
.patterns-li-bottom-operation-bottom p{
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
}
.patterns-li-bottom-operation:hover .patterns-li-bottom-operation-bottom{
    display: block;
}
.initial{
    margin: 0;
    padding: 0;
}
.btn-look{
    position: absolute;
    width: 25px;
    height: 25px;
    right: 0;
    bottom: 0;
    line-height: 25px;
    cursor: pointer;
    z-index: 200;
}
.c-thumbnail-view{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 50px;
}
.c-thumbnail-view .iconfont{
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 50px;
    cursor: pointer;
    color: #fff;
    font-size: 30px;
}

.modelTypeBtn{
    display: inline-block;
    width: 120px;
    height: 35px;
    background: #fff;
    color: #0A0A0A;
    box-shadow: 0 0 5px 0px rgba(0,0,0,0.3);
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    margin-right: 20px;
    cursor: pointer;
    transition: all .3s;
    outline:none;
}
.modelTypeBtn:hover{
    color: #ff6c5f;
}
.modelTypeBtnSelect{
    background: #ff6c5f;
    box-shadow: 0 0 5px 0px rgba(255, 108, 95,0.3);
    color: #fff;
}
.modelTypeBtnSelect:hover{
    color: #fff;
}
.c-modelBlendent-view{
    position: fixed;
    left: 10%;
    top: 5%;
    display: flex;
}
.c-modelBlendent-view-li{
    height: 40px;
    padding: 0 15px;
    background: #fff;
    cursor: pointer;
    border-radius: 4px;
    color: #0f0f0f;
    transition: all .1s;
    margin-right: 10px;
    line-height: 40px;
}
.c-modelBlendent-view-li-selected{
    background: #1B9815;
    color: #fff;
}
.c-rahmen{
    padding: 0 15px;
}
.c-rahmen-title{
    color: #1C1C1F;
    font-size: 16px;
    text-align: left;
}
.c-rahmen-view{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.c-schedule-view{
    display: flex !important;
    align-items: center;
}
.c-schedule-title{
    display: block;
    width: 40px !important;
    font-size: 12px;
}

.rightPanel-title-radio-btn{

}
.rightPanel-title-radio-btn img{
    width: 16px;
    height: 16px;
}

.scrollBar::-webkit-scrollbar {
    width: 8px !important;
}

/* 这是针对缺省样式 (必须的) */
.scrollBar::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
}

/* 滚动条的滑轨背景颜色 */
.scrollBar::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 20px;
}

/* 滑块颜色 */
.scrollBar::-webkit-scrollbar-button {
    background-color: #fff;
    display: none;
}

/* 滑轨两头的监听按钮颜色 */
.scrollBar::-webkit-scrollbar-corner {
    background-color: black;
}

.border-recommend-btn{
    width: 30px;
    height: 30px;
    background: #000;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    border-bottom-right-radius: 10px;
}
.border-recommend-btn img {
    position: absolute;
    z-index: 101;
    top: 5px;
    left: 5px;
}
.border-radio-btn{
    display: flex !important;
    width: 50px;
    align-items: center;
    justify-content: space-evenly;
}
.borderSet-body{
    display: flex !important;

}
.c-font-view::after,.c-font-view-body-ul::after,.c-font-view-body::after{
    opacity: 0;
}

.thumbnailator-text {
    color: #6E6E6E;
    font-size: 14px;
    margin: 10px 0;
}

.c-documentDetection-tab{
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.c-documentDetection-tab div{
    margin: 0 20px;
    font-size: 16px;
}
.c-documentDetection-tab-select{
    color: #FAC779;
}
.c-documentDetection-view{
    width: 100%;
}
.c-documentDetection-view-title{
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}
.c-documentDetection-view-1{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.c-documentDetection-view-1-li{
    display: flex;
    margin: 0 15px 15px 0;
}
.c-documentDetection-view-1-li-text{
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    background: #f3f3f3;
    color: #666;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.c-documentDetection-view-1-li-fl{
    width: 80px;
    height: 80px;
    background: #f3f3f3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.c-documentDetection-view-1-li-fl img{
    max-width: 60%;
    max-height: 100%;
}
.c-documentDetection-view-1-li-fl i{
    font-size: 18px;
}
.c-documentDetection-view-1-li-fr{
    padding: 0 15px;
    max-width: 100px;
    height: 80px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    color: #666;
}
.align-view{
    width: 100%;
    display: flex !important;
    flex-wrap: wrap;
}
.align-view > div {
    width: 50%;
    display: flex !important;
    margin-top: 5px;
}
.align-view > div > div {
    margin-left: 10px;
}
.c-thumbnail-all{
    position: relative;
    width: 1200px;
    background: #fff;
    margin: 50px auto;
    border-radius: 5px;
}
.c-thumbnail-all-head{
    height: 50px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}
.c-thumbnail-all-head span{
    font-size: 16px;
    color: #0f0f0f;
}
.c-thumbnail-all-head img{
    width: 16px;
    cursor: pointer;
}
.c-thumbnail-all-body{
    width: 100%;
}
.c-thumbnail-all-body-view1{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.c-thumbnail-all-body-view1 span{
    color: #999;
}
.c-thumbnail-all-body-view1-btn{
    width: 120px;
    height: 40px;
    background: #e6f1fc;
    color: #1989fa;
    border: 1px solid #1989fa;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.c-thumbnail-all-body-view2{
    width: 100%;
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
}
.c-thumbnail-all-body-view2-li{
    position: relative;
    width: 200px;
}
.c-thumbnail-all-body-view2-li-img{
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    background-color: rgba(245, 247, 250, 1);
    align-items: center;
    border-radius: 2px;
}
.c-thumbnail-all-body-view2-li-img img{
    max-width: 100%;
    max-height: 100%;
}
.c-thumbnail-all-body-view2-li-img span{
    font-size: 16px;
    color: #89919D;
}
.c-thumbnail-all-body-view2-li-conetnt{
    height: 40px;
    width: 200px;
    line-height: 40px;
    text-align: left;
    text-overflow: ellipsis;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    padding: 0px 6px;
    overflow: hidden;
    display: flex;

    justify-content: space-between;
}
.c-thumbnail-all-body-view2-li-conetnt span{
    font-size: 14px;
    color: #89919D;
}
.c-thumbnail-all-body-view2-li-conetnt div{
    font-size: 14px;
    color: #333333;
    cursor: pointer;
}
.c-thumbnail-all-body-view2-li-conetnt div:hover{
    color: #264df0;
}
.c-prompt{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    color: #fff;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
}
.c-prompt span{
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 10px;
    font-size: 12px;
    color: #fff;
    margin-top: 2px;
}
.checkOrIf{
  position: relative;
  -webkit-appearance: none;
    width: 28px !important;
    height: 15px !important;
  line-height: 22px;
  background: #fff;
  border-radius: 15px;
  border:2px solid #C8C8C8;
  cursor: pointer;
  outline: none !important;
}
.checkOrIf:before{
    position: absolute;
    left: 3px;
    top: 2px;
    content: '';
    width: 7px;
    height: 7px;
    outline: none;
    border-radius: 50%;
    background: #C8C8C8;
    border: none;
    box-shadow: 0px 0px 5px #ddd;
    transition: all 0.2s linear;
}

.checkOrIf:checked{
    background: #264DF0;
    border: none;
}
.checkOrIf:checked:before{
    left: 17px;
    top: 4px;
    width: 7px;
    height: 7px;
    background: #fff;
    transition: all 0.2s linear;
}

.c-first-page{
    border: 1px solid #999;
    font-size: 12px;
    color: #0A0A0A;
    display: flex;
}
.c-first-page-1{
    background: #fff;
    width: 40px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.c-first-page-2{
    background: #ddd;
    width: 40px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bb-bookblock {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 100;
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.bb-page {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
}

.bb-vertical .bb-page {
    width: 50%;
    height: 100%;
    left: 50%;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    transform-origin: left center;
}

.bb-horizontal .bb-page {
    width: 100%;
    height: 50%;
    top: 50%;
    -webkit-transform-origin: center top;
    -moz-transform-origin: center top;
    transform-origin: center top;
}

.bb-page > div,
.bb-outer,
.bb-content,
.bb-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
}

.bb-vertical .bb-content {
    width: 200%;
}

.bb-horizontal .bb-content {
    height: 200%;
}

.bb-page > div {
    width: 100%;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.bb-page > div:not(:only-child) {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.bb-vertical .bb-back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.bb-horizontal .bb-back {
    -webkit-transform: rotateX(-180deg);
    -moz-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
}

.bb-outer {
    width: 100%;
    overflow: hidden;
    z-index: 999;
}

.bb-overlay,
.bb-flipoverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.bb-flipoverlay {
    /*background-color: rgba(0, 0, 0, 0.2);*/
}

/* */

.bb-bookblock.bb-vertical > div.bb-page:first-child,
.bb-bookblock.bb-vertical > div.bb-page:first-child .bb-back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.bb-bookblock.bb-horizontal > div.bb-page:first-child,
.bb-bookblock.bb-horizontal > div.bb-page:first-child .bb-back {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

/* Content display */
.bb-vertical .bb-front .bb-content {
    left: -100%;
}

.bb-horizontal .bb-front .bb-content {
    top: -100%;
}

/* Flipping classes */
.bb-vertical .bb-flip-next,
.bb-vertical .bb-flip-initial {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.bb-vertical .bb-flip-prev {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.bb-horizontal .bb-flip-next,
.bb-horizontal .bb-flip-initial {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.bb-horizontal .bb-flip-prev {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

.bb-vertical .bb-flip-next-end {
    -webkit-transform: rotateY(-15deg);
    -moz-transform: rotateY(-15deg);
    transform: rotateY(-15deg);
}

.bb-vertical .bb-flip-prev-end {
    -webkit-transform: rotateY(-165deg);
    -moz-transform: rotateY(-165deg);
    transform: rotateY(-165deg);
}

.bb-horizontal .bb-flip-next-end {
    -webkit-transform: rotateX(15deg);
    -moz-transform: rotateX(15deg);
    transform: rotateX(15deg);
}

.bb-horizontal .bb-flip-prev-end {
    -webkit-transform: rotateX(165deg);
    -moz-transform: rotateX(165deg);
    transform: rotateX(165deg);
}

.bb-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}

/* No JS */
.no-js .bb-bookblock,
.no-js ul.bb-custom-grid li {
    width: auto;
    height: auto;
}

.no-js .bb-item {
    display: block;
    position: relative;
}

* {
    outline: none;
}
.c-table {
    width: 100%;
    overflow: auto;
}

.c-table table {
    width: 100%;
}

.c-table table thead tr {
    border: 1px solid #e8e8e8;
}

.c-table table thead tr th {
    text-align: center;
    height: 54px;
    font-size: 14px;
    color: #232323;
}

.c-table table tbody tr td {
    padding: 10px;
    color: #000;
    font-size: 14px;
    border: 1px solid #e8e8e8;
    text-align: center;
}

.c-table-btn {
    color: #1890ff;
    padding-right: 12px;
    border-right: 1px solid #ddd;
}

.c-body {
    background: #fff;
    margin: -30px 0 0 0;
    padding: 20px;
}

.c-title-nav {
    display: inline-block;
    line-height: 50px;
    padding: 0 10px;
    font-size: 14px;
}

.c-title-navOn {
    color: #fe5454;
    border-bottom: 3px solid #fe5454;
}

.c-title-navOn2 {
    color: #000000;
    border-bottom: 3px solid #fe5454;
}

.c-text-list li {
    float: left;
    width: 100px;
    text-align: center;
}

.c-text-list li img {
    width: 100px;
    height: 100px;
}

.c-text-list li p {
    font-size: 14px;
    text-align: center;
    margin: 10px 0 0 0;
    color: #0A0A0A;
}


.c-form {
    clear: both;
    margin-bottom: 20px;
}

.c-form .c-form-label {
    float: left;
    display: block;
    padding-right: 10px;
    height: 35px;
    line-height: 35px;
    width: 110px;
    font-weight: 400;
    text-align: right;
    margin: 0;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.c-form .c-form-div {
    margin-left: 110px;
    width: 375px;
    min-height: 35px;
}

.c-form .c-form-div input.c-input1, .c-form .c-form-div select.c-select1, .c-form .c-form-div textarea {
    width: 100%;
    /* -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075); */
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-radius: 4px;
    height: 32px;
    border: 1px solid #E1E5EB;
    padding: 0 6px;
    font-size: 13px;
    outline: none;
}

.c-form .c-form-div input.c-input1:focus, .c-form .c-form-div select.c-select1:focus, .c-form .c-form-div textarea:focus, .commodityAdd-select input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.lh35 {
    line-height: 35px;
}

.c-xml {
    /*position: relative;*/
}

.c-xml-head {
    height: 50px;
    background: #000;
}

.c-xml-logo {
    float: left;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
}

.c-xml-logo img {
    max-height: 80%;
}

.c-xml-head-fileSelect {
    position: relative;
    float: left;
    width: 120px;
    height: 50px;
    padding-top: 10px;
}

.c-xml-head-fileSelect .c-xml-head-fileSelect-span {
    width: 80%;
    height: 30px;
    border-radius: 30px;
    background: rgba(250, 250, 250, 0.3);
    color: #fff;
    line-height: 30px;
    text-align: center;
    font-size: 13px;
    margin: 0 auto;
}

.c-xml-head-fileSelect .c-xml-head-fileSelect-list {
    position: absolute;
    top: 50px;
    left: 50%;
    width: 170px;
    margin-left: -85px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0 0 3px 3px;
    display: none;
    z-index: 3;
}

.c-xml-head-fileSelect:hover .c-xml-head-fileSelect-list {
    display: block;
}

.c-xml-head-fileSelect .c-xml-head-fileSelect-list ul li {
    width: 100%;
    padding: 0 20px;
    text-align: left;
    height: 35px;
    line-height: 35px;
    color: #0A0A0A;
}

.c-xml-head-fileSelect .c-xml-head-fileSelect-list ul li:hover {
    background: #e2e2e2;
    color: #FE5454;
}

.c-xml-head-name {
    float: left;
    margin-left: 20px;
    width: 150px;
    height: 50px;
    padding-top: 10px;
}

.c-xml-head-name input {
    background: rgba(250, 250, 250, 0);
    border: 0;
    height: 30px;
    line-height: 30px;
    color: #fff;
}

.c-xml-head-fr {
    float: right;
}

.c-xml-head-btn1 {
    width: 120px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border-left: 2px solid #E1A03A;
    color: #E1A03A;
}

.c-xml-head-btn2 {
    width: 200px;
    height: 50px;
    background: #FAC779;
    color: #0A0A0A;
}

.c-xml-nav {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    background: #000;
    z-index: 3;
}

.c-xml-nav ul li {
    position: relative;
    width: 100%;
    height: 60px;
    text-align: center;
    padding-top: 10px;
}

.c-xml-nav ul li img {
    height: 25px;
}

.c-xml-nav ul li span {
    display: block;
    color: #fff;
    font-size: 14px;
}

.c-xml-nav ul li .c-xml-navOn-border {
    position: absolute;
    left: 0;
    top: 10px;
    height: 40px;
    width: 3px;
    background: #E1A03A;
    display: none;
}

.c-xml-nav ul li:hover {
    background: rgba(225, 160, 58, .3);
}

.c-xml-nav ul li.c-xml-navOn {
    background: rgba(225, 160, 58, .3);
}

.c-xml-nav ul li.c-xml-navOn .c-xml-navOn-border {
    display: block;
}

.c-xml-cdr {
    position: absolute;
    left: 60px;
    top: 0;
    bottom: 0;
    width: 240px;
    background: #fff;
    box-shadow: 5px 0 5px #ccc;
    z-index: 2;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.c-xml-cdr-font, .c-xml-cdr-background, .c-xml-cdr-material {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.c-xml-cdr-font ul li .c-xml-cdr-font-div {
    width: 80%;
    height: 35px;
    line-height: 50px;
    text-align: center;
    background: #fff;
    box-shadow: 0 3px 10px #ccc;
    margin: 20px auto;
    border-radius: 35px;
}

.c-xml-cdr-font ul li .c-xml-cdr-font-div img {
    max-height: 100%;
    max-width: 100%;
}

.c-xml-cdr-head1 {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.c-xml-cdr-head1 ul li {
    float: left;
    width: 33%;
    padding: 0 5px;
}

.c-xml-cdr-head1 ul li span {
    display: inline-block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    border: 1px solid #999;
    color: #333;
    border-radius: 30px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}

.c-xml-cdr-head1 ul li span:hover {
    color: #E1A03A;
}

.themeOn span {
    border: 1px solid #E1A03A !important;
    color: #E1A03A !important;
}

.c-xml-cdr-head {
    position: relative;
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.c-xml-cdr-background-btn {
    display: block;
    width: 80%;
    height: 35px;
    line-height: 35px;
    border-radius: 35px;
    border: 1px solid #E1A03A;
    background: #fff8ee;
    color: #E1A03A;
    margin: 0 auto;
    cursor: pointer;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}

.c-xml-cdr-head:hover .c-xml-cdr-background-btn {
    background: #f9f0e2;
}

.c-xml-cdr-background-file {
    display: block;
    position: absolute;
    top: 20px;
    left: 10%;
    right: 0;
    bottom: 0;
    width: 80%;
    height: 35px;
    opacity: 0; /* 实现的关键点 */
    filter: alpha(opacity=0); /* 兼容IE */
    z-index: 1;
}
.c-xml-cdr-head1{
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
}
.c-xml-cdr-head1-view{
    width: 80%;
    border: 1px solid #E1A03A;
    border-radius: 35px;
    overflow: hidden;
    display: flex;
    margin: 0 auto;
}
.c-xml-cdr-head1-view span{
    display: inline-block;
    width: 50%;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #666;
    background: #fff8ee;
    font-size: 12px;
    cursor: pointer;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}
.c-xml-cdr-head1-view span:hover, .c-xml-cdr-head1-view span.c-xml-cdr-head1-btn-select{
    background: #E1A03A;
    color: #fff;
}

.c-xml-cdr-body {
    padding: 20px 0 50px;
}

.c-xml-cdr-body ul li {
    float: left;
    width: 50%;
    padding: 10px;
}

.c-xml-cdr-body-img {
    position: relative;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f3f3f3;
    overflow: hidden;
    box-shadow: 0 5px 10px #999;
}

.c-xml-cdr-body-img img.c-xml-cdr-img {
    max-width: 100%;
    max-height: 100%;
}

.c-xml-cdr-body-imgOn {
    border: 1px solid #ffb741;
}

.c-xml-cdr-delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 20px;
    background: rgba(0, 0, 0, 0.7) url("../images/icons/ic_delete.svg") no-repeat center;
    border-radius: 0 0 0 3px;
    cursor: pointer;
    display: none;
    background-size: 13px;
}
.c-xml-cdr-recommend {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 26px;
    height: 20px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 3px 0;
    display: none;
    text-align: center;
    line-height: 18px;
}

.c-xml-cdr-body-img:hover .c-xml-cdr-delete, .c-xml-cdr-body-img:hover .c-xml-cdr-recommend {
    display: block;
}

.c-xml-cdr-body-paging {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background: #fff;
}

.c-xml-cdr-body-paging button {
    width: 90%;
    height: 35px;
    border-radius: 35px;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(35, 35, 35, 0.3);
    border: 1px solid #ddd;
}

.c-xml-action {
    position: absolute;
    left: 300px;
    top: 0;
    right: 200px;
    height: 40px;
    background: #fff;
    box-shadow: 0 5px 10px #ccc;
    padding: 0 20px;
    z-index: 999;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.c-xml-action-icon {
    float: right;
}
.c-xml-action-icon-li{
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px !important;
    border-radius: 3px;
}
.c-xml-action-icon-li:hover .tip3{
    display: block;
}

.c-xml-action-icon li {
    position: relative;
    padding-top: 5px;
    margin: 0 5px;
    float: left;
}

.c-xml-action-icon-font {
    float: left;
}

.c-xml-action-icon-font li {
    position: relative;
    margin: 0 5px;
    float: left;
}

.c-xml-action-div-icon {
    width: 40px;
    height: 30px;
    line-height: 30px;
    background: #ddd;
    border-radius: 3px;
    transition: all .1s ease;
}

.c-xml-action-div-icon .tip3 {
    top: 35px;
}

.c-xml-action-div-icon:hover {
    background-color: #ccc;
}

.c-xml-action-div-icon:hover .tip3 {
    display: block;
}

.tools_bar_italic {
    background: url("../images/icons/tools_bar_italic.svg") no-repeat center;
}

.tools_bar_italic_Selected {
    background: url("../images/icons/tools_bar_italic_Selected.svg") no-repeat center;
}

.tools_bar_underline {
    background: url("../images/icons/tools_bar_underline.svg") no-repeat center;
}

.tools_bar_underline_Selected {
    background: url("../images/icons/tools_bar_underline_Selected.svg") no-repeat center;
}

.tools_bar_spacing1 {
    background: url("../images/icons/tools_bar_spacing1.svg") no-repeat center;
}

.tools_bar_bold {
    background: url("../images/icons/tools_bar_bold.svg") no-repeat center;
}

.tools_bar_bold_Selected {
    background: url("../images/icons/tools_bar_bold_Selected.svg") no-repeat center;
}

.tools_bar_transparency {
    background: url("../images/icons/tools_bar_transparency.svg") no-repeat center;
}

.tools_bar_coverage {
    background: url("../images/icons/tools_bar_coverage.svg") no-repeat center;
}

.tools_bar_lock {
    background: url("../images/icons/tools_bar_lock.svg") no-repeat center;
}

.tools_bar_copy {
    background: url("../images/icons/tools_bar_copy.svg") no-repeat center;
}

.tools_bar_revocation {
    background: url("../images/icons/tools_bar_revocation.svg") no-repeat center;
}

.tools_bar_recover {
    background: url("../images/icons/tools_bar_recover.svg") no-repeat center;
}

.tools_bar_delete {
    background: url("../images/icons/tools_bar_delete.svg") no-repeat center;
}

.tools_bar_full_screen {
    background: url("../images/icons/tools_bar_full_screen.svg") no-repeat center;
}

.tools_bar_left_justifying {
    background: url("../images/icons/tools_bar_left_justifying.svg") no-repeat center;
}

.tools_bar_align_center {
    background: url("../images/icons/tools_bar_align_center.svg") no-repeat center;
}

.tools_bar_align_right {
    background: url("../images/icons/tools_bar_align_right.svg") no-repeat center;
}

.tools_bar_justify_align {
    background: url("../images/icons/tools_bar_justify_align.svg") no-repeat center;
}

.coverage_move_up {
    background: url("../images/icons/coverage_move_up.svg") no-repeat center;
}

.coverage_move_down {
    background: url("../images/icons/coverage_move_down.svg") no-repeat center;
}

.coverage_stick {
    background: url("../images/icons/coverage_stick.svg") no-repeat center;
}

.coverage_bottom {
    background: url("../images/icons/coverage_bottom.svg") no-repeat center;
}

.c-xml-action-div-icon-align {
    background: url("../images/icons/tools_bar_left_justifying.svg") no-repeat center;
    width: 40px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    transition: all .1s ease;
}

.c-xml-action-div-icon-align {

}

.c-xml-action-div-icon {
    background-size: 20px;
}

.c-xml-action-div-icon-align-div {
    position: absolute;
    top: 30px;
    left: 50%;
    width: 220px;
    padding: 10px;
    background: #fff;
    border-radius: 3px;
    margin-left: -110px;
    display: none;
}

.c-xml-action-div-icon-align-div > div {
    position: relative;
    float: left;
    margin: 0 5px;
}

.c-xml-action-div-icon-align:hover .c-xml-action-div-icon-align-div, .c-xml-action-div-seq:hover .c-xml-action-div-icon-align-div {
    display: block;
}

.c-xml-content {
    position: absolute;
    left: 300px;
    top: 40px;
    right: 200px;
    bottom: 0;
    background: #ececec;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    overflow: hidden;
}
.c-xml-content .selected{
    outline: 2px dashed #ffb741 !important;
}

.c-xml-attribute {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    background: #fff;
    box-shadow: -5px 0 10px #ccc;
    width: 200px;
    z-index: 1;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.c-xml-attribute-head {
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #0A0A0A;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
}

.c-xml-attribute-body {

}

.c-xml-attribute-body-module {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.c-xml-attribute-body-module-title {
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    color: #666;
    text-align: left;
}

.c-xml-attribute-body-module-form {
    text-align: center;
    margin: 10px 0;
}

.c-xml-attribute-body-module-form-input {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 30px;
    overflow: hidden;
    margin-right: 0;
}

.c-xml-attribute-body-module-form-input input {
    border: 0;
    width: 69px;
    height: 30px;
    text-align: center;
}

.c-xml-attribute-body-module-form-input span {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    width: 40px;
    text-align: center;
    border-left: 1px solid #ddd;
}

.c-xml-attribute-body-module-form-select {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 30px;
    overflow: hidden;
}

.c-xml-attribute-body-module-form-select select {
    border: 0px;
    width: 50px;
    height: 30px;
    text-align: center;
}

.c-xml-attribute-body-module-edit {
    margin: 10px auto;
    text-align: center;
}

.c-xml-attribute-body-module-edit-btn1 {
    display: inline-block;
    width: 70px;
    height: 30px;
    font-size: 12px;
    color: #0A0A0A;
    text-align: center;
    line-height: 30px;
    background: #ddd;
    border-radius: 15px 0 0 15px;
    float: left;
    cursor: pointer;
}

.c-xml-attribute-body-module-edit-btn2 {
    display: inline-block;
    width: 70px;
    height: 30px;
    font-size: 12px;
    color: #0A0A0A;
    text-align: center;
    line-height: 30px;
    background: #ddd;
    border-radius: 0 15px 15px 0;
    float: left;
    cursor: pointer;
}

.c-xml-attribute-body-module-edit-btnOn {
    background: #FAC779;
}

.c-xml-thumbnail {
    width: 100%;
    height: 200px;
}

.c-xml-page-textboxe-span {
    display: inline-block;
    width: 100%;
    /*white-space: pre-wrap;*/
    white-space: break-spaces;
    word-break: break-word;
    font-feature-settings: "kern" 0, "liga" 0;
}

.c-xml-page-material, .c-xml-page-textboxe {
    position: absolute;
}

.c-xml-page-material-img {
    width: 100%;
    /*height: 100%;*/
}

.c-xml-page-material-zoom {
    display: none;
    position: absolute;
    width: 14px;
    height: 14px;
    /*border-radius: 14px;*/
    /*background: #ffb741;*/
}

.c-xml-page-material-zoom-left {
    background: url("../images/icons/ic_left.svg") no-repeat;
    left: -7px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize;
}

.c-xml-page-material-zoom-left1 {
    background: url("../images/icons/ic_leftup.svg") no-repeat;
    left: -7px;
    top: -7px;
    cursor: se-resize;
}

.c-xml-page-material-zoom-top {
    background: url("../images/icons/ic_rightup.svg") no-repeat center right;
    right: -7px;
    top: -7px;
    cursor: sw-resize;
}

.c-xml-page-material-zoom-top1 {
    background: url("../images/icons/ic_rightup.svg") no-repeat center right;
    right: -7px;
    top: -7px;
    cursor: sw-resize;
}

.c-xml-page-material-zoom-right {
    background: url("../images/icons/ic_right.svg") no-repeat center right;
    right: -7px;
    top: 50%;
    margin-top: -7px;
    cursor: e-resize;
}

.c-xml-page-material-zoom-right1 {
    background: url("../images/icons/ic_rightbelow.svg") no-repeat;
    right: -7px;
    bottom: -7px;
    cursor: se-resize;
}

.c-xml-page-material-zoom-bottom {
    background: url("../images/icons/ic_leftbelow.svg") no-repeat;
    left: -7px;
    bottom: -7px;
    cursor: sw-resize;
}

.c-xml-page-material-zoom-bottom1 {
    background: url("../images/icons/ic_leftbelow.svg") no-repeat;
    left: -7px;
    bottom: -7px;
    cursor: sw-resize;
}

.c-xml-page-material-pitch, .c-xml-page-textboxe-pitch {
    border: 1px dotted #ffb741;
}

.c-xml-page-material-rotate {
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 1px;
    height: 20px;
    background: #ffb741;
    display: none;
}

.c-xml-page-material-rotate-icon {
    position: absolute;
    left: 50%;
    bottom: -15px;
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 20px;
    background: url("../images/icons/copy.svg") no-repeat center;
    margin: -10px 0 0 -10px;
}

.c-xml-page-material-pitch .c-xml-page-material-zoom, .c-xml-page-material-pitch .c-xml-page-material-rotate, .c-xml-page-textboxe-pitch .c-xml-page-material-zoom, .c-xml-page-textboxe-pitch .c-xml-page-material-rotate {
    display: block;
}

.c-xml-action-div-transparency {
    position: absolute;
    top: 40px;
    left: 50%;
    width: 270px;
    height: 50px;
    border-radius: 3px;
    box-shadow: 0 0 5px #d9d9d9;
    border: 1px solid #d9d9d9;
    padding: 10px;
    margin-left: -135px;
    z-index: 999;
    background: #fff;
}

.c-xml-action-div-transparency-title {
    display: block;
    width: 50px;
    font-size: 14px;
    color: #000;
    float: left;
    height: 30px;
    line-height: 30px;
}

.c-xml-action-div-transparency-bar {
    position: relative;
    float: left;
    height: 30px;
    width: 100px;
    margin: 0 15px;
    overflow: hidden;
}

.c-xml-action-div-transparency-bar1 {
    position: absolute;
    top: 13.5px;
    width: 100%;
    height: 3px;
    background: #ddd;
    border-radius: 1px;
}

.c-xml-action-div-transparency-bar2 {
    position: absolute;
    left: 0;
    top: 10px;
    display: block;
    width: 10px;
    height: 10px;
    background: #4d4d4d;
    box-shadow: 0 0 3px #fff;
    cursor: pointer;
    border-radius: 10px;
}

.c-xml-action-div-transparency-input {
    float: left;
    width: 50px;
    height: 30px;
    border: 1px solid #ddd;
    background: #f3f3f3;
    color: #000;
    text-align: center;
}

.c-xml-font {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 200px;
}

.c-textarea1 {
    width: 100%;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-radius: 4px;
    height: 70px;
    border: 1px solid #ddd;
    padding: 6px;
    font-size: 13px;
    outline: none;
}

.c-xml-font input:focus, .c-select1:focus, .c-textarea1:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.c-xml-page-textboxe-text {
    display: block;
    margin: 0;
    padding: 0;
    word-break: break-all;
    width: 100%;
    transform-origin: left top;
}

.loading-font {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5) url("../images/icon/loading-all.gif") no-repeat center;
    background-size: 40px;
    z-index: 99;
}
.c-loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url("../images/icon/loading-all.gif") no-repeat center;
    background-size: 80px;
    z-index: 99999;
}

.c-xml-action-div-color-modal {
    position: absolute;
    top: 40px;
    left: 0;
    background: #fff;
    width: 200px;
}

.c-xml-action-div-color-modal span {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 3px;
    margin: 5px;
    float: left;
    cursor: pointer;
    border: 1px solid #ddd;
}

.c-xml-action-div-color-modal span.c-xml-action-div-color-on {
    border: 1px solid #FAC779;
}

.c-xml-action-div-color-modal-btn {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 3px;
    margin: 5px;
    float: left;
    cursor: pointer;
    border: 1px solid #ddd;
    background: url('../../content/images/icons/color_choose.svg') no-repeat center;
}

.unfold-left {
    position: fixed;
    top: 50%;
    width: 10px;
    height: 40px;
    background: #FAC779;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.unfold-right {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 112px;
    margin-top: -56px;
    background: #FFFFFF;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.c-xml-operation {
    position: absolute;
    right: -30px;
    top: 50%;
    width: 30px;
    margin-top: -30px;
    background: #fff;
    border: 1px solid #ddd;
}

.c-xml-operation button {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-bottom: 1px solid #ddd;
    margin-bottom: -1px;
    text-align: center;
}

.c-xml-operation div {
    line-height: 30px;
    text-align: center;
    height: 30px;
}

.c-xml-bgImg-tailor {
    z-index: 999;
    border: 2px solid #d9534f;
}

.c-xml-imageart-trim {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
}

.c-xml-imageart-trim-div {
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px dotted #d9534f;
    background: #fff;
}

.c-xml-imageart-trim-frame {
    border: 1px solid #0A0A0A;
    background: rgba(0, 0, 0, .5);
}

pre {
    display: block;
    padding: 0;
    margin: 0;
    word-wrap: unset;
    background-color: inherit;
    border: none;
    border-radius: 0;
    overflow: unset;
}

.c-xml-bgImg-trim {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
}

.input-span {
    display: inline-block;
    width: 50px;
    height: 30px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-right: 10px;
    line-height: 30px;
    margin-top: 2.5px;
    overflow: hidden;
}

.wh100 {
    width: 100%;
    height: 100%;
}

.leftPanel-img-show-content {
    position: absolute;
    width: 100%;
    top: 66px;
    bottom: 0;
    border-top: 1px solid #ddd;
    background: #f3f3f3;
    overflow: hidden;
}

.leftPanel-img-show-content-body {
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
    overflow: auto;
}

.leftPanel-img-show-content-body ul {
    padding: 5px;
}

.leftPanel-img-show-content-body ul li {
    float: left;
    width: 50%;
    padding: 5px;
}

.leftPanel-img-show-content-body-img {
    position: relative;
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: rgb(255, 255, 255);
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
    overflow: hidden;
}

.leftPanel-img-show-content-body-img img {
    max-width: 100%;
    max-height: 100%;
}

.leftPanel-img-show-content-foot {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: #fff;
    z-index: 201;
}

.leftPanel-img-show-content-foot span {
    display: inline-block;
    line-height: 50px;
    text-align: center;
    color: #999;
}

.leftPanel-img-show-content-body-delete {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #000;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    display: none;
}

.leftPanel-img-show-content-body-img:hover .leftPanel-img-show-content-body-delete {
    display: block;
}

.c-font-view {
    position: relative;
    width: 200px;
}

.c-font-view .c-font-view-head {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #fff url("../images/icon/icon-copy.png") no-repeat 97% center;
    background-size: auto 11px;
    border-radius: 3px;
    border: 1px solid #ddd;
}

.c-font-view .c-font-view-head2 {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #fff url("../images/icons/drop_down.svg") no-repeat 97% center;
    background-size: auto 11px;
    border-radius: 3px;
    border: 1px solid #ddd;
    position: relative;
    padding-left: 20px;
}
.c-font-view .c-font-view-head2>div{
    width: 75%;
    text-align: center;
}
.c-font-view .c-font-view-head2>div img{
    width: 100%;
}
.c-font-view .c-font-view-body {
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    background: #fff;
    border-radius: 0 0 6px 6px;
    border: 1px solid #ddd;
    border-top: 0;
    padding: 10px;
    display: none;
    z-index: 999;
    opacity: 0;
}

.c-font-view:hover .c-font-view-body {
    display: block;
    opacity: 1;
}

.c-font-view-body-head {
    position: relative;
}

.c-font-view-body-head-input {
    height: 30px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 12px;
    color: #0A0A0A;
    transition: all 0.2s;
    padding-right: 37px;
}

.c-font-view-body-head-btn {
    display: inline-block;
    width: 30px;
    height: 26px;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 1;
    cursor: pointer;
    background: url('../images/icon/sousuo.png') no-repeat center;
    background-size: 16px;
    transition: all 0.2s;
}
.chinese-support-view{
    height: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.chinese-support-item{
    color: #0f0f0f;
    cursor: pointer;
}
.chinese-support-select{
    color: #264DF0;
}

.c-font-view-body-head:hover .c-font-view-body-head-input {
    border: 1px solid #FAC779;
}

.c-font-view-body-head:hover .c-font-view-body-head-btn {
    background: url("../images/icon/sousuo-2.png") no-repeat center;
    background-size: 16px;
}

.c-font-view-body-ul {
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.c-font-view-body-ul .c-font-view-body-li {
    width: 100%;
    height: 30px;
    line-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
    font-size: 12px;
    transition: all 0.3s;
}

.c-font-view-body-ul .c-font-view-body-li img {
    max-height: 100%;
    max-width: 100%;
}

.c-font-view-body-ul .c-font-view-body-li:hover {
    background: #FAC779;
    border-radius: 2px;
}

.c-font-view-body-li-on {
    background: #FAC779;
    border-radius: 2px;
}

.color-red {
    color: #FE5454;
}

.c-btn1 {
    border: 0;
    background: #FE5454;
    color: #fff;
    height: 30px;
    width: 60px;
    border-radius: 3px;
    cursor: pointer;
}

.c-btn2 {
    border: 1px solid #ddd;
    background: #fff;
    color: #333;
    height: 30px;
    width: 60px;
    border-radius: 3px;
    cursor: pointer;
}

.c-modal {
    /*position: fixed;*/
    /*left: 0;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*z-index: 998;*/
    /*background: rgba(0,0,0,0.6);*/
}
.c-modal1 {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    background: rgba(0,0,0,0.6);
}
.c-modal-view{
    position: relative;
    width: 800px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.c-modal .c-modal-head {
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.c-modal .c-modal-head p {
    font-size: 18px;
    color: #232323;
    margin: 0;
}

.c-modal-hide {
    position: absolute;
    top: 0;
    right: 10px;
    display: inline-block;
    width: 40px;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
}

.c-modal-body {
    position: relative;
    padding: 20px 40px;
    max-height: 800px;
    overflow: auto;
}

.c-modal-foot {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #ddd;
}

.c-modal-btn {
    width: 120px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
}

.c-modal-btn-hide {
    color: #232323;
    background: #fff;
    border: 1px solid #ddd;
}

.c-modal-btn-submint {
    color: #fff;
    background: #fe5454;
    border: 1px solid #fe5454;
}

.c-buy-title {
    text-align: center;
    font-size: 14px;
}

.c-buy-title span {
    font-size: 30px;
    font-weight: 600;
}

.c-buy-tab {
    border: 2px solid #fe5454;
    width: 300px;
    border-radius: 3px;
    overflow: hidden;
    margin: 0 auto;
}

.c-buy-tab1 {
    float: left;
    width: 50%;
    height: 35px;
    background: #fff;
    color: #fe5454;
    text-align: center;
    line-height: 35px;
}

.c-buy-tab1-on {
    background: #fe5454;
    color: #fff;
}

.c-buy-tab1 span {
    font-size: 14px;
    font-weight: 600;
}

.c-buy-tab-view {
    margin-top: 20px;
    text-align: center;
    height: 300px;
}

.c-buy-tab-view-alipay {
    color: #666;
    margin-top: 20px;
}

.templateItemTab {
    width: 60%;
}

.templateItemTab li {
    float: left;
    width: 100px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ddd;
    border-radius: 3px;
    text-align: center;
    color: #0A0A0A;
    background: #fff;
    margin: 5px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    padding: 0 10px;
}

.templateItemTab li.pitch {
    background: #FE5454;
    color: #fff;
    border: 1px solid #FE5454;
}

.text-new-btn {
    background-color: #fe5454;
    border-color: #fe5454;
    color: #FFFFFF;
}

.text-new-btn:hover {
    color: #fff;
    background-color: #ff6c5f;
}

.modal-property {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
}

.modal-mask {
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
}

.modal-con {
    width: 46vw;
    margin: 30vh auto;
    height: 40vh;
    overflow-x: auto !important;
    min-height: 40vh;
    background: #fff;
    border-radius: 5px;
    position: relative;
    text-align: center;
}

.modal-t {
    border-bottom: 1px solid #d9d9d9;
    padding: 16px 0;
    width: 46vw;
    position: fixed;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 99;
}

.size-height {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
}

.pic-count-tip {
    text-align: center;
    padding-top: 10px;
    background: #FAE1B8;
    border-radius: 10px;
    color: #6F6F6F;
    height: 40px;
    width: 100%;
    margin-top: 20px;
}

.leftPanel-add .mask_info {
    display:none;
    width: 230px;
    position: absolute;
    left: 0px;
    top: 25px;
}
.leftPanel-add:hover .mask_info {
    display:block;
}

.picture-view{
    width: 100%;
    padding: 20px 0;
}
.picture-view-li{
    position: relative;
    float: left;
    margin: 10px;
    width: 242px;
    height: 310px;
    background: #FAFAFA;
    box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.07);
    border-radius: 2px;
}
.picture-view-li-next-look{
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}
.picture-view-li:hover .picture-view-li-next-look{
    display: block;
}
.picture-view-li-img{
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.picture-view-li-img img{
    max-width: 100%;
    max-height: 100%;
}
.picture-view-li-next {
    height: 40px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.picture-view-li-next-1{
    height: 40px;
    width: 70%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.picture-view-li-next-1 span{
    display: inline-block;
    width: 100%;
    color: #000000;
    font-size: 12px;
}
.picture-view-li-next-title{
    display: inline-block;
    max-width: 70%;
    height: 40px;
    line-height: 40px;
    color: #000000;
}
.picture-view-li-next-img{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.picture-view-li-next-img img{
    width: 35px;
    height: 35px;
}
.picture-view-li-next1{
    width: 90%;
    margin: 0 auto;
    height: 38px;
    color: #57585C;
    background: #F6E2BD;
    border-radius: 8px;
    text-align: center;
    line-height: 38px;
}
.picture-share-btn{
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}
.picture-share-btn:hover, .picture-share-btn-select{
    color: #FE5454;
}
.picture-view1{
    display: flex;
    justify-content: space-between;
}
.picture-view1-fl-btn{
    height: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
    width: 90px;
    color: #0f0f0f;
    margin-top: 10px;
}
.picture-view1-fl-btn img{
    width: 17px;
    height: 17px;
    margin-right: 10px;
}

.psd-list{
    padding: 0;
    line-height: 38px;
    height: 38px;
    margin-top: 32px;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.psd-list-left{
    text-align: left;
}

.psd-status1{
    color: #4F4F4F;
}

.psd-status2{
    color: #B7B7B7;
}

.psd-list-left img{
    margin-right: 14px;
}

.psd-list-right{
    text-align: right;
}

.psd-list-right img{
    margin-right: 10px;
}

.psd-wait{
    font-size: 14px;
    color: #457AE7;
}

.psd-success{
    font-size: 14px;
    color: #00A854;
}

.psd-loading{
    font-size: 14px;
    color: #264DF0;
}

.psd-error{
    font-size: 14px;
    color: #FF4444;
}

.psdFiles-ul{
    margin: 15px;
    /*background-color: #F8F8F8;*/
}

.psdFiles-li{
    margin-bottom: 30px;
    position: relative;
}

.psdFiles-li-cover{
    width: 210px;
    height: 135px;
    background: #F0F0F0;
    border-radius: 4px;
    margin-bottom: 14px;
    display: table-cell;     /* IE8+及标准浏览器识别，让div以表格元素的方式呈现，目的在于配合vertical-align*/
    vertical-align: middle; /* IE8+及标准浏览器识别，内容垂直居中 */
    text-align: center;
    cursor: pointer;
}

.psdFiles-li-cover img{
    max-width: 90%;
    max-height: 90%;
    border-radius: 4px;
    vertical-align: middle;
}

.psdFiles-li-name{
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #4F4F4F;
    line-height: 20px;
}

.psdFiles-li-del{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.c-login{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url("../images/login_background.png");
}
.c-login-view{
    position: fixed;
    width: 66%;
    height: 470px;
    background: #F7F7F7;
    left: 17%;
    top: 50%;
    margin-top: -235px;
    z-index: 1;
    display: flex;
}
.c-login-view-fl{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.c-login-view-fr{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.c-login-view-1{
    width: 300px;
}
.c-login-view-title{
    font-size: 16px;
    color: #333;
    text-align: center;
    font-weight: bold;
}
.c-login-view-1-title{
    margin: 20px 0 10px;
}
.c-login-view-1-view1{
    display: flex;
    align-content: center;
}
.c-login-view-1-view1-select{
    margin: 0 20px;
}
.c-login-view-1-view1-select i{
    color: #22cc1a;
}
.c-login-view-1-btn{
    width: 100%;
    height: 40px;
    background: #fe5454;
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    margin: 60px auto 0;
}
.c-login-view-input{
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
}
.c-login-view-input input{
    border: 1px solid #ddd;
    background: #fff;
    height: 30px;
}
.c-login-view-code{
    width: 120px;
    height: 30px;
    background: #dadada;
    border: 1px solid #ddd;
    font-size: 12px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.c-login-view-code1{
    position: absolute;
    right: 5px;
    top: 2px;
    width: 120px;
    height: 30px;
    background: #dadada;
    border: 1px solid #ddd;
    font-size: 12px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.c-login-view-1-form{
    position: relative;
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}
.c-login-view-1-form .icon-view{
    position: absolute;
    left: 0;
    top: 0;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.c-login-view-1-form input{
    width: 100%;
    height: 35px;
    padding-left: 40px;
    border: 0;
    background: none;
}
.c-home-first-modal{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
}

.c-document-detection-body{
    padding: 20px 50px;
}
.c-document-detection-body-view1{
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(38,77,240,0.09);
    border-radius: 4px;
    padding: 0 20px;
}
.c-document-detection-body-view1-tab{
    color: #B6B6B6;
    font-size: 16px;
    line-height: 21px;
}
.c-document-detection-body-view1-tab-selected{
    color: #2F53EB;
}
.c-document-detection-body-view1-hr{
    height: 1px;
    width: 140px;
    margin: 0 10px;
    border-bottom: 2px dotted #89919D;
}
.c-document-detection-body-view1-hr-selected{
    border-bottom: 2px dotted #2F53EB;
}
.c-document-detection-body-view2{
    width: 100%;
    margin: 20px 0;
}
.c-document-detection-body-view2-1-tab{
    width: max-content;
    height: 30px;
    font-size: 14px;
    color: #9C9C9C;
    display: flex;
    align-items: center;
    padding: 0 2px;
}
.c-document-detection-body-view2-1-tab > div{
    position: relative;
    width: 80px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 13px;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
    cursor: pointer;
}
.c-document-detection-body-view2-1-tab > div:hover{
    color: #2F53EB;
}
.c-document-detection-body-view2-1-tab-hr{
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 24px;
    height: 4px;
    margin-left: -12px;
    background: #264DF0;
    border-radius: 2px;
    display: none;
}
.c-document-detection-body-view2-1-tab-selected{
    background: #fff;
    color: #2F53EB;
}
.c-document-detection-body-view2-1-tab-selected .c-document-detection-body-view2-1-tab-hr{
    display: block;
}
.c-document-detection-body-view2-1-conent{
    margin-top: 15px;
    padding: 0 15px;
}
.c-document-detection-body-view2-1-conent-li{
    margin-bottom: 15px;
}
.c-document-detection-body-view2-1-conent-li-title{
    line-height: 30px;
    color: #000000;
}
.c-document-detection-body-view2-1-conent-li-item{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.c-document-detection-body-view2-1-conent-li-item-view{
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 16px;
}
.c-document-detection-body-view2-1-conent-li-item-view-img{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EEF0F3;
    overflow: hidden;
    border-radius: 4px;
}
.c-document-detection-body-view2-1-conent-li-item-view-img img{
    max-height: 100%;
    max-width: 100%;
}
.c-document-detection-body-view2-1-conent-li-item-view-describe{
    margin-left: 10px;
    height: 50px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}
.c-document-detection-body-view2-1-conent-li-item-view-describe-index{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}
.c-document-detection-body-view2-1-conent-li-item-view-describe-text{
    width: 100%;
    color: #505760;
    font-size: 12px;
}
.c-document-detection-foot{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.c-document-detection-foot-text{
    font-size: 12px;
    color: #A0A0A0;
}
.c-document-detection-btn1{
    width: 120px;
    height: 42px;
    background: #CDCDCD;
    border-radius: 4px;
    color: #656565;
    cursor: pointer;
    font-size: 16px;
    display: inline-block;
    margin: 0 5px;
    line-height: 42px;
    text-align: center;
}
.c-document-detection-btn2{
    color: #fff;
    background: #2F53EB;
}
.c-document-detection-btn3{
    color: #2F53EB;
    background: #FFFFFF;
}
.c-document-detection-body-view2-1-conent-2{
    width: 100%;
    text-align: center;
    color: #7BB394;
    font-size: 18px;
    font-weight: bold;
}
.c-document-detection-body-view2-2{}
.c-document-detection-body-view2-2-1{
    display: flex;
    justify-content: space-between;
}
.c-document-detection-body-view2-2-1-title{
    color: #505760;
}
.c-document-detection-body-view2-2-2{
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}
.c-checkbox-view{
    height: 30px;
    display: flex;
    align-items: center;
    margin: 0 10px 10px 0;
    color: #505760;
}
.c-checkbox-view .checkbox-icon{
    margin: 0 5px 0 0;
}
.c-checkbox-view1{
    padding: 0 8px;
    height: 32px;
    background: #F5F7FA;
    border-radius: 2px;
    color: #505760;
    transition: all .1s;
    line-height: 32px;
    margin: 8px;
}
.c-checkbox-view1:hover{
    color: #264DF0;
}
.c-checkbox-view1-selected, .c-checkbox-view1-selected:hover{
    background: #264DF0;
    color: #fff;
}
.c-document-detection-body-view2-3-1{
    text-align: center;
}
.c-document-detection-body-view2-3-1-text{
    color: #89919D;
    font-size: 14px;
}
.c-document-detection-body-view2-3-2-li{
    width: 100%;
    padding: 0 30px;
    height: 50px;
    background: #F7FAFE;
    border-radius: 4px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.c-document-detection-body-view2-3-2-li-title{
    color: #454546;
}
.c-document-detection-body-view2-3-2-li-success{
    color: #64D464;
}
.c-document-detection-body-view2-3-2-li-error .iconfont{
    color: #2F53EB;
}
.c-document-detection-body-view2-3-loadding{
    text-align: center;
    padding: 30px 0;
}

.relative-view{
    position: relative;
    width: 100%;
    height: 100%;
}
.relative-view1{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.bezel-set{
    display: flex;
    align-items: center;
}
.bezel-set-color{
    width: 30px;
    height: 30px;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ddd;
}
.bezel-set-color input{
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.c-frameConfigs-view{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
}
.c-frameConfigs-li{
    margin-bottom: 15px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 6px 0 rgba(25, 25, 25, 0.17);
}
.c-frameConfigs-li img{
    max-width: 100%;
    max-height: 100%;
}
.first-step{
    width: 45px;
    height: 45px;
    background: #FFFFFF;
    border: 2px solid #CECECE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #CECECE;

}
.middle-wire{
    width: 65px;
    height: 1px;
    border: 1px dashed #EA604F;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 19px;
}
.second-step{
    width: 45px;
    height: 45px;
    background: #FFFFFF;
    border: 2px solid #CECECE;
    color: #CECECE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.step-frame{
    display: flex;
    width: 200px;
    margin: 0 auto;
}
.step-active{
    color: #EA604F;
    border: 2px solid #EA604F;
}

.delete-span{
    transform: rotate(45deg);
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: block;
    margin: -33px 149px 0;
    color: red;
    font-size: 34px;
}
/* .panel-body{

    justify-content: space-between;
} */
.editor-border-left{
    flex: 1;
    /* padding-right: 10px; */
}
.editor-border-right{
    flex: 1;
}
.editor-border-right-content{
    border: 1px solid #939393;
    border-radius: 4px;
    width: 300px;
    box-sizing: content-box;
}
.shape-boder{
    width: 35px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #939393;
}
.img-place{
    padding:20px 41px 0px;
}
.img-place1{
    width: 250px;
    display: flex;
    padding:20px 41px 0px;
    justify-content: space-between;
}
.img-place1-left,
.img-place1-right{
    display: flex;
    flex-direction: column;
}
.editor-right-border{
}
.relative-view{
    position: relative;
    width: 100%;
    height: 100%;
}
.relative-view1{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.relative-view2{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.template-status{
    width: 75px;
    height: 25px;
    background: #fae1b8;
    box-shadow: 0px 8px 9px 0px rgb(140 140 140 / 10%);
    border-radius: 10px 0px 0px 10px;
    position: absolute;
    right: -18px;
    bottom: 147px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6C6D6E;
}
.template-status::after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0.4;
    border-color: transparent #7f7f7f;
    border-width: 8px 0px 0px 8px;
    border-style: solid;
    right: 0;
    top: -8px;
}
.image-placeholder{
    background-color: #e2e3e7;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.image-placeholder img{
    width: 28px;
}
.image-placeholder span{
    margin-top: 7px;
    color: #333333;

}
.image-placeholder-picture-xml{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.image-placeholder-picture-xml img{
    width: 28px;
}
.image-placeholder-picture-xml span{
    margin-top: 7px;
    color: #333333;
}
.image-placeholder-picture-xml-2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.image-placeholder-picture-xml-2 img{
    width: 20px;
}
.image-placeholder-picture-xml-2 span{
    margin-top: 7px;
    color: #333333;
}
.title-more-icon{
    position: relative;
}
.title-more{
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    background-color: #fff;
    z-index: 500;
}

.title-more > div{
    margin: 5px;
}

.botton-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.moveCanvas-view{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 887;
    cursor: move;
}


/* MessageService.confirm */
.confirm-dialog{
    width: 528px;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px 24px 32px;
}
.confirm-dialog-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.confirm-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}
.confirm-dialog-content{
    padding: 24px 0;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}
.confirm-dialog-footer{
    display: flex;
    align-items: center;
    justify-content: right;
}
.confirm-dialog-footer-cancel{
    padding: 10px 30px;
    color: #333333;
    background: #F7F8FA;
    border-radius: 4px;
}
.confirm-dialog-footer-delete{
    padding: 10px 30px;
    color: #ffffff;
    background: #264DF0;
    border-radius: 4px;
    margin-left: 16px;
}

@charset "utf-8";
/*@import url("https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans:300");*/
*,
*::before,
*::after {
	box-sizing: border-box;
}

/*body {*/
/*	font-family: "Fredoka One", cursive;*/
/*	font-size: 16px;*/
/*	margin: 0px;*/
/*}*/

#app {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 100vh;
	background: rgba(249, 249, 249, 0.9);
	overflow: hidden;
}

.text-wrapper {
	padding: 0 1rem;
	max-width: 60rem;
	width: 100%;
	text-align: center;
    margin: 30vh auto;
}

.text {
	font-size: 8em;
	text-transform: uppercase;
	letter-spacing: -14px;
}

.text .letter {
	position: relative;
	display: inline-block;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.text .letter .character {
	opacity: 0.65;
	-webkit-transition: color .4s;
	transition: color .4s;
	cursor: pointer;
}

.text .letter span {
	position: absolute;
	bottom: .8rem;
	left: .4rem;
	display: block;
	width: 100%;
	height: 15px;
}

.text .letter span::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.25);
}

.text .letter:hover .character {
	color: #fff !important;
}

.text.part1 .letter:nth-child(1).poofed {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation: poofing1 1.4s ease-in-out infinite alternate;
	animation: poofing1 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes poofing1 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(459deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(459deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(459deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(459deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes poofing1 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(459deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(459deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(459deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(459deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part1 .letter:nth-child(1) .character {
	color: #f44336;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 0.33333s;
	animation-delay: 0.33333s;
}

.text.part1 .letter:nth-child(1) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 0.33333s;
	animation-delay: 0.33333s;
}

.text.part1 .letter:nth-child(2).poofed {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation: poofing2 1.4s ease-in-out infinite alternate;
	animation: poofing2 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes poofing2 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(540deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(540deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(540deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(540deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes poofing2 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(540deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(540deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(540deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(540deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part1 .letter:nth-child(2) .character {
	color: #9C27B0;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 0.66667s;
	animation-delay: 0.66667s;
}

.text.part1 .letter:nth-child(2) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 0.66667s;
	animation-delay: 0.66667s;
}

.text.part1 .letter:nth-child(3).poofed {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation: poofing3 1.4s ease-in-out infinite alternate;
	animation: poofing3 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes poofing3 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(264deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(264deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(264deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(264deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes poofing3 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(264deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(264deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(264deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(264deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part1 .letter:nth-child(3) .character {
	color: #f99b0c;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.text.part1 .letter:nth-child(3) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.text.part1 .letter:nth-child(4).poofed {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation: poofing4 1.4s ease-in-out infinite alternate;
	animation: poofing4 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes poofing4 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(42deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(42deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(42deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(42deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes poofing4 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(42deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(42deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(42deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(42deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part1 .letter:nth-child(4) .character {
	color: #cee007;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 1.33333s;
	animation-delay: 1.33333s;
}

.text.part1 .letter:nth-child(4) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 1.33333s;
	animation-delay: 1.33333s;
}

.text.part1 .letter:nth-child(5).poofed {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation: poofing5 1.4s ease-in-out infinite alternate;
	animation: poofing5 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes poofing5 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(384deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(384deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(384deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(384deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes poofing5 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(384deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(384deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(384deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(384deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part1 .letter:nth-child(5) .character {
	color: #00c6b2;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 1.66667s;
	animation-delay: 1.66667s;
}

.text.part1 .letter:nth-child(5) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 1.66667s;
	animation-delay: 1.66667s;
}

.text.part1 .letter:nth-child(6).poofed {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation: poofing6 1.4s ease-in-out infinite alternate;
	animation: poofing6 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes poofing6 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes poofing6 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part1 .letter:nth-child(6) .character {
	color: #f44336;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.text.part1 .letter:nth-child(6) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.text.part1 .letter:nth-child(7).poofed {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-animation: poofing7 1.4s ease-in-out infinite alternate;
	animation: poofing7 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes poofing7 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes poofing7 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(156deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(156deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part1 .letter:nth-child(7) .character {
	color: #4CAF50;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 2s;
	animation-delay: 2.5s;
}

.text.part1 .letter:nth-child(7) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 2s;
	animation-delay: 3s;
}

.text.part2 span:nth-child(1).poofed {
	-webkit-animation: sec_poofing1 1.4s ease-in-out infinite alternate;
	animation: sec_poofing1 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing1 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(268deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(268deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(268deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(268deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing1 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(268deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(268deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(268deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(268deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(1) .character {
	color: #ff5a5f;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 2.33333s;
	animation-delay: 2.33333s;
}

.text.part2 span:nth-child(1) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 2.33333s;
	animation-delay: 2.33333s;
}

.text.part2 span:nth-child(2).poofed {
	-webkit-animation: sec_poofing2 1.4s ease-in-out infinite alternate;
	animation: sec_poofing2 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing2 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(135deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(135deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(135deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(135deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing2 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(135deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(135deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(135deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(135deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(2) .character {
	color: #f99b0c;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 2.66667s;
	animation-delay: 2.66667s;
}

.text.part2 span:nth-child(2) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 2.66667s;
	animation-delay: 2.66667s;
}

.text.part2 span:nth-child(3).poofed {
	-webkit-animation: sec_poofing3 1.4s ease-in-out infinite alternate;
	animation: sec_poofing3 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing3 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(442deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(442deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(442deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(442deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing3 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(442deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(442deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(442deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(442deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(3) .character {
	color: #cee007;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.text.part2 span:nth-child(3) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.text.part2 span:nth-child(4).poofed {
	-webkit-animation: sec_poofing4 1.4s ease-in-out infinite alternate;
	animation: sec_poofing4 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing4 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(525deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(525deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(525deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(525deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing4 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(525deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(525deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(525deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(525deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(4) .character {
	color: #00c6b2;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 3.33333s;
	animation-delay: 3.33333s;
}

.text.part2 span:nth-child(4) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 3.33333s;
	animation-delay: 3.33333s;
}

.text.part2 span:nth-child(5).poofed {
	-webkit-animation: sec_poofing5 1.4s ease-in-out infinite alternate;
	animation: sec_poofing5 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing5 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(419deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(419deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(419deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(419deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing5 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(419deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(419deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(419deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(419deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(5) .character {
	color: #4e2a84;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 3.66667s;
	animation-delay: 3.66667s;
}

.text.part2 span:nth-child(5) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 3.66667s;
	animation-delay: 3.66667s;
}

.text.part2 span:nth-child(6).poofed {
	-webkit-animation: sec_poofing6 1.4s ease-in-out infinite alternate;
	animation: sec_poofing6 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing6 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(246deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(246deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(246deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(246deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing6 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(246deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(246deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(246deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(246deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(6) .character {
	color: #9C27B0;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 4s;
	animation-delay: 4s;
}

.text.part2 span:nth-child(6) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 4s;
	animation-delay: 4s;
}

.text.part2 span:nth-child(7).poofed {
	-webkit-animation: sec_poofing7 1.4s ease-in-out infinite alternate;
	animation: sec_poofing7 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing7 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(206deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(206deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(206deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(206deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing7 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(206deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(206deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(206deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(206deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(7) .character {
	color: #f99b0c;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 4.33333s;
	animation-delay: 4.33333s;
}

.text.part2 span:nth-child(7) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 4.33333s;
	animation-delay: 4.33333s;
}

.text.part2 span:nth-child(8).poofed {
	-webkit-animation: sec_poofing8 1.4s ease-in-out infinite alternate;
	animation: sec_poofing8 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing8 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(60deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(60deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(60deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(60deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing8 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(60deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(60deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(60deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(60deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(8) .character {
	color: #cee007;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 4.66667s;
	animation-delay: 4.66667s;
}

.text.part2 span:nth-child(8) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 4.66667s;
	animation-delay: 4.66667s;
}

.text.part2 span:nth-child(9).poofed {
	-webkit-animation: sec_poofing9 1.4s ease-in-out infinite alternate;
	animation: sec_poofing9 1.4s ease-in-out infinite alternate;
}

@-webkit-keyframes sec_poofing9 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(496deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(496deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(496deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(496deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

@keyframes sec_poofing9 {
	0% {
		-webkit-transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(0) translateY(0px) scale(1);
	}
	50% {
		-webkit-transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(0deg) rotateY(360deg) translateY(0px) scale(1);
	}
	56% {
		-webkit-transform: rotateZ(496deg) rotateY(360deg) translateY(0px) scale(1);
		transform: rotateZ(496deg) rotateY(360deg) translateY(0px) scale(1);
	}
	100% {
		-webkit-transform: rotateZ(496deg) rotateY(360deg) translateY(-700px) scale(0.01);
		transform: rotateZ(496deg) rotateY(360deg) translateY(-700px) scale(0.01);
	}
}

.text.part2 span:nth-child(9) .character {
	color: #00c6b2;
	-webkit-animation: wave 1.2s linear infinite;
	animation: wave 1.2s linear infinite;
	-webkit-animation-delay: 5s;
	animation-delay: 5s;
}

.text.part2 span:nth-child(9) span::before {
	-webkit-animation: shadow 1.2s linear infinite;
	animation: shadow 1.2s linear infinite;
	-webkit-animation-delay: 5s;
	animation-delay: 5s;
}

@-webkit-keyframes wave {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes wave {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes shadow {
	0% {
		width: 0;
		height: 0;
	}
	50% {
		width: 100%;
		height: 100%;
	}
	100% {
		width: 0;
		height: 0;
	}
}

@keyframes shadow {
	0% {
		width: 0;
		height: 0;
	}
	50% {
		width: 100%;
		height: 100%;
	}
	100% {
		width: 0;
		height: 0;
	}
}

.how-to {
	margin: 2rem 0 2rem 1rem;
	font-family: "Opens Sans", sans-serif;
	font-weight: 300;
	font-size: .85em;
	letter-spacing: 4px;
	color: rgba(0, 0, 0, 0.8);
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotateZ(0) scale(0.7);
		transform: rotateZ(0) scale(0.7);
	}
	100% {
		-webkit-transform: rotateZ(360deg) scale(0.7);
		transform: rotateZ(360deg) scale(0.7);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotateZ(0) scale(0.7);
		transform: rotateZ(0) scale(0.7);
	}
	100% {
		-webkit-transform: rotateZ(360deg) scale(0.7);
		transform: rotateZ(360deg) scale(0.7);
	}
}

@media only screen and (max-width: 767px) {
	.text {
		font-size: 6em;
	}
	.text .letter span {
		bottom: .5rem;
	}
}

@media only screen and (max-width: 480px) {
	.text {
		font-size: 4em;
	}
	.text .letter span {
		bottom: 0;
	}
}
